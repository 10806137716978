import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import TabBar from "../Tab/TabBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  appBar: {
    bottom: "auto",
    top: 0,
    maxWidth: 530,
    width: "100%",
    // width: "100%",
    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
    paddingLeft: "0px",
    height: "40px",
    left: "unset",
    right: "unset",

    background: "white",
  },

  YourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "34px",
    background: "white",
  },
  icon: {
    marginLeft: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
}));
function OrderNavBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <AppBar
        position="fixed"
        color="white"
        className={classes.appBar}
        style={{
          maxWidth: width ? width : "530px",
        }}
      >
        {localStorage.tabId && !props.backButtonDisable && (
          <Grid item xs={12}>
            {" "}
            <TabBar />
          </Grid>
        )}

        <Toolbar className={classes.toolbar}>
          {props.backButtonDisable ? (
            <ArrowBackIosIcon className={classes.icon} />
          ) : (
            <ArrowBackIosIcon
              className={classes.icon}
              onClick={() => history.goBack()}
            />
          )}
          {props.text && <p className={classes.YourOrder}>{props.text}</p>}
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default OrderNavBar;
