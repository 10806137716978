import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Grid, Button } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import TabDialog from "./TabDialog";
// import JoinTabDialog from "./JoinTabDialog";
// import OpenTabDialog from "./OpenTabDialog";
import { useSelector } from "react-redux";
import { defaultThemeColor } from "../../constant/colors";
import { ROOM_SERVICE } from "../../utils/constants";
import { useIntl } from "react-intl";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  iconShare: {
    height: "23",
    marginTop: "7px",
    width: "17px",
  },
  activeRoomNumber: {
    paddingTop: "4px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#FFFFFF",
    margin: "0px",
    lineHeight: "14px",
    letterSpacing: "0.41px",
    textTransform: "uppercase",
  },
  tableText2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    margin: "0px",

    fontSize: "16px",
    lineHeight: "18px",
    // textAlign: "center",
    color: "#FFFFFF",
    // marginTop: "-10px",
  },
  exitButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    transform: "none",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    margin: "5px 8px 0px 0px",
    padding: "4px",
    maxWidth:"87px",
    overflow:"hidden",
    maxHeight:"30px",
    alignItems:"start"
  },
  activeText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    margin: "0px",
    color: "#FFFFFF",
    marginTop: "10px",
  },
  tableText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    margin: "0px",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
  },
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    margin: "0px",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  appBar: {
    // eslint-disable-next-line
    // position: "-webkit-sticky",
    // eslint-disable-next-line
    // position: "sticky",
    // top: 0,
    // bottom: "auto",

    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
    paddingLeft: "0px",
    height: "40px",
  },
  YourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  icon: {
    marginLeft: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
}));
function TabBar(props) {
  const intl = useIntl();

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  let orderDetail = useSelector((state) => state.orderReducer.orderDetail);
  let menuReducer = useSelector((state) => state.menuReducer);
  const [viewOnlyMenu, setViewOnlyMenu] = useState(false);
  useEffect(() => {
    let type = new URLSearchParams(location.search).get("type");
    if (type === "view") {
      setViewOnlyMenu(true);
    }
  }, [location.search]);
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          url: localStorage.tabLink,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log("Share is Not available at this device");
    }
  }; // eslint-disable-next-line
  const [iosTest, setIosTest] = useState(false);
  useEffect(() => {
    // const venue_Id = localStorage.getItem("menuId");

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;
    if (isIos() && !isInStandaloneMode()) {
      setIosTest(true);
    }
  }, []);
  const funJoin = () => {
    if (props.mainPage) {
      props.setSelectedCard(null);
      props.setShowDetails(false);
    }
    localStorage.setItem("from", "menu");
    history.push("/join-tab");
  };

  const funOpen = () => {
    if (props.mainPage) {
      props.setSelectedCard(null);
      props.setShowDetails(false);
    }
    localStorage.setItem("from", "menu");
    if (
      localStorage.getItem("sessionId") !== null &&
      localStorage.getItem("sessionId") !== "null" &&
      localStorage.getItem("room_no") !== null &&
      localStorage.getItem("room_no") !== "null"
    ) {
      history.push(
        `/open-tab/?sessionid=${localStorage.getItem(
          "sessionId"
        )}&room_no=${localStorage.getItem("room_no")}`
      );
    } else {
      history.push(`/open-tab`);
    }
    // history.push("/open-tab");
  };

  return (
    <>
     {menuReducer?.menu?.accept_orders_without_tab !== ACCEPT_ORDERS_WITHOUT_TAB&&
      <div
        // position="sticky"
        className={classes.appBar}
        id="tab-bar"
        style={{
          background: localStorage.getItem("color")
            ? localStorage.getItem("color")
            : defaultThemeColor,
        }}
      >
        <Grid container style={{ paddingLeft: "17px" }} justify="space-between">
          {!viewOnlyMenu && menuReducer?.menu?.is_currently_accepting_orders ? (
            <Grid item
            style={{  maxWidth: "calc(100% - 190px)",
            overflow: "hidden",
            maxHeight: "37px",
            flexGrow:1}}>

            
              {localStorage.tabId && localStorage.tabId !== "empty" ? (
                <Grid
                  container
                  justify="space-between"
                  style={{ width: "100%" ,minWidth:"100px"}}

                >
                  <Grid item
                  style={{ width: "50%" ,overflow:"hidden",maxHeight:"36px"}}
                  
                  >
                    <p className={classes.activeRoomNumber}
                  style={{overflow:"hidden",maxHeight:"15px"}}
                    
                    >
                      {menuReducer?.menu?.type === ROOM_SERVICE
                        ? intl.formatMessage({ id: "room" })
                        : intl.formatMessage({ id: "table" })}
                    </p>
                    <p className={classes.tableText2}>
                      {orderDetail && orderDetail.table_number}{" "}
                    </p>
                  </Grid>
                  {menuReducer?.menu?.type !== ROOM_SERVICE && (
                    <Grid item
                  style={{ width: "50%" ,overflow:"hidden",maxHeight:"36px"}}
                    
                    >
                      <p className={classes.activeRoomNumber}
                  style={{ overflow:"hidden",maxHeight:"15px"}}
                      
                      >
                        {intl.formatMessage({ id: "password" })}
                      </p>
                      <p className={classes.tableText2}>
                        {orderDetail && orderDetail.tab_password}{" "}
                      </p>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <p className={classes.activeText}>
                  <span className={classes.tabText}>
                    {intl.formatMessage({ id: "open_or_join_tab" })}
                  </span>
                </p>
              )}
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <p className={classes.activeText}>
                <span className={classes.tabText}>
                  {viewOnlyMenu
                    ? intl.formatMessage({ id: "read_only_mode" })
                    : intl.formatMessage({
                        id: "venue_currently_not_accepting_orders",
                      })}
                </span>
              </p>
            </Grid>
          )}

          {!viewOnlyMenu && menuReducer?.menu?.is_currently_accepting_orders && (
            <Grid
              itemstyle={{maxWidth:"190px",overflow:"hidden"}}>

            
              {localStorage.tabId && localStorage.tabId !== "empty" ? (
                <>
                  <Button className={classes.exitButton} onClick={handleShare}>
                    {intl.formatMessage({ id: "share" })}
                  </Button>
                  {menuReducer?.menu?.type !== ROOM_SERVICE && (
                    <Button
                      className={classes.exitButton}
                      onClick={() => setOpen(true)}
                    >
                      {intl.formatMessage({ id: "exit_tab" })}
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <Button className={classes.exitButton} onClick={funOpen}>
                    {intl.formatMessage({ id: "open_tab" })}
                  </Button>
                  <Button className={classes.exitButton} onClick={funJoin}>
                    {intl.formatMessage({ id: "join_tab" })}
                  </Button>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </div>}
      <TabDialog open={open} setOpen={setOpen} />
      {/* <OpenTabDialog openTab={openTab} setOpenTab={setOpenTab} />
      <JoinTabDialog joinTab={joinTab} setJoinTab={setJoinTab} /> */}
    </>
  );
}

export default TabBar;
