import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getTableListByUrl,
  createOpenTabWithSession,
} from "../../actions/order";
import { useHistory, useParams ,useLocation} from "react-router-dom";
import splashLogoPic from "../../assets/images/splashLogoPic.png";
import splashLogoWrite from "../../assets/images/splashLogoWrite.png";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../../assets/images/no-image-found.png";
import TabBar from "../../components/Hotel/TabBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useIntl } from "react-intl";

const SelectTable = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();

  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const [height] = useState(window.textareaheight);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  let [selectedVenue, setSelectedVenue] = useState(null);
  const [tableNumber, setTableNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tablesList, setTablesList] = useState([]);
  useEffect(() => {
    let table_Number = new URLSearchParams(location.search).get("table_number");
    if (table_Number) {
      setTableNumber(table_Number)
      localStorage.setItem("table_number",table_Number)

    }
    // eslint-disable-next-line
  }, [])
  const handleSwitchPage = async (path) => {
    dispatch(
      createOpenTabWithSession({
        venue_uuid: param?.slug,
        table_number: tableNumber,
        first_name: hotelDetails?.guest_session_details?.room_number,
      })
    ).then((res) => {
      if (res?.status === 200) {
        let url = res?.tab_Link;
        if (localStorage.getItem("sessionId") !== null) {
          window.open(
            url + `&sessionid=${localStorage.getItem("sessionId")}`,
            "_top"
          );
        } else {
          window.open(url, "_top");
        }
      }
    });
  };
  const handleChangePage = () => {
    history.goBack();
  };
  const handleChange = (event) => {
    setTableNumber(event.target.value);
    setError(false);
  };
  const getDetails = async () => {
    let venuesList = hotelDetails?.venues;

    let index = venuesList?.findIndex((n) => n?.uuid === param?.slug);
    if (index > -1) {
      setSelectedVenue(venuesList[index]);
      let res = await dispatch(
        getTableListByUrl(`https://${venuesList[index]?.domain}`)
      );
      if (res?.data) {
        setTablesList(res?.data);
      }
    } else {
      const params = new URL(window?.location?.href).search;
      let search = new URLSearchParams(params).get("session");
      if (search) {
        history.push(`/?session=${search}`);
      } else {
        history.push(`/`);
      }
    }
  };
  useEffect(() => {
    getDetails();
    // eslint-disable-next-line
  }, [param.slug]);
  return (
    <>
      {false ? (
        <>
          <Grid
            container
            justify="center"
            style={{
              backgroundColor: "#FFFFFF",
              height: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              classes={{
                root: "circularProgress",
              }}
              size={40}
            />
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#FFFFFF",
              height: "70vh",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30%",
              }}
            >
              <img src={splashLogoPic} width={"130px"} alt=""></img>
              <img
                src={splashLogoWrite}
                width={"130px"}
                style={{ paddingTop: "20px" }}
                alt=""
              ></img>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          direction="column"
          style={{
            height: height,
            // eslint-disable-next-line
            // height: "-webkit-fill-available",
            minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
              ? "90vh"
              : "85vh",
            display: "flex",

            overflow: "hidden !important",
          }}
        >
          <TabBar />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",

              height: height,
              // eslint-disable-next-line
              // height: "-webkit-fill-available",
              minHeight: window?.matchMedia("(display-mode: standalone)")
                ?.matches
                ? "90vh"
                : "85vh",
              overflow: "hidden !important",
            }}
          >
            <Grid
              item
              container
              style={{
                marginTop: "6%",
              }}
            >
              <Grid item xs={2}>
                <ArrowBackIosIcon
                  style={{ paddingLeft: "20px", cursor: "pointer" }}
                  onClick={handleChangePage}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant={"circle"}
                    width={"124px"}
                    height={"124px"}
                  />
                ) : null}
                <img
                  onLoad={() => {
                    setIsLoading(false);
                  }}
                  title={props.title}
                  alt={props.title}
                  onError={(err) => {
                    setIsLoading(false);
                    setIsError(true);
                  }}
                  src={isError ? noimage : selectedVenue?.logo}
                  className={classes.logoContaier}
                  style={{ display: isLoading ? "none" : "block" }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginTop: "25%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography className={classes.header}>
                  {intl.formatMessage({ id: "Enter_your_table_number" })}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <FormControl variant="outlined" className={classes.formControl}>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tableNumber}
                    className={classes.selectTable}
                    onChange={handleChange}
                  >
                    {tablesList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginBottom: "6%",
                marginTop: "15%",
              }}
            >
              <Grid item xs={12} className={classes.buttonDiv}>
                <Button
                  className={classes.button1}
                  onClick={() => handleSwitchPage("menu")}
                >
                  {intl.formatMessage({ id: "View_menu_and_order" })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectTable;

const useStyles = makeStyles((theme) => ({
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF",
    },
  },

  formControl: {
    minWidth: "90px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "100px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },

  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000",
  },

  logoContaier: {
    border: "1px solid #202020",
    width: "127px !important",
    height: "127px !important",
    backgroundColor: "#fff !important",
    borderRadius: "20px !important",
  },
  button1: {
    width: " 100%",
    height: "100%",
    background: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
    "&:focus": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
  },

  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
