import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { venueLogin, getVenueDetails } from "../../actions/Menu";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "date-fns";
import { useLocation } from "react-router-dom";
import moment, { months } from "moment";
import DateFnsUtils from "@date-io/date-fns";
import LanguageDropDown from "../../components/SelectLanguage/Select"

const Login = () => {
  let location = useLocation();

  const [error, setError] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const [selectedDate, setSelectedDate] = React.useState(
    new Date(moment().add(1, "days"))
  );
  const [minDate] = React.useState(new Date(moment().add(1, "days")));

  const handleDateChange = (date) => {
    if (moment(date).isBefore(minDate)) {
      setSelectedDate(minDate);
    } else {
      setSelectedDate(date);
    }
  };
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);

  const [email, setEmail] = useState("");
  // eslint-disable-next-line
  const [tableNumber, setTableNumber] = useState(null);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [errorSurName, setErrorSurName] = useState(false);
  const [errorRoomNumber, setErrorRoomNumber] = useState(false);
  const [roomNumber, setRoomNumber] = useState("");
  const [surName, setSurName] = useState("");
  const [height, setHeight] = useState(window.textareaheight);
  useEffect(() => {
    setHeight(window.textareaheight);
    let table_number = new URLSearchParams(location.search).get("table_number");

    if (table_number) {
      setTableNumber(table_number);
    } else {
      // history.goBack();
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, []);
  const getData = async () => {
    setLoader(true);
    const url =
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host;
    // eslint-disable-next-line
    if (!menuReducer?.menu) {
      let res = await dispatch(getVenueDetails(url + "/d/"));
    }

    setLoader(false);
  };

  useEffect(() => {
    if (menuReducer?.menu?.read_only_menu === true) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu]);

  const handleChangeSurName = (event) => {
    setSurName(event.target.value);
    setErrorSurName(false);
  };
  const handleChangeRoomNumber = (event) => {
    setRoomNumber(event.target.value);
    setErrorRoomNumber(false);
  };
  const checkError = () => {
    if (
      !surName ||
      surName?.trim() === "" ||
      !roomNumber ||
      roomNumber?.trim() === ""
    ) {
      if (!surName || surName?.trim() === "") {
        setErrorSurName(true);
      }
      if (!roomNumber || roomNumber?.trim() === "") {
        setErrorRoomNumber(true);
      }
      return false;
    } else {
      return true;
    }
  };
  const handleCreateSession = async () => {
    if (checkError()) {
      setLoader(true);
      let res = await dispatch(
        venueLogin({
          venue_uuid: menuReducer?.menu?.uuid,
          surname: surName,
          room_number: roomNumber,
          check_out_datetime: moment(selectedDate).format("YYYY-MM-DD"),
          table_number: tableNumber ? tableNumber : roomNumber,
        })
      );
      if (res?.status === 200) {
        history.push("/menu");
        setLoader(false);
      } else {
        setMessage(intl.formatMessage({ id: "something_went_wrong" }));
        setLoader(false);
      }
      // } else {
      //   setError(true);
      //   setLoader(false);
      // }
    }
  };

  const handleCancel = () => {
    history.push(`/?from=login&table=${tableNumber}`);
  };

  return (
    <Grid
      container
      direction="column"
      style={{
        height: height,
        minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
          ? "90vh"
          : "85vh",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden !important",
        width: "100%",
      }}
    >
      <Grid>
        <Grid container style={{ marginTop: "55px", marginBottom: "10px" }}>
          <Grid
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              style={{ paddingLeft: "20px" }}
              onClick={() => history.goBack()}
            />
          </Grid>
          <Grid xs={8}>
            <Grid>
              <Typography className={classes.topText}>
                {intl.formatMessage({ id: "hotel_guest_login" })}
              </Typography>
             
              {<p className={classes.errorTextMessage}>{message}</p>}
            </Grid>
          </Grid>
          <Grid xs={2}>
          <LanguageDropDown/>

          </Grid>
        </Grid>
        <DialogContent style={{ marginTop: "75px" }}>
          <p className={classes.GenralText}>
            {intl.formatMessage({
              id: "surname",
            })}
          </p>
          <TextField
            variant="outlined"
            value={surName}
            onChange={(e) => {
              handleChangeSurName(e);
            }}
            type="text"
            placeholder={intl.formatMessage({
              id: "enter_surname",
            })}
            style={{ paddingBottom: 10 }}
            fullWidth
            // eslint-disable-next-line
            style={{
              marginBottom: 10,
            }}
            inputProps={{
              className: classes.TextInputStyle,
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.outlinedStyle,
              },
            }}
            error={errorSurName}
            helperText={
              errorSurName &&
              intl.formatMessage({
                id: "Field_is_required",
              })
            }
          />
          <p className={classes.GenralText}>
            {intl.formatMessage({
              id: "room_number",
            })}
          </p>
          <TextField
            variant="outlined"
            value={roomNumber}
            onChange={(e) => {
              handleChangeRoomNumber(e);
            }}
            type="text"
            placeholder={intl.formatMessage({
              id: "enter_room_number",
            })}
            style={{ paddingBottom: 10 }}
            fullWidth
            // eslint-disable-next-line
            style={{
              marginBottom: 10,
            }}
            inputProps={{
              className: classes.TextInputStyle,
            }}
            InputProps={{
              classes: {
                notchedOutline: classes.outlinedStyle,
              },
            }}
            error={errorRoomNumber}
            helperText={
              errorRoomNumber &&
              intl.formatMessage({
                id: "Field_is_required",
              })
            }
          />
          <p className={classes.GenralText}>
            {intl.formatMessage({
              id: "check_out_date",
            })}
          </p>
          <MuiPickersUtilsProvider variant="outlined" utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              fullWidth
              variant="outlined"
              format="dd/MM/yyyy"
              margin="normal"
              id="date-picker-inline"
              // label={intl.formatMessage({
              //   id: "check_out_date",
              // })}
              minDate={minDate}
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </DialogContent>
      </Grid>

      <Grid container style={{ marginBottom: "10%" }}>
        <Grid item xs={12} className={classes.buttonDiv}>
          {loader ? (
            <Button
              className={classes.button1}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
            >
              <CircularProgress size={25} style={{ color: "#fff" }} />
            </Button>
          ) : (
            <Button
              className={classes.button1}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
              onClick={handleCreateSession}
            >
              {intl.formatMessage({
                id: "continue",
              })}
            </Button>
          )}
        </Grid>
        <Grid item xs={12} className={classes.buttonDiv}>
          {menuReducer?.menu?.type !== 3 && (
            <Button className={classes.cancelbutton} onClick={handleCancel}>
              {intl.formatMessage({
                id: "not_staying_at_the_hotel",
              })}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  outlinedStyle: {
    border: "0px",
  },
  errorTextMessage: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  topText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    // padding: "10px 50px 0px 50px",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    margin: "8px 0px",
    color: "#000000",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    color: "#777777 !important",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginTop: "-2px",
      textAlign: "center",
    },
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      color: "#777777",
      background: "#FFFFFF",
      border: "1px solid #E3E3E3",
      boxSizing: "border-box",
      borderRadius: "5px",
      maxHeight: "50px",
      height: "50px",
    },
    "& .MuiInputBase-input:focus": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline::after": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  nullPadding: {
    "& .MuiAutocomplete-input": {
      padding: "1px !important",
    },
  },
  button1: {
    width: " 100%",
    height: "100%",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  // eslint-disable-next-line
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
