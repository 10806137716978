import {
  SET_FORMATTED_MENU_LOCAL,
  REMOVE_FORMATTED_MENU_LOCAL,
  SET_SEARCHED_ITEMS,
  REMOVE_SEARCHED_ITEMS,
  SET_SEARCH_TEXT,
  REMOVE_SEARCH_TEXT,
  SET_ROUTE,
  SET_CURRENT_SELECTED_ITEM,
  CANCEL_SEARCH_OR_ADD_ITEM,
} from "../constant/types";

const initialState = {
  formattedMenu: null,
  searchedItems: null,
  searchText: null,
  route: null,
  currentSelectedItem: null,
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CANCEL_SEARCH_OR_ADD_ITEM: {
      return {
        ...state,
        formattedMenu: null,
        searchedItems: null,
        searchText: null,
        route: null,
        currentSelectedItem: null,
      };
    }
    case SET_CURRENT_SELECTED_ITEM:
      return {
        ...state,
        currentSelectedItem: payload,
      };
    case SET_ROUTE:
      return {
        ...state,
        route: payload,
      };
    case SET_SEARCH_TEXT:
      return {
        ...state,
        searchText: payload,
      };
    case REMOVE_SEARCH_TEXT:
      return {
        ...state,
        searchText: null,
      };

    case SET_SEARCHED_ITEMS:
      return {
        ...state,
        searchedItems: payload,
      };
    case REMOVE_SEARCHED_ITEMS:
      return {
        ...state,
        searchedItems: null,
      };

    case SET_FORMATTED_MENU_LOCAL:
      return {
        ...state,
        formattedMenu: payload,
      };
    case REMOVE_FORMATTED_MENU_LOCAL:
      return {
        ...state,
        formattedMenu: null,
      };

    default:
      return state;
  }
}
