import React, { useEffect, useState } from "react";
import TabExpireDialog from "../components/Tab/TabExpireDialog";
import splashLogoPic from "../assets/images/splashLogoPic.png";
import splashLogoWrite from "../assets/images/splashLogoWrite.png";
import BlendPowerIcon from "../assets/images/BlendIcon.png";
import axios from "axios";
import TabBar from "../components/Tab/TabBar";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import instagram from "../assets/images/instagram.png";
import instagramWhite from "../assets/images/instagramWhite.png";
import Facebook from "../assets/images/Facebook.png";
import facebookWhite from "../assets/images/facebookWhite.png";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import AddToHomescreen from "react-add-to-homescreen";
import {
  Grid,
  Chip,
  Card,
  CardContent,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Snackbar from "@material-ui/core/Snackbar";
import { createOrder } from "../actions/order";
import BlendIcon from "../assets/images/logo.jpg";
import { useHistory, useLocation } from "react-router-dom";
import { defaultThemeColor } from "../constant/colors";
import DetailCard from "../components/DetailCard";
import Truncate from "react-truncate";
import GridMenu from "../components/GridMenu";
import TopGridMenu from "../components/TopGridMenu";
import "../style/home.css";
import API from "../utils/API";
import SmartImage from "../components/SmartImage";
import OrderBar from "../components/Order/OrderBar";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetail,
  clearOldOrders,
  updateTabExpire,
  createOpenTabWithSessionRoomService,
} from "../actions/order";
import { getVenueDetails, getMenusList } from "../actions/Menu";
import truncateHtml from "truncate-html";
import InstallDailog from "../components/InstallPopUp/InstallDailog";
import { ReactComponent as CallServer } from "../assets/images/callServer.svg";
import { ReactComponent as PreviosOrders } from "../assets/images/previosOrders.svg";
import { ReactComponent as GetBill } from "../assets/images/getBill.svg";
import ConfirmServerCall from "../components/Dialog/ConfirmServerCall";
import ServerArivalMessage from "../components/Dialog/ServerArivalMessage";
import PaymentMethod from "../components/Dialog/PaymentMethod";
import moment from "moment";
import { ROOM_SERVICE } from "../utils/constants";
import { useIntl } from "react-intl";
import CardFooter from "../components/Footer/CardFooter";
import { useTheme } from "@material-ui/core/styles";
import SelectLanguage from "../components/SelectLanguage/Select";
import Logo from "../components/Logo/Logo";
import { getCookie } from "../utils/common";
import MessageBox from "../components/Menu/MessageBox";
import TruncateMarkup from "react-truncate-markup";

const MenuList = (props) => {
  const theme = useTheme();
  const intl = useIntl();
  let dispatch = useDispatch();
  let location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [menuLoading, setMenuLoading] = useState(false);
  const [currentSelctedMenu, setCurrentSelectedMenu] = useState(null);
  const [openReadOnlyMessage, setOpenReadOnlyMessage] = useState(false);

  let languageReducer = useSelector((state) => state.languageReducer?.language);
  const updateLanguageLoadMenu = async () => {
    setMenuLoading(true);
    try {
      await dispatch(getMenusList()).then((res) => {
        setMenuLoading(false);
         
      });
    } catch (error) {
       setMenuLoading(false);
    }
  };

  useEffect(() => {
    updateLanguageLoadMenu();
    // eslint-disable-next-line
  }, [languageReducer]);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [lastOrderId, setLastOrderId] = useState("");
  const [lastOrderId2, setLastOrderId2] = useState("");
  let orderReducer = useSelector((state) => state.orderReducer);
  let menuReducer = useSelector((state) => state.menuReducer);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [tabExpire, setTabExpire] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [test, settest] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openServerCall, setOpenServerCall] = useState(false);
  const [openPaymentChoice, setOpenPaymentChoice] = useState(false);
  const [confirmModel, setConfirmModel] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [viewOnlyMenu, setViewOnlyMenu] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  useEffect(() => {
    let type = new URLSearchParams(location.search).get("type");
    if (type === "view") {
      setViewOnlyMenu(true);
    }
  }, [location.search]);
  const clearCache = async () => {
    let num = await localStorage.getItem("clearCacheCount");
    if (!num || parseInt(num) < 2) {
      try {
        localStorage.setItem("clearCacheCount", 3);
        if ("caches" in window) {
          caches.keys().then((names) => {
            names.forEach((name) => {
              caches.delete(name);
            });
          });
          // window.location.reload(true);
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  useEffect(() => {
    clearCache();
  }, [localStorage.getItem("clearCacheCount")]);
  useEffect(() => {
    clearCache();
  }, []);
  const handleConfirm = (val, serviceType) => {
    setConfirmModel(val);
    setServiceType(serviceType);
    setOpenServerCall(false);
    setOpenPaymentChoice(false);
  };
  const handleCloseConfirmModel = () => {
    setConfirmModel(false);
    setServiceType("");
  };

  const getCount = (orderReducer, dish) => {
    let totalCount = 0;
    let count = orderReducer.orderList.filter(
      (item) => item.item_id === dish.item_id
    );
    count.map((item) => {
      totalCount++;
      if (item.quantity > 1) {
        totalCount = totalCount + item.quantity - 1;
      }
      return "";
    });
    return totalCount;
  };

  const [menusLength, setMenusLength] = useState(-1);
  useEffect(() => {
    menuReducer &&
      menuReducer.menu &&
      menuReducer.menu.menus &&
      menuReducer.menu.menus.map(
        (menu) =>
          menuReducer.selectedMenu === menu.id &&
          setSelectedSubMenu(menu.menu_categories)
      );
    setMenusLength(menuReducer?.menu?.menus?.length);
    // eslint-disable-next-line
  }, [menuReducer?.selectedMenu,menuReducer?.menu,loader]);

  useEffect(() => {
    if (!menuReducer.menu) {
      setLoader(true);
      let type = new URLSearchParams(location.search).get("type");
      if (
        new URLSearchParams(location.search).get("tab") ||
        (localStorage.tabId && !type)
      ) {
        // let tab_id=new URLSearchParams(location.search).get("tab");
        // let tabId=new URLSearchParams(location.search).get("tab")
        // let tabDetails=JSON.parse(localStorage.getItem("tabId"))
        // if(tabDetails?.tabId===tab_id){

        orderReducer?.orderId &&
          dispatch(
            createOrder({
              venue_id: localStorage.menuId,
              tab_id: new URLSearchParams(location.search).get("tab")
                ? new URLSearchParams(location.search).get("tab")
                : localStorage.tabId,
              tab_link: new URLSearchParams(location.search).get("tab")
                ? window.location.protocol + "//" + window.location.host
                : localStorage.tab_link,
            })
          ).then((res) => {
            if (res === 403) {
              dispatch(
                updateTabExpire({
                  value: true,
                })
              );
              setTabExpire(true);
            } else if (res === 200) {
            } else {
              localStorage.setItem("tabId", "empty");
              localStorage.removeItem("orderId");
              localStorage.setItem("tabLink", "empty");
              if (menuReducer.menu?.uuid) {
                dispatch(
                  createOrder({
                    venue_id: menuReducer.menu?.uuid,
                    tab_id: "empty",
                    tab_link: "empty",
                  })
                );
              }
            }
          });
      }
      const url =
        (process.env.NODE_ENV === "development"
          ? "https://busaba.staging.blend.menu"
          : window.location.protocol + "//" + window.location.host) + "/d/";
      dispatch(getVenueDetails(url))
        .then((result) => {
          dispatch(getMenusList());
          let sessionCookies = null;
          if (result?.data?.business_account?.domain) {
            sessionCookies = getCookie(
              "session",
              result?.data?.business_account?.domain
            );
          }
          if (result?.data?.type === ROOM_SERVICE) {
            let room_no = new URLSearchParams(location.search).get("room_no");
            let session2 =
              sessionCookies ||
              new URLSearchParams(location.search).get("sessionid");
            localStorage.setItem("sessionId", session2);

            if (room_no) {
              localStorage.setItem("room_no", room_no);
              dispatch(
                createOpenTabWithSessionRoomService({
                  venue_uuid: result?.data?.uuid,
                  first_name: room_no,
                  table_number: room_no,
                })
              );
            }
          }
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);

          console.log(err);
        });
      // axios
      //   .get(
      //     (process.env.NODE_ENV === "development"
      //       ? "https://busaba.staging.blend.menu"
      //       : window.location.protocol + "//" + window.location.host) + "/d/",

      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )

      //   .then((result) => {
      //     if (result?.data?.display_hotel_details) {
      //       history.push("/");
      //     }
      //     localStorage.setItem(
      //       "menuId",
      //       result.data.uuid ? result.data.uuid : ""
      //     );
      //     //add dummy type for room service
      //     // let dd = { ...result.data, type: 3 };
      //     if (result?.data?.type === ROOM_SERVICE) {
      //       let room_no = new URLSearchParams(location.search).get("room_no");
      //       let session2 = new URLSearchParams(location.search).get(
      //         "sessionid"
      //       );
      //       localStorage.setItem("sessionId", session2);

      //       if (room_no) {
      //         localStorage.setItem("room_no", room_no);
      //         dispatch(
      //           createOpenTabWithSessionRoomService({
      //             venue_uuid: result?.data?.uuid,
      //             first_name: room_no,
      //             table_number: room_no,
      //           })
      //         );
      //       }
      //     }
      //     // dispatch({
      //     //   type: "SET_RESTAURANT_MENU",
      //     //   payload: result.data.id ? dd : "",
      //     // });
      //     //uncomment before push
      //     dispatch({
      //       type: "SET_RESTAURANT_MENU",
      //       payload: result.data.id ? result.data : "",
      //     });

      //     dispatch({
      //       type: "SELECTED_MENU",
      //       payload: result.data.menus[0]?.id,
      //     });

      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     setLoader(false);

      //     console.log(err);
      //   });
    } else {
      if (!menuReducer.menu?.menus || menuReducer.menu?.menus?.length === 0) {
        dispatch(getMenusList());
      }
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    
    if (
      !orderReducer.orderId &&
      menuReducer.menu?.uuid
      // && menuReducer.menu?.type !== ROOM_SERVICE
    ) {
      if (
        new URLSearchParams(location.search).get("tab") ||
        localStorage.tabId
      ) {
        dispatch(
          createOrder({
            venue_id: menuReducer.menu?.uuid,
            tab_id: new URLSearchParams(location.search).get("tab")
              ? new URLSearchParams(location.search).get("tab")
              : localStorage.tabId,
            tab_link: new URLSearchParams(location.search).get("tab")
              ? window.location.protocol + "//" + window.location.host
              : localStorage.tab_link,
          })
        ).then((res) => {
          if (res === 403) {
            dispatch(
              updateTabExpire({
                value: true,
              })
            );
            setTabExpire(true);
          } else if (res === 200) {
          } else {
            localStorage.setItem("tabId", "empty");
            localStorage.setItem("tabLink", "empty");
            localStorage.removeItem("orderId");
            dispatch(createOrder({ venue_id: menuReducer.menu?.uuid }));
          }
        });
      } else {
        dispatch(
          createOrder({
            venue_id: menuReducer?.menu?.uuid,
            tab_id: "empty",
            tab_link: "empty",
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [orderReducer?.orderId, menuReducer?.menu]);
  const getDetails = async () => {
    await dispatch(getOrderDetail({ order_uuid: orderReducer.orderId }));
  };

  useEffect(() => {
    if (
      orderReducer?.orderId &&
      lastOrderId2 !== orderReducer?.orderId &&
      lastOrderId !== orderReducer?.orderId
    ) {
      setLastOrderId2(lastOrderId);
      setLastOrderId(orderReducer?.orderId);
      setTimeout(() => {
        getDetails();
      }, 200);
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderId]);

  useEffect(() => {
    const functionCall = async () => {
      try {
        const response = await API.get(
          `/api/order/${orderReducer.orderDetail.order_uuid}/status/`
        );
        if (response && response.data && response.data.status === 2) {
          history.push({
            pathname: "/order",
          });

          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (orderReducer?.orderDetail?.order_uuid) {
      let type = new URLSearchParams(location.search).get("type");
      functionCall();
      let tabLinkUrl = new URLSearchParams(location.search).get("tab");
      if (
        !type &&
        !tabLinkUrl &&
        localStorage.getItem("tabId") !== "empty" &&
        localStorage.getItem("show-tab-expire") !== "yes"
      ) {
        history.push(`/menu/?tab=${localStorage.getItem("tabId")}`);
      }
      // let session2 = new URLSearchParams(location.search).get("sessionid");
      // localStorage.setItem("sessionId", session2);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(clearOldOrders());
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;
    if (isIos() && !isInStandaloneMode()) {
      menuReducer &&
        menuReducer.menu &&
        menuReducer.menu.show_add_to_home_screen_pop_up === true &&
        settest(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleAddToHomescreenClick = () => {
    menuReducer &&
      menuReducer.menu &&
      menuReducer.menu.show_add_to_home_screen_pop_up === true &&
      setOpen(true);
  };
  const [goTop, setGoTop] = useState(false);

  const executeScroll = () => {
    setGoTop(true);
  };
  useEffect(() => {
    if (selectedSubMenu && goTop) {
      const element = document.getElementById("top-bar");
      window.scrollTo(0, element.offsetHeight + 60);
    }
    // eslint-disable-next-line
  }, [goTop, selectedSubMenu]);

  const handleChangePageBackBusiness = () => {
    if (menuReducer?.menu?.business_account?.type > -1) {
      if (
        localStorage.getItem("sessionId") !== "null" &&
        localStorage.getItem("sessionId") !== null
      ) {
        window.open(
          `https://${
            menuReducer?.menu?.business_account?.domain
          }/?session=${localStorage.getItem("sessionId")}`,
          "_top"
        );
      } else {
        window.open(
          `https://${menuReducer?.menu?.business_account?.domain}`,
          "_top"
        );
      }
    }
  };
  const [vertical] = React.useState("center");
  const [horizontal] = React.useState("center");

  const handleClickOpenBill = () => {
    let list = orderReducer.orderHistory.filter(
      (n) =>
        n.is_paid === false &&
        moment(n.submitted_for_review).isAfter(moment().subtract(1, "day"))
    );
    if (list.length > 0) {
      setOpenPaymentChoice(true);
    } else {
      setOpenSnack(true);
    }
  };
  useEffect(() => {
    if (openSnack) {
      setTimeout(() => {
        setOpenSnack(false);
      }, 2500);
    }
  }, [openSnack]);
  useEffect(() => {
    if (menuReducer?.selectedMenu) {
      menuReducer.menu.menus.forEach((element) => {
        if (element?.id === menuReducer?.selectedMenu) {
          setCurrentSelectedMenu(element);
        }
      });
    }
    // eslint-disable-next-line
  }, [menuReducer?.selectedMenu]);
  useEffect(() => {
    if (openReadOnlyMessage) {
      setTimeout(() => {
        setOpenReadOnlyMessage(false);
      }, 2000);
    }
    // eslint-disable-next-line
  }, [openReadOnlyMessage]);
  return (
    <div>
      {openPaymentChoice && (
        <PaymentMethod
          open={openPaymentChoice}
          handleClose={setOpenPaymentChoice}
          confirm={handleConfirm}
        />
      )}
      {openServerCall && (
        <ConfirmServerCall
          open={openServerCall}
          handleClose={setOpenServerCall}
          confirm={handleConfirm}
        />
      )}
      {confirmModel && (
        <ServerArivalMessage
          open={confirmModel}
          handleClose={handleCloseConfirmModel}
          serviceType={serviceType}
        />
      )}
      {menuReducer.menu ? (
        <>
          <Grid>
            <Grid item>
              {localStorage.tabId &&
                !menuReducer.menu.read_only_menu &&
                !viewOnlyMenu && (
                  <TabBar
                    setSelectedCard={setSelectedCard}
                    setShowDetails={setShowDetails}
                    mainPage={true}
                  />
                )}
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openSnack}
                message={intl.formatMessage({
                  id: "you_do_not_have_any_unpaid_orders",
                })}
                key={"vertical + horizontal"}
                className={classes.snackbar}
              />
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={openReadOnlyMessage}
                message={
                  currentSelctedMenu?.read_only_text
                    ? currentSelctedMenu?.read_only_text
                    : intl.formatMessage({
                        id: "only_order_food_menu_table_area",
                      })
                }
                
                key={"vertical + horizontal"}
                className={classes.customSnackbar}
              />
              <Grid item xs={12} className="headerTop">
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundImage:
                      menuReducer && menuReducer.menu.cover_image
                        ? `url("${menuReducer.menu.cover_image}")`
                        : "",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  className="headerCard"
                >
                  <Grid
                    item
                    container
                    xs={12}
                    justify="space-between"
                    style={{ paddingTop: "11px" }}
                  >
                    <Grid item xs={6}>
                      {menuReducer?.menu?.business_account && (
                        <Grid
                          container
                          style={{
                            cursor: "pointer",
                            paddingBottom: "12px",
                            paddingLeft: "5px",
                            visibility:
                              menuReducer?.menu?.business_account?.type === 1 ||
                              menuReducer?.menu?.business_account?.type === 2
                                ? "visible"
                                : "hidden",
                          }}
                          onClick={handleChangePageBackBusiness}
                        >
                          <Grid
                            item
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <ArrowBackIosIcon
                              style={{
                                marginLeft: "10px",
                                color: "#fff",
                                position: "relative",
                              }}
                            />
                          </Grid>

                          <Grid
                            item
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Typography
                              className={classes.backButton}
                              style={{
                                color: "#fff",
                                position: "relative",
                              }}
                            >
                              {intl.formatMessage({ id: "Back" })}
                            </Typography>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      container
                      style={{
                        justifyContent: "flex-end",
                        color: "#fff",

                        position: "relative",
                      }}
                    >
                      {menuReducer && menuReducer.menu.instagram_url && (
                        <Grid
                          item
                          onClick={() =>
                            window.open(
                              menuReducer && menuReducer.menu.instagram_url
                            )
                          }
                        >
                          <img
                            alt={intl.formatMessage({ id: "noImg" })}
                            src={
                              menuReducer && menuReducer.menu.cover_image
                                ? instagramWhite
                                : instagram
                            }
                            style={{
                              color: localStorage.getItem("color")
                                ? localStorage.getItem("color")
                                : defaultThemeColor,
                              cursor: "pointer",
                              height: "20px",
                              margin: "0px 10px",
                            }}
                            onClick={() => {
                              history.push("/");
                            }}
                          />
                        </Grid>
                      )}
                      {menuReducer && menuReducer.menu.facebook_url && (
                        <Grid
                          item
                          onClick={() =>
                            window.open(
                              menuReducer && menuReducer.menu.facebook_url
                            )
                          }
                        >
                          <img
                            alt={intl.formatMessage({ id: "noImg" })}
                            src={
                              menuReducer && menuReducer.menu.cover_image
                                ? facebookWhite
                                : Facebook
                            }
                            style={{
                              color: localStorage.getItem("color")
                                ? localStorage.getItem("color")
                                : defaultThemeColor,
                              cursor: "pointer",
                              height: "18px",
                              margin: "0px 25px",
                            }}
                            onClick={() => {
                              history.push("/");
                            }}
                          />
                        </Grid>
                      )}
                     
                      <Grid item>
                        <SelectLanguage  type="menu"/>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className="headerCardGrid">
                    <Card className="headerCardStyle">
                      <Grid container spacing={3} style={{ width: "100%" }}>
                        <Grid
                          container
                          item
                          justify="center"
                          className="logoBox"
                          style={{ padding: "0px" }}
                        >
                          <SmartImage
                            url={menuReducer?.menu?.logo}
                            styleClass="logo"
                            width={100}
                            height={100}
                            type={"circle"}
                            title={menuReducer?.menu?.name}
                          />
                        </Grid>
                        <CardContent>
                          <Typography
                            style={{
                              color: menuReducer.menu.cover_image
                                ? "#ffffff"
                                : "#000000",

                              position: "relative",
                            }}
                            className="title"
                            gutterBottom
                            variant="h5"
                            component="h2"
                          >
                            {menuReducer?.menu?.name}
                          </Typography>
                          <Typography
                            style={{
                              color: menuReducer.menu.cover_image
                                ? "#ffffff"
                                : "#777777",
                              position: "relative",
                            }}
                            className="description"
                            variant="body2"
                            component="p"
                          >
                            <Truncate ellipsis={"..."} width={250}>
                              {menuReducer.menu.description
                                ? menuReducer.menu.description
                                : ""}
                            </Truncate>
                          </Typography>
                        </CardContent>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

              {<Grid
                id="top-bar"
                className={
                  localStorage.tabId
                    ? !menuReducer?.menu?.accept_orders_without_tab &&
                      !menuReducer.menu.read_only_menu &&
                      !viewOnlyMenu
                      ? "menuBar-tab"
                      : "menuBar-tab-view-only"
                    : "menuBar"
                }
              >
                {menuReducer.menu.menus?.length > 1 && (
                  <TopGridMenu
                    executeScroll={executeScroll}
                    menuList={menuReducer.menu.menus}
                    setSelectedSubMenu={setSelectedSubMenu}
                    setSelectedMenu={setSelectedMenu}
                    selectedMenu={selectedMenu}
                  />
                )}
                { selectedSubMenu && (
                  <GridMenu
                    menuLength={menuReducer.menu.menus?.length}
                    menuList={selectedSubMenu}
                  />
                )}
              </Grid>
 }
  {!menuReducer?.menu?.read_only_menu && !viewOnlyMenu && (
                <Grid
                  container
                  justify={
                    menuReducer?.menu?.type === ROOM_SERVICE
                      ? ""
                      : menuReducer?.menu?.show_request_waiter_action
                      ? "space-between"
                      : window.innerWidth > 300 && window.innerWidth < 415
                      ? "space-around"
                      : ""
                  }
                  spacing={
                    !menuReducer?.menu?.show_request_waiter_action ? 2 : 0
                  }
                  style={{ padding: "0px 20px 0px 20px" }}
                >
                  <Grid item style={{ marginTop: "20px" }}>
                    <Grid
                      onClick={() => history.push("/previousOrders")}
                      container
                      style={{
                        background: "#FFFFFF",
                        maxWidth: menuReducer?.menu?.show_request_waiter_action
                          ? "102px"
                          : "179px",
                        minWidth: menuReducer?.menu?.show_request_waiter_action
                          ? "100px"
                          : window.innerWidth > 330
                          ? "150px"
                          : "130px",
                        overflow: "hidden",
                        minHeight: "40px",
                        padding: "10px 0px",
                        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.17)",
                        borderRadius: "5px",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <Grid item>
                        <PreviosOrders
                          style={{
                            height: "15px",
                            width: "15px",
                            marginRight: "7px",
                          }}
                        />
                      </Grid>

                      <Grid item style={{ textAlign: "center" }}>
                        <Typography className={classes.buttonText}>
                          {intl.formatMessage({ id: "My_orders" })}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {menuReducer?.menu?.type !== ROOM_SERVICE && (
                    <Grid item style={{ marginTop: "20px" }}>
                      <Grid
                        container
                        style={{
                          background: "#FFFFFF",
                          maxWidth: menuReducer?.menu
                            ?.show_request_waiter_action
                            ? "102px"
                            : "179px",

                          minWidth: menuReducer?.menu
                            ?.show_request_waiter_action
                            ? "100px"
                            : window.innerWidth > 330
                            ? "150px"
                            : "130px",
                          padding: "10px 0px",

                          minHeight: "40px",
                          overflow: "hidden",

                          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.17)",
                          borderRadius: "5px",
                          alignItems: "center",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleClickOpenBill}
                      >
                        <Grid item>
                          <GetBill
                            style={{
                              height: "15px",
                              width: "15px",
                              marginRight: "7px",
                            }}
                          />
                        </Grid>

                        <Grid item style={{ textAlign: "center" }}>
                          <Typography className={classes.buttonText}>
                            {intl.formatMessage({ id: "Get_bill" })}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                  {menuReducer?.menu?.type !== ROOM_SERVICE &&
                    menuReducer?.menu?.show_request_waiter_action && (
                      <Grid item style={{ marginTop: "20px" }}>
                        <Grid
                          container
                          style={{
                            background: "#FFFFFF",
                            maxWidth: "102px",
                            minWidth: "100px",
                            minHeight: "40px",
                            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.17)",
                            borderRadius: "5px",
                            overflow: "hidden",
                            padding: "10px 0px",

                            alignItems: "center",
                            justifyContent: "center",
                            cursor: "pointer",
                          }}
                          onClick={() => setOpenServerCall(true)}
                        >
                          <Grid item>
                            <CallServer
                              style={{
                                height: "15px",
                                width: "15px",
                                marginRight: "7px",
                              }}
                            />
                          </Grid>

                          <Grid item style={{ textAlign: "center" }}>
                            <Typography className={classes.buttonText}>
                              {intl.formatMessage({ id: "Call_server" })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                </Grid>
              )}
              {currentSelctedMenu?.information_text_section && (
                <Grid
                  container
                  justifyContent="center"
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <MessageBox
                    message={currentSelctedMenu?.information_text_section}
                  />
                </Grid>
              )}
{

              selectedSubMenu ? (
                <Grid container>
                  <Grid item xs={12} className="cardListBody">
                    <Grid className="cardListMainGrid">
                      {selectedSubMenu && selectedSubMenu?.length > 0 ? (
                        selectedSubMenu.map((menu) => {
                          return (
                            <Grid key={menu.id} id={menu.name}>
                              <Typography
                                className="menu"
                                variant="h4"
                                component="h1"
                                style={{ paddingLeft: "1rem" }}
                              >
                                {menu.name}
                              </Typography>
                              {menu.items &&
                                menu.items.length > 0 &&
                                menu.items.map((dish, index) => {
                                  const dishTags =
                                    dish.tags && dish.tags.length > 0
                                      ? dish.tags.filter(
                                          (tag) =>
                                            tag.name && tag.name === "Popular"
                                        )
                                      : [];
                                  return (
                                    <div key={index}>
                                      {showDetails &&
                                      selectedCard ===
                                        dish.item_id +
                                          `_${menuReducer.menu.name}` ? (
                                        <DetailCard
                                          key={dish.item_id}
                                          dishImage={
                                            menuReducer.menu.cover_image
                                          }
                                          currentSelctedMenu={
                                            currentSelctedMenu
                                          }
                                          setOpenReadOnlyMessage={
                                            setOpenReadOnlyMessage
                                          }
                                          viewOnlyMenu={viewOnlyMenu}
                                          dish={dish}
                                          setSelectedCard={setSelectedCard}
                                          setShowDetails={setShowDetails}
                                          currency={menuReducer.menu.currency}
                                          orderCount={orderCount}
                                          setOrderCount={setOrderCount}
                                          readOnly={
                                            menuReducer.menu.read_only_menu ||
                                            viewOnlyMenu
                                              ? true
                                              : false
                                          }
                                          is_currently_accepting_orders={
                                            menuReducer?.menu
                                              ?.is_currently_accepting_orders &&
                                            !viewOnlyMenu
                                          }
                                        />
                                      ) : (
                                        <Card
                                          key={dish.item_id}
                                          className="dishCard"
                                          style={{
                                            paddingLeft: "1rem",
                                            borderLeft:
                                              orderReducer.orderList &&
                                              orderReducer.orderList.length >
                                                0 &&
                                              orderReducer.orderList.filter(
                                                (item) =>
                                                  item.item_id === dish.item_id
                                              ).length > 0
                                                ? `solid ${
                                                    localStorage.getItem(
                                                      "color"
                                                    )
                                                      ? localStorage.getItem(
                                                          "color"
                                                        )
                                                      : defaultThemeColor
                                                  } 6px`
                                                : "unset",
                                            borderRadius: "0px",
                                          }}
                                          onClick={() => {
                                            setSelectedCard(
                                              dish.item_id +
                                                `_${menuReducer.menu.name}`
                                            );

                                            setShowDetails(true);
                                          }}
                                        >
                                          {dish.image ? (
                                            <Grid
                                              container
                                              justify="space-between"
                                              spacing={3}
                                            >
                                              <Grid
                                                item
                                                xs={7}
                                                lg={8}
                                                style={{
                                                  paddingTop: "0px",
                                                  marginTop: "12px",
                                                }}
                                              >
                                                <CardContent className="dishCardContentWithImage">
                                                  <Grid container>
                                                    <Grid item></Grid>
                                                    <Grid item>
                                                      <h1
                                                        className="dishName"
                                                        // gutterBottom
                                                        style={{
                                                          textDecoration:
                                                            dish?.availability
                                                              ?.out_of_stock ===
                                                            true
                                                              ? "line-through"
                                                              : "",
                                                        }}
                                                      >
                                                        {orderReducer.orderList &&
                                                          orderReducer.orderList
                                                            .length > 0 &&
                                                          orderReducer.orderList.filter(
                                                            (item) =>
                                                              item.item_id ===
                                                              dish.item_id
                                                          ).length > 0 && (
                                                            <Typography
                                                              style={{
                                                                fontFamily:
                                                                  theme?.fontFamily,
                                                                fontStyle:
                                                                  "normal",
                                                                fontWeight:
                                                                  "normal",
                                                                fontSize:
                                                                  "13px",
                                                                // lineHeight:
                                                                //   "18px",
                                                                color:
                                                                  localStorage.getItem(
                                                                    "color"
                                                                  )
                                                                    ? localStorage.getItem(
                                                                        "color"
                                                                      )
                                                                    : defaultThemeColor,
                                                                display:
                                                                  "inline",
                                                                marginRight:
                                                                  "5px",
                                                              }}
                                                            >
                                                              x
                                                              {getCount(
                                                                orderReducer,
                                                                dish
                                                              )}
                                                            </Typography>
                                                          )}
                                                        {dish.name}
                                                      </h1>
                                                    </Grid>
                                                  </Grid>
                                                  <div
                                                    className="dishDescription truncate-overflow"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.description && (
                                                      <TruncateMarkup
                                                        lines={2}
                                                        ellipsis=" ..."
                                                      >
                                                        {/* <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          dish.description,
                                                      }}
                                                    /> */}
                                                        <div>
                                                          {dish.description
                                                            ?.replace(
                                                              /\&nbsp;/g,
                                                              " "
                                                            ).replace(
                                                              /\&amp;/g,
                                                              "&"
                                                            )
                                                            .replace(
                                                              /(<([^>]+)>)/gi,
                                                              " "
                                                            )
                                                            .replace(
                                                              "nbsp;",
                                                              " "
                                                            )}
                                                        </div>
                                                      </TruncateMarkup>
                                                    )}
                                                  </div>
                                                  {/* <Truncate
                                                    lines={2}
                                                    ellipsis={"..."}
                                                    className="dishDescription"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          dish.description,
                                                      }}
                                                    />

                                                  </Truncate> */}

                                                  <Grid
                                                    container
                                                    justify="flex-start"
                                                    // spacing={1}
                                                    className="priceGrid"
                                                  >
                                                    <Grid
                                                      container
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    >
                                                      <Grid item>
                                                        <CardFooter
                                                          dish={dish}
                                                          currency={
                                                            menuReducer.menu
                                                              .currency
                                                          }
                                                        />
                                                      </Grid>
                                                      {/* <Grid item>
                                                        <Truncate
                                                          className={
                                                            classes.priceDescription
                                                          }
                                                          lines={1}
                                                        >
                                                          Description Typography
                                                        </Truncate>
                                                      </Grid> */}
                                                    </Grid>

                                                    <Grid
                                                      item
                                                      style={{
                                                        paddingTop: "0px",
                                                        marginTop: "1rem",
                                                      }}
                                                    >
                                                      {dishTags.length ? (
                                                        <>
                                                          {dishTags[0].image ? (
                                                            <Chip
                                                              icon={
                                                                <img
                                                                  src={
                                                                    dishTags[0]
                                                                      .image
                                                                      .image
                                                                  }
                                                                  alt={
                                                                    dishTags[0]
                                                                      .name
                                                                  }
                                                                />
                                                              }
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "#000",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#000",
                                                                marginRight:
                                                                  "0.5rem",
                                                                marginLeft:
                                                                  "0.25rem",
                                                              }}
                                                            />
                                                          ) : (
                                                            <Chip
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "#000",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#000",
                                                                marginRight:
                                                                  "0.5rem",
                                                                marginLeft:
                                                                  "0.25rem",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      ) : (
                                                        <> </>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={5}
                                                md={5}
                                                lg={3}
                                                className="CardImageGrid"
                                                style={{
                                                  paddingTop: "0px",
                                                  marginTop: "14px",
                                                }}
                                              >
                                                <SmartImage
                                                  url={dish.image}
                                                  title={dish.name}
                                                  styleClass="dishMediaImage"
                                                  width={"100%"}
                                                  height={100}
                                                  type={"rect"}
                                                />
                                              </Grid>
                                            </Grid>
                                          ) : (
                                            <Grid
                                              container
                                              justify="space-between"
                                              spacing={3}
                                            >
                                              <Grid item xs={12}>
                                                <CardContent className="dishCardContent">
                                                  <p
                                                    className="dishName"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.name}
                                                  </p>
                                                  <div
                                                    className="dishDescription truncate-overflow"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.description && (
                                                      <TruncateMarkup
                                                        lines={2}
                                                        ellipsis=" ..."
                                                      >
                                                        {/* <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          dish.description,
                                                      }}
                                                    /> */}
                                                        <div>
                                                          {dish.description
                                                            ?.replace(
                                                              /\&nbsp;/g,
                                                              " "
                                                            ).replace(
                                                              /\&amp;/g,
                                                              "&"
                                                            )
                                                            .replace(
                                                              /(<([^>]+)>)/gi,
                                                              " "
                                                            )
                                                            .replace(
                                                              "nbsp;",
                                                              " "
                                                            )}
                                                        </div> 
                                                      </TruncateMarkup>
                                                    )}
                                                  </div>
                                                  {/* <Truncate
                                                    lines={2}
                                                    ellipsis={"..."}
                                                    className="dishDescription"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          dish.description,
                                                      }}
                                                    />
                                                  </Truncate> */}

                                                  <Grid
                                                    container
                                                    justify="flex-start"
                                                    spacing={1}
                                                    style={{
                                                      marginTop: "0rem",
                                                    }}
                                                    className="priceGrid"
                                                  >
                                                    <Grid item>
                                                      <CardFooter
                                                        dish={dish}
                                                        currency={
                                                          menuReducer.menu
                                                            .currency
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      style={{
                                                        paddingTop: "0px",
                                                        marginBottom: "0.5rem",
                                                      }}
                                                    >
                                                      {dishTags.length ? (
                                                        <>
                                                          {dishTags[0].image ? (
                                                            <Chip
                                                              icon={
                                                                <img
                                                                  src={
                                                                    dishTags[0]
                                                                      .image
                                                                      .image
                                                                  }
                                                                  alt={
                                                                    dishTags[0]
                                                                      .name
                                                                  }
                                                                />
                                                              }
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "black",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#fff",
                                                              }}
                                                            />
                                                          ) : (
                                                            <Chip
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "black",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#fff",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Grid>
                                            </Grid>
                                          )}
                                        </Card>
                                      )}
                                      <Grid
                                        container
                                        style={{ paddingLeft: "1rem" }}
                                      >
                                        <Divider className="divider" light />
                                      </Grid>
                                    </div>
                                  );
                                })}
                            </Grid>
                          );
                        })
                      ) : (
                        <Grid
                          container
                          justify="center"
                          style={{ marginTop: "50px" }}
                        >
                          {intl.formatMessage({ id: "No_items_Available" })}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item>
                      {" "}
                      <Divider style={{ marginTop: "90px", width: "100%" }} />
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid
                      onClick={() => window.open("https://blend.menu/")}
                      container
                      style={{
                        cursor:"pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Grid item>
                        <p className="power-by">
                          {intl.formatMessage({ id: "Powered_by_Blend" })}
                        </p>
                      </Grid>
                      <Grid item>
                        <img
                          src={BlendPowerIcon}
                          width={"15px"}
                          style={{ paddingLeft: "10px" }}
                          alt=""
                        ></img>
                      </Grid>
                    </Grid>

                    {/* <img src={BlendPowerIcon }> </img> */}
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify="center">
                  {menusLength === 0 ? (
                    <Grid
                      container
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "60vh",
                        display: "flex",
                      }}
                    >
                      <Typography
                        className="description"
                        style={{ paddingLeft: "1rem" }}
                      >
                        {intl.formatMessage({ id: "No_available_items" })}
                      </Typography>
                    </Grid>
                  ) : (
                    <CircularProgress
                      classes={{
                        root: "circularProgress",
                      }}
                      size={40}
                      style={{ padding: "10%" }}
                    />
                  )}
                </Grid>
              )}
            </Grid>
            {localStorage.getItem("show-tab-expire") === "yes" && (
              <TabExpireDialog tabExpire={true} setTabExpire={setTabExpire} />
            )}

            {orderReducer.orderList && orderReducer.orderList.length > 0 ? (
              <div>{<OrderBar count={orderReducer.orderCount} />}</div>
            ) : (
              <></>
            )}
        
          </Grid>
        </>
      ) : (
        <>
          {loader ? (
            <>
              <Grid container justify="center">
                <CircularProgress
                  classes={{
                    root: "circularProgress",
                  }}
                  size={40}
                  style={{ padding: "10%" }}
                />
              </Grid>
              <Grid
                container
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "80vh",
                }}
              >
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",

                    marginTop: "20vh",
                  }}
                >
                  <Logo />
                  
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid container justify="center" style={{ marginTop: "50px" }}>
                {intl.formatMessage({ id: "Url_Not_Found" })}
              </Grid>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MenuList;

const useStyles = makeStyles((theme) => ({
  priceDescription: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#777777",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#777777",
  },

  buttonText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
  },
  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#fff",
  },
  snackbar: {
    "&.MuiSnackbar-root": {
      top: "49%",
      height: "36px",
    },
    "& .MuiSnackbarContent-root": {
      borderRadius: "24px",
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#FFFFFF",
      height: "36px",
      background: "rgba(0, 0, 0, 0.75)",
      flexGrow: 0,
      display: "flex",
      justifyContent: "center",
    },
  },
  customSnackbar: {
    "&.MuiSnackbar-root": {
      top: "49%",
      height: "36px",
    },
    "& .MuiSnackbarContent-message": {
      padding: "0px 10px",
      textAlign: "center",
    },
    "& .MuiSnackbarContent-root": {
      borderRadius: "24px",
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#FFFFFF",
      height: "36px",
      background: "rgba(0, 0, 0, 0.75)",
      flexGrow: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
