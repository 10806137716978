import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Truncate from "react-truncate";
const CardFooter = (props) => {
  const { dish, currency } = props;
  const classes = useStyles();
  return (
    <>
      {dish &&
        dish.prices.map((n, i) => {
          return (
            <Grid
              container
              style={{
                marginTop: "1rem",
              }}
            >
              <Grid item>
                <Typography className="dishPrice" variant="body2" component="p">
                  {parseFloat(n.amount) === parseFloat("0")
                    ? ""
                    : parseFloat(n?.amount).toLocaleString("en-US", {
                        style: "currency",
                        currency: currency?currency:"usd",
                      })}
                </Typography>
              </Grid>
              <Grid item>
                <Truncate className={classes.priceDescription} lines={1}>
                  {n?.description}
                </Truncate>
              </Grid>
            </Grid>
          );
        })}
    </>
  );
};
export default CardFooter;

const useStyles = makeStyles((theme) => ({
  priceDescription: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#777777",
    paddingLeft: "10px",
    display: "flex",
    alignItems: "center",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#777777",
  },

  buttonText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
  },
  backButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#fff",
  },
}));
