export const setCookies = (sessionId, domain, expiryDate, hotelDomain,room_number) => {
  let d = new Date(expiryDate);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    "session" +
    "=" +
    sessionId +
    ";" +
    expires +
    ";domain=" +
    domain +
    "; path=/";
  document.cookie =
    "id" + "=" + hotelDomain + ";" + expires + ";domain=" + domain + "; path=/";
  document.cookie =
    "room_number" + "=" + room_number + ";" + expires + ";domain=" + domain + "; path=/";
};
export const removeCookies = (domain) => {
  let d = new Date();
  d.setTime(d.getTime() - 2 * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie =
    "session" +
    "=" +
    "empty" +
    ";" +
    expires +
    ";domain=" +
    domain +
    "; path=/";
  document.cookie =
    "id" + "=" + domain + ";" + expires + ";domain=" + domain + "; path=/";
    document.cookie =
    "room_number" + "=" + "empty" + ";" + expires + ";domain=" + domain + "; path=/";
};
export const getCookie = (cname, domain) => {
  if(domain){
  var name = cname + "=";
  var ca = document.cookie.split(";");
  let str = "";
  for (var i = 0; i < ca.length; i++) {
     str = ca[i];
    while (str.charAt(0) == " ") {
      str = str.substring(1);
    }
    if (str.indexOf("id") == 0) {
      str = str.substring(3, str.length).trim();
      break;
    }
  }
  
  if (str === domain) {
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}else{
  return ""
}
};
export const getRoomNumberFromCookies = (cname, domain) => {
  if(domain){
  var name = cname + "=";
  var ca = document.cookie.split(";");
  let str = "";
  for (var i = 0; i < ca.length; i++) {
     str = ca[i];
    while (str.charAt(0) == " ") {
      str = str.substring(1);
    }
    if (str.indexOf("id") == 0) {

      str = str.substring(3, str.length).trim();
      break;
    }
  }
  if (str === domain) {
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return "";
}else{
  return ""
}
};
