import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { defaultThemeColor } from "../../constant/colors";
import emailSent from "../../assets/images/emailSent.png";
import { sendEmail } from "../../actions/order";
import Message from "../../components/Dialog/Message";
import { useIntl } from "react-intl";

const Login = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const intl = useIntl();

  let buttonColor = useSelector((state) => state?.menuReducer?.buttonColor);
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let emailReducer = useSelector((state) => state?.hotelReducer?.email);

  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [height, setHeight] = useState(window.textareaheight);
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (open) {
      setInterval(() => {
        setOpen(false);
      }, 4700);
    }
    // eslint-disable-next-line
  }, [open]);
  useEffect(() => {
    setHeight(window.textareaheight);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (menuReducer?.menu?.read_only_menu === true) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu]);

  const openInbox = () => {
    window.open(
      "https://www.google.com/gmail",
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  useEffect(() => {
    if (
      emailReducer === null ||
      emailReducer === undefined ||
      emailReducer === ""
    ) {
      history.push("/login");
    }
    // eslint-disable-next-line
  }, [emailReducer]);
  const handleBack = () => {
    history.push("/login");
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 5000);
    }
  }, [message]);
  const againSendEmail = async () => {
    setLoader(true);
    let res = await dispatch(sendEmail(emailReducer));
    if (res?.status === 200) {
      setLoader(false);
      setMessage(intl.formatMessage({ id: "Email_sent_Successfully" }));
      setOpen(true);
    } else {
      setLoader(false);
      setMessage(intl.formatMessage({ id: "Something_went_wrong_Try_Again" }));
      setOpen(true);
    }
  };
  return (
    <Grid
      container
      direction="column"
      style={{
        height: height,
        minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
          ? "90vh"
          : "85vh",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden !important",
        width: "100%",
      }}
    >
      <Message handleClose={handleClose} open={open} message={message} />
      <Grid>
        <Grid container style={{ marginTop: "55px", marginBottom: "10px" }}>
          <Grid
            xs={2}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowBackIosIcon
              style={{ paddingLeft: "20px" }}
              onClick={handleBack}
            />
          </Grid>
          <Grid xs={8}></Grid>
          <Grid xs={2}></Grid>
        </Grid>
        <DialogContent style={{ marginTop: "30px" }}>
          <Grid contanier>
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={emailSent} height={"220px"} width="220px" />
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Typography className={classes.textHeading}>
                {intl.formatMessage({ id: "We_have_sent_you_an_email" })}
                <br />
                {intl.formatMessage({ id: "with_your_session_link" })}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.body}>{emailReducer}</Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>

      <Grid container style={{ marginBottom: "10%", marginTop: "10px" }}>
        <Grid item xs={12} className={classes.buttonDiv}>
          <Button
            className={classes.button1}
            style={{
              background: localStorage.getItem("color")
                ? localStorage.getItem("color")
                : defaultThemeColor,
            }}
            onClick={openInbox}
          >
            {intl.formatMessage({ id: "Go_to_your_inbox" })}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.buttonDiv}>
          {loader ? (
            <Button className={classes.cancelbutton}>
              <CircularProgress size={25} />{" "}
            </Button>
          ) : (
            <Button className={classes.cancelbutton} onClick={againSendEmail}>
              {intl.formatMessage({ id: "Send_again" })}
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Login;

const useStyles = makeStyles((theme) => ({
  textHeading: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineheight: "20px",
    textAlign: "center",
    color: "#000000",
  },
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  outlinedStyle: {
    border: "0px",
  },
  formControl: {
    minWidth: "100%",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
  },
  topText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    padding: "10px 50px 0px 50px",
  },
  centerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#000000",
  },

  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    margin: "8px 0px",

    color: "#000000",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },

  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    color: "#777777 !important",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginTop: "-2px",
      textAlign: "center",
    },
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      color: "#777777",
      background: "#FFFFFF",
      border: "1px solid #E3E3E3",
      boxSizing: "border-box",
      borderRadius: "5px",
      maxHeight: "50px",
      height: "50px",
    },
    "& .MuiInputBase-input:focus": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline::after": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  nullPadding: {
    "& .MuiAutocomplete-input": {
      padding: "1px !important",
    },
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },

  button1: {
    width: " 100%",
    height: "100%",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },

  buttonDisabled: {
    width: " 100%",
    height: "100%",
    background: "#AEAEAE",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  // eslint-disable-next-line
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
