import React, { useDebugValue, useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createOpenTab } from "../actions/order";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { getTableAllList } from "../actions/order";
import { getVenueDetails } from "../actions/Menu";
import { defaultThemeColor } from "../constant/colors";
import { useLocation } from "react-router-dom";
import EnterTable from "./VenueOpenTab/EnterTable";
import { useIntl } from "react-intl";
import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../utils/constants";
import { getCookie } from "../utils/common";
import LanguageDropDown from "../components/SelectLanguage/Select"

const OpenTab = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const [tableListView, settableListView] = useState([]);
  const [currentPage, setCurrentPage] = useState("");

  const history = useHistory();
  const location = useLocation();
  let dispatch = useDispatch();

  let menuReducer = useSelector((state) => state?.menuReducer);
  const [table, setTable] = useState(null);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  let tableList = useSelector((state) => state?.menuReducer?.tableList);
  useEffect(() => {
    if (props?.table_number) {
      localStorage.setItem("table_number",props.table_number)
      setTableNumber(props.table_number);
      if(localStorage.getItem("tabId")!=="empty"){
      history.push(`/menu/?tab=${localStorage.getItem("tabId")}`)
      }
    }
    // eslint-disable-next-line
  }, [props]);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [height, setHeight] = useState(window.textareaheight);

  useEffect(() => {
    let table_Number = new URLSearchParams(location.search).get("table");
    if (table_Number) {
      setTable(table_Number);
      setTableNumber(table_Number)
      localStorage.setItem("table_number",table_Number)

    }
    setHeight(window.textareaheight);
    if (
      localStorage?.tabId !== null &&
      localStorage?.tabId !== undefined &&
      localStorage?.tabId !== "empty"
    ) {
      history.push(`/menu/?tab=${localStorage.getItem("tabId")}`)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (menuReducer?.menu?.read_only_menu === true||
      menuReducer?.menu?.accept_orders_without_tab === ACCEPT_ORDERS_WITHOUT_TAB
      ) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu?.read_only_menu]);

  const handleChange = (event) => {
    setTableNumber(event.target.value);
  };

  const onCreate = () => {
    if (firstName.length > 0 && lastName.length > 0 && tableNumber.length > 0) {
      setLoading(true);
      dispatch(
        createOpenTab({
          venue_uuid: menuReducer.menu?.uuid,
          table_number: tableNumber,
          first_name: firstName,
          last_name: lastName,
        })
      ).then((res) => {
        if (res && res === 200) {
          setError(null);
          setTableNumber(null);
          setFirstName(null);
          setLastName(null);
          setLoading(false);

          setTimeout(() => {
            history.push("/menu");
          }, 200);
        } else if (res && res === 404) {
          setError("Error");
          setLoading(false);
        }
      });
      setError(null);
    } else {
      setError(
        `${intl.formatMessage({ id: "Enter" })} ${
          firstName.length > 0
            ? ""
            : `(${intl.formatMessage({ id: "First_Name" })})`
        } ${
          lastName.length > 0
            ? ""
            : `(${intl.formatMessage({ id: "Last_Name" })})`
        } ${
          tableNumber.length > 0
            ? ""
            : `(${intl.formatMessage({ id: "Table_Number" })})`
        }`
      );
    }
  };
  useEffect(() => {
    let sessionCookies=null;
    if(menuReducer?.menu?.business_account?.domain){
      sessionCookies = getCookie("session",menuReducer?.menu?.business_account?.domain);
     }
    
    let session2 =sessionCookies|| new URLSearchParams(location.search).get("sessionid");
    if (session2) {
      setCurrentPage("session");
    } else {
      getData();
    }
    localStorage.setItem("sessionId", session2);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    
    if(menuReducer?.menu?.business_account?.domain){
    let sessionCookies = getCookie("session",menuReducer?.menu?.business_account?.domain);

    let session2 =sessionCookies|| new URLSearchParams(location.search).get("sessionid");
    if (session2) {
      setCurrentPage("session");
    } else {
      getData();
    }
    localStorage.setItem("sessionId", session2);}
    // eslint-disable-next-line
  }, [menuReducer?.menu?.business_account?.domain]);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, [tableList]);

  const getData = async () => {
    setLoader(true);
    const url =
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host;
    // eslint-disable-next-line
    if (!menuReducer?.menu) {
      let res = await dispatch(getVenueDetails(url + "/d/"));
    }
    // eslint-disable-next-line
    let res1 = await dispatch(getTableAllList(url + "/tables/list/"));
    setLoader(false);
  };

  const handleCancel = () => {
    if (localStorage.getItem("from") === "menu") {
      localStorage.removeItem("from");
      history.push("/menu");
    } else {
      // history.push("/");
      let path=`/?from=open-tab`;
      if(table){
        path=path+`&table=${table}`
      }
      history.push(path);

    }
  };
  return (
    <>
      {loader ? (
        <LoadingScreen />
      ) : (
        <>
          {currentPage === "session" ? (
            <EnterTable />
          ) : (
            <Grid
              container
              direction="column"
              style={{
                height: height,
                minHeight: window?.matchMedia("(display-mode: standalone)")
                  ?.matches
                  ? "90vh"
                  : "85vh",
                display: "flex",
                justifyContent: "space-between",
                overflowY: "hidden !important",
              }}
            >
              <Grid container justify="flex-end">
                <Grid item style={{padding:"16px"}}>
              <LanguageDropDown/>
              </Grid>
              </Grid>
              <Grid container>
                <Grid
                  style={{
                    marginTop: "4%",
                    marginBottom: "10px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography className={classes.topText}>
                
                    {intl.formatMessage({ id: "Open_tab" })}
                  </Typography>
                </Grid>
                <DialogContent>
                  {error === null ? (
                    ""
                  ) : (
                    <p className={classes.errorText}>{error}</p>
                  )}
                  <p className={classes.GenralText}>
                    {" "}
                    {intl.formatMessage({ id: "Table_Number" })}
                  </p>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={tableNumber}
                      className={classes.selectTable}
                      onChange={handleChange}
                      disabled={props.table_number||table}

                    >
                      {tableListView.map((item) => (
                        <MenuItem value={item.id}>{item.id}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <p className={classes.GenralText}>
                    {intl.formatMessage({ id: "First_Name" })}
                  </p>
                  <TextField
                    variant="outlined"
                    value={firstName}
                    // autoFocus={open}
                    onChange={(e) => {
                      setFirstName(e.target.value);
                    }}
                    placeholder={intl.formatMessage({ id: "Enter_here" })}
                    fullWidth
                    style={{
                      marginBottom: 10,
                    }}
                    inputProps={{
                      className: classes.TextInputStyle2,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                  <p className={classes.GenralText}>
                    {intl.formatMessage({ id: "Last_Name" })}
                  </p>
                  <TextField
                    variant="outlined"
                    value={lastName}
                    onChange={(e) => {
                      setLastName(e.target.value);
                    }}
                    placeholder={intl.formatMessage({ id: "Enter_here" })}
                    fullWidth
                    style={{
                      marginBottom: 10,
                    }}
                    inputProps={{
                      className: classes.TextInputStyle2,
                    }}
                    InputProps={{
                      classes: {
                        notchedOutline: classes.outlinedStyle,
                      },
                    }}
                  />
                </DialogContent>
              </Grid>

              <Grid container style={{ marginBottom: "6%" }}>
                <Grid item xs={12} className={classes.buttonDiv}>
                  {!loading ? (
                    <Button
                      onClick={onCreate}
                      className={classes.button1}
                      style={{
                        background: localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor,
                      }}
                    >
                      {intl.formatMessage({ id: "Open_tab" })}
                    </Button>
                  ) : (
                    <Button
                      className={classes.button1}
                      style={{
                        background: localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor,
                      }}
                    >
                      <CircularProgress size={25} style={{ color: "#fff" }} />
                    </Button>
                  )}
                </Grid>

                <Grid item xs={12} className={classes.buttonDiv}>
                  <Button
                    className={classes.cancelbutton}
                    onClick={handleCancel}
                  >
                    {intl.formatMessage({ id: "Cancel" })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export default OpenTab;

const useStyles = makeStyles((theme) => ({
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
  },
  TextInputStyle2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    color: "#777777 !important",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginTop: "-2px",
      textAlign: "center",
    },
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      color: "#777777",
      background: "#FFFFFF",
      border: "1px solid #E3E3E3",
      boxSizing: "border-box",
      borderRadius: "5px",
      maxHeight: "50px",
      height: "50px",
    },
    "& .MuiInputBase-input:focus": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline::after": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  formControl: {
    // margin: theme.spacing(1),

    minWidth: "100%",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },

  topText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "10px 50px 0px 50px",
  },

  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "18px",
    margin: "8px 0px",

    color: "#000000",
  },
  centerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#000000",
  },
  button: {
    width: " 100%",
    height: "100%",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px",
  },
  nullPadding: {
    "& .MuiAutocomplete-input": {
      padding: "1px !important",
    },
  },

  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineheight: "18px",
    textAlign: "center",
    color: "#000000",
  },

  button1: {
    width: " 100%",
    height: "100%",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },

  buttonDisabled: {
    width: " 100%",
    height: "100%",
    background: "#AEAEAE",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  // eslint-disable-next-line
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
