import React, { useEffect, useLayoutEffect, useState, useMemo } from "react";
import API from "../../utils/API";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import StripeInput from "./StripeInput";
import {
  useStripe,
  useElements,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { defaultThemeColor } from "../../constant/colors";
// import { } from "stripe";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import PayButton from "./PayButton";
import { Grid } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "3rem 1rem",

  },
  otherCardText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    color: "#777777",
  },
  paymentRequestButton: {
    height: "40px",
  },
  fieldtext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",

    display: "flex",
  },
  totalorder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    margin: "24px 0 0 0",
  },
  incl: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    margin: "0px",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
  },

  button: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    bottom: 13,
  },
}));

const useOptions = (paymentRequest) => {
  const options = useMemo(
    () => ({
      paymentRequest,
      style: {
        paymentRequestButton: {
          theme: "dark",
          height: "48px",
          width: "50%",
          type: "default",
        },
      },
    }),
    [paymentRequest]
  );

  return options;
};
const usePaymentRequest = ({ options, onPaymentMethod }) => {
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [canMakePayment, setCanMakePayment] = useState(false);

  useEffect(() => {
    if (stripe && paymentRequest === null) {
      const pr = stripe.paymentRequest(options);
      setPaymentRequest(pr);
    }
  }, [stripe, options, paymentRequest]);

  useEffect(() => {
    let subscribed = true;
    if (paymentRequest) {
      paymentRequest.canMakePayment().then((res) => {
        if (res && subscribed) {
          setCanMakePayment(true);
        }
      });
    }

    return () => {
      subscribed = false;
    };
  }, [paymentRequest]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", onPaymentMethod);
    }
    return () => {
      if (paymentRequest) {
        paymentRequest.off("paymentmethod", onPaymentMethod);
      }
    };
  }, [paymentRequest, onPaymentMethod]);

  return canMakePayment ? paymentRequest : null;
};

function Checkout(props) {
  const intl = useIntl();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const orderReducer = useSelector((state) => state.orderReducer);
  const menu = useSelector((state) => state.menuReducer.menu);
  // const [paymentRequest, setPaymentRequest] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [processing, setProcessing] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [emailError, setEmailError] = useState("");
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = async (event) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const handleSubmit = async (ev, b) => {
    setProcessing(true);

    ev.preventDefault();
    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.getItem("tabId"),
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    const obj = {
      note: props.location.state.orderNote,
      tip_amount: props.location.state.tip ? props.location.state.tip : 0,
      table_number: props.location.state.table_number
        ? props.location.state.table_number
        : 10,
      payment_method: "card",
      email: email,
    };
    // set body
    if (!email || email.trim() === "") {
      delete obj.email;
    }
    const body = JSON.stringify({ ...obj });

    const response = await API.post(
      `/api/order/${location.state.orderID}/checkout/`,
      body,
      config
    );
    if (response && response.data && response.data.payment_intent) {
      const payload = await stripe.confirmCardPayment(
        response.data.payment_intent,
        {
          // receipt_email: email,
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        }
      );
      if (payload.error) {
        setError(`
        ${intl.formatMessage({
          id: "Payment_failed",
        })} ${payload.error.message}`);
        setProcessing(false);
      } else {
        setError(null);
        setProcessing(false);
        setSucceeded(true);
        history.push("/order");
      }
    } else {
      setError(`Payment failed `);
      setProcessing(false);
    }
  };

  const changeEmail = (e) => {
    setEmail(e.target.value);
    // eslint-disable-next-line
    if (
      // eslint-disable-next-line
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value) ||
      e.target.value === ""
    ) {
      // eslint-disable-next-line
      setEmailError("");
    } else {
      setEmailError("Invalid email");
    }
  };

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paymentRequest = usePaymentRequest({
    options: {
      country: "CY",
      currency: menu?.currency ? menu?.currency.toLowerCase() : "eur",
      total: {
        label: "Order Total Payment",
        amount: parseInt(location.state.total * 100),
      },
    },
    onPaymentMethod: async ({ complete, paymentMethod, ...data }) => {
      const config =
        localStorage.getItem("tabId") &&
        localStorage.getItem("tabId") !== "empty"
          ? {
              headers: {
                "Content-Type": "application/json",
                "tab-uuid": localStorage.getItem("tabId"),
              },
            }
          : {
              headers: {
                "Content-Type": "application/json",
              },
            };

      // set body
      const body = JSON.stringify({
        note: props.location.state.orderNote,
        tip_amount: props.location.state.tip ? props.location.state.tip : 0,
        table_number: props.location.state.table_number
          ? props.location.state.table_number
          : 10,
        payment_method: paymentMethod?.type ? paymentMethod?.type : "card",
      });
      const response = await API.post(
        `/api/order/${location.state.orderID}/checkout/`,
        body,
        config
      );
      if (response && response.data && response.data.payment_intent) {
        const payload = await stripe.confirmCardPayment(
          response.data.payment_intent,
          {
            payment_method: paymentMethod.id,
          },
          { handleActions: false }
        );
        if (payload.error) {
          setError(`Payment failed ${payload.error.message}`);
          setProcessing(false);
          complete("fail");
        } else {
          setError(null);
          setProcessing(false);
          setSucceeded(true);
          history.push("/order");
          complete("success");
        }
      }
    },
  });
  const options = useOptions(paymentRequest);

  // if (paymentRequest) {
  //   return <PaymentRequestButtonElement options={{ paymentRequest }} />;
  // }
  return (
    <div style={{ width: "100%" }}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <div className={classes.root}>
          <Grid>
            <p className={classes.totalorder} style={{ paddingBottom: "20px" }}>
            {intl.formatMessage({
          id: "payment_information",
        })}
              
            </p>
          </Grid>

          {paymentRequest && paymentRequest !== null && (
            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div style={{ minWidth: "78%" }}>
                <PaymentRequestButtonElement options={options} />
              </div>
              <p className={classes.otherCardText}>
              {intl.formatMessage({
          id: "Or_enter_card_details",
        })}
                 </p>
            </Grid>
          )}

          {/* <Grid
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={cardIos}
            width={"240px"}
            alt=""
            style={{ cursor: "pointer" }}
          ></img> 
          <p className={classes.otherCardText}>Or enter card details </p>
        </Grid> */}

          <p className={classes.totalorder} style={{ paddingBottom: "20px" }}>
          {intl.formatMessage({
          id: "your_card_details",
        })}
            
          </p>
          <TextField
            label= {intl.formatMessage({
              id: "Credit_Card_Number",
            })}
            name="ccnumber"
            variant="outlined"
            required
            fullWidth
            margin={"normal"}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardNumberElement,
              },
            }}
          />
          <TextField
            label={intl.formatMessage({
              id: "Expiration_Date",
            })}
            name="ccexp"
            variant="outlined"
            required
            fullWidth
            margin={"normal"}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardExpiryElement,
              },
            }}
          />
          <TextField
            label={intl.formatMessage({
              id: "CVC",
            })}
            name="cvc"
            variant="outlined"
            required
            fullWidth
            margin={"normal"}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              inputComponent: StripeInput,
              inputProps: {
                component: CardCvcElement,
              },
            }}
          />
          {error && (
            <div
              className="card-error"
              role="alert"
              style={{ color: "red", fontWeight: 700 }}
            >
              {error}
            </div>
          )}
          <p className={classes.totalorder} style={{ paddingBottom: "20px" }}>
          {intl.formatMessage({
              id: "Email_Receipt_optional",
            })}
            
          </p>
          <TextField
            label={intl.formatMessage({
              id: "Email",
            })}
            name="Email"
            variant="outlined"
            value={email}
            error={emailError}
            helperText={emailError}
            fullWidth
            margin={"normal"}
            onChange={changeEmail}
            InputLabelProps={{ shrink: true }}
            placeholder={intl.formatMessage({
              id: "Write_your_email",
            })}
          />

          <Grid container spacing={0} style={{ padding: "20px 0px 30px 0px" }}>
            <Grid item xs={6}>
              <Grid>
                <p className={classes.totalorder}>
                {intl.formatMessage({
              id: "Order_total",
            })}
                  </p>
              </Grid>
              <Grid>
                <p className={classes.incl}>(
                  {intl.formatMessage({
              id: "incl_VAT",
            })}
                  ) </p>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end" }}>
              <p className={classes.price}>
                {parseFloat(
                  parseFloat(location.state.total).toFixed(2)
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: location.state.currency,
                })}
              </p>
            </Grid>
          </Grid>
        </div>
        <PayButton
          disable={processing || disabled || succeeded || emailError}
          style={
            processing || disabled || succeeded || emailError
              ? { backgroundColor: "#AEAEAE !important" }
              : {
                  backgroundColor: localStorage.getItem("color")
                    ? localStorage.getItem("color")
                    : defaultThemeColor,
                }
          }
          orderClass={
            processing || disabled || succeeded || emailError
              ? classes.buttonDisabled
              : classes.button
          }
          loader={processing}
        />
      </form>
    </div>
  );
}

export default Checkout;
