import React from "react";
import BlendPowerIcon from "../../assets/images/BlendIcon.png";
import { Grid, Divider } from "@material-ui/core";
import { useIntl } from "react-intl";

const Footer = () => {
  const intl = useIntl();

  return (
    <div>
      <Grid item xs={12} style={{padding:"0px 10px"}}>
        <Divider style={{ marginTop: "90px", width: "100%" }} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          onClick={() => window.open("https://blend.menu/")}
          container
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item>
            <p className="power-by">
              {intl.formatMessage({ id: "Powered_by_Blend" })}
            </p>
          </Grid>
          <Grid item>
            <img
              src={BlendPowerIcon}
              width={"15px"}
              style={{ paddingLeft: "10px" }}
              alt=""
            ></img>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;
