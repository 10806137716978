import React, { useState } from "react";
import { Grid, Typography, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../../assets/images/no-image-found.png";
import TabBar from "../../components/Hotel/TabBar";
import VenueCard from "../../components/VenueCard/index";
import { ROOM_SERVICE } from "../../utils/constants";
import { useIntl } from "react-intl";
import Footer from "../../components/Footer/Footer"
const LandingPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const history = useHistory();
  const [loader, setLoader] = useState(true);
  let menuReducer = useSelector((state) => state.menuReducer);
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  const [height, setHeight] = useState(window.textareaheight);

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleSwitchPage = (id, domain, type, VType) => {
    if (hotelDetails?.guest_session_details?.room_number) {
      const params = new URL(hotelDetails?.guest_session_details?.session_url)
        ?.search;
      let session2 = new URLSearchParams(params).get("session");
      if (VType === ROOM_SERVICE && type !== "view") {
        window.open(
          `https://${domain}/menu/?sessionid=${session2}&room_no=${hotelDetails?.guest_session_details?.room_number}`,
          "_top"
        );
      } else if (type === "view") {
        window.open(
          `https://${domain}/menu/?sessionid=${session2}&room_no=${hotelDetails?.guest_session_details?.room_number}&type=${type}`,
          "_top"
        );
      } else {
        window.open(
          `https://${domain}/open-tab/?sessionid=${session2}&room_no=${hotelDetails?.guest_session_details?.room_number}`,
          "_top"
        );
      }
    } else {
      if (type === "view") {
        window.open(`https://${domain}/menu/?type=${type}`, "_top");
      } else {
        window.open(`https://${domain}`, "_top");
      }
      // props.handleChangePage();
    }
  };
  return (
    <Grid
      container
      direction="column"
      style={{
        height: height,
        // eslint-disable-next-line
        minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
          ? "90vh"
          : "85vh",
        display: "flex",
        overflow: "hidden !important",
      }}
    >
      <TabBar />
      <Grid>
        <Grid
          container
          style={{
            display: "flex",
            marginTop: "6%",
          }}
        >
          <Grid item style={{ padding: "20px" }}>
            {isLoading ? (
              <Skeleton
                animation="wave"
                variant={"circle"}
                width={"124px"}
                height={"124px"}
              />
            ) : null}
            <img
              onLoad={() => {
                setIsLoading(false);
              }}
              title={props.title}
              alt={props.title}
              onError={(err) => {
                setIsLoading(false);
                setIsError(true);
              }}
              src={isError ? noimage : hotelDetails?.logo}
              className={classes.logoContaier}
              style={{ display: isLoading ? "none" : "block" }}
            />
          </Grid>
          <Grid
            item
            className={classes.centerAlign}
            style={{ maxWidth: "50%" }}
          >
            <Grid>
              <Grid item style={{ width: "100%" }}>
                <Typography className={classes.hotelName}>
                  {hotelDetails?.name}
                </Typography>
              </Grid>
              <Grid item style={{ width: "100%" }}>
                <Typography className={classes.hotelDesc}>
                  {hotelDetails?.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{
            marginTop: "5%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {hotelDetails &&
            hotelDetails.venues &&
            hotelDetails.venues.map((item) => {
              return (
                <Grid key={item?.id} style={{ width: "100%" }}>
                  <VenueCard item={item} handleChangePage={handleSwitchPage} />
                </Grid>
              );
            })}
        </Grid>
        <Footer/>
      </Grid>
    </Grid>
  );
};

export default LandingPage;

const useStyles = makeStyles((theme) => ({
  centerAlign: {
    display: "flex",
    alignItems: "center",
  },
  hotelDesc: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
  },
  hotelName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
  },
  logoContaier: {
    // border: "1px solid #202020",
    border: "1px solid #e3e3e3",
    
    
    width: "127px !important",
    height: "127px !important",
    backgroundColor: "#fff !important",
    // boxSizing: "border-box !important",
    borderRadius: "20px !important",
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineheight: "18px",
    textAlign: "center",
    color: "#000000",
  },

  button1: {
    width: " 100%",
    height: "100%",
    background: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
    "&:focus": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
  },
  button2: {
    width: " 100%",
    height: "100%",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    textTransform: "none",
    border: `1px solid ${
      localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366"
        ? localStorage.getItem("color")
          ? localStorage.getItem("color")
          : "#FF3366"
        : "#FF3366"
    }`,
  },
  button3: {
    width: " 100%",
    height: "100%",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    background: "#AEAEAE",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
