import {
  GET_DETAIL,
  SET_UPDATED,
  ORDER_HISTORY,
  SET_RESTAURANT_MENU,
  SET_RESTAURANT_DETAILS,
  TABLE_LIST,
  SELECTED_MENU,
  GET_HOTEL_DETAILS,
  REORDER_LIST,
  SET_EMAIL,
  CLEAR_OLD_ORDER,
  SET_MENU_REQUEST_LOADING,
  UPDATE_LANGUAGE,
} from "../constant/types";
import axios from "axios";
import API from "../utils/API";
import moment from "moment";
import { setCookies, removeCookies ,getCookie} from "../utils/common";
import { getOrderDetail } from "./order";

let domainUrl = process.env.REACT_APP_COOKIES_DOMAIN
  ? process.env.REACT_APP_COOKIES_DOMAIN
  : "blend.menu";
export const getIcon = async () => {
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  try {
    let url = `${
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.origin
    }/logo.jpeg`;
    const response = await API.get(url, config);
    localStorage.setItem("icon", response?.data);
    return response?.data;
  } catch (error) {
    return { status: error?.response?.status, error: error };
  }
};
export const venueLogin =
  ({ venue_uuid, surname, room_number, check_out_datetime, table_number }) =>
  async (dispatch, getState) => {
    let vDomain = getState()?.menuReducer?.menu?.business_account?.domain;
    // set body
    const body = JSON.stringify({
      surname,
      room_number,
      checkout_date: check_out_datetime,
      table_number,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        venue_uuid: venue_uuid,
      },
    };
    try {
      const response = await API.post(
        `/api/venue/customer-guest-session/`,
        body,
        config
      );
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        localStorage.setItem("tabId", response.data.tab_uuid);
        localStorage.setItem("tabLink", response.data.tab_link);
        if (response.data?.guest_account?.guest_session_uuid) {
          let sessionData = response?.data?.guest_account;

          setCookies(
            sessionData?.guest_session_uuid,
            domainUrl,
            sessionData?.check_out_datetime,
            vDomain,
            room_number
          );

          localStorage.setItem(
            "sessionId",
            response.data?.guest_account?.guest_session_uuid
          );
        }
        // guest_session_uuid
        // guest_account
        dispatch({ type: "ORDER_EXPIRE", payload: false });

        return { status: response.status, tab_Link: response.data.tab_link };
      }
    } catch (error) {
      return { status: error.response.status, error: error };
    }
  };

export const updateLanguage =
  ({ data }) =>
  async (dispatch, getState) => {
    dispatch({
      type: UPDATE_LANGUAGE,
      payload: data,
    });
  };
export const getHotelDetails = (url, session) => async (dispatch, getState) => {
  try {
    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? url === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) +
      "/details-slim/";
    const result = await axios.get(currentUrl, {
      headers: session
        ? {
            "Content-Type": "application/json",
            "guest-session-uuid": session,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.guest_session_details?.guest_session_uuid) {
        let room_number = result?.data?.guest_session_details?.room_number;
        setCookies(
          result?.data?.guest_session_details?.guest_session_uuid,
          domainUrl,
          result?.data?.guest_session_details?.check_out_datetime,
          window.location.hostname,
          room_number
        );

        if (session !== null) {
          localStorage.setItem(
            "sessionId",
            result?.data?.guest_session_details?.guest_session_uuid
          );
          //           check_in_datetime: null
          // check_out_datetime: "2021-06-16T12:00:00Z"
          // guest_session_uuid: "b8bc838be0f3eb0fe20bdf181f92b4776184d993"
          // id: 135
          // room_number

          let sessionData = result?.data?.guest_session_details;
          let data =
            result?.data?.business_account?.domain || window.location.hostname;
          setCookies(
            sessionData?.guest_session_uuid,
            domainUrl,
            sessionData?.check_out_datetime,
            data,
            room_number
          );
        }
        localStorage.setItem(
          "check_out_datetime",
          JSON.stringify(
            result?.data?.guest_session_details?.check_out_datetime
          )
        );
      } else {
        localStorage.setItem("sessionId", null);
        localStorage.setItem("check_out_datetime", null);
        removeCookies(domainUrl);
      }
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {
     
      if (
        localStorage.getItem("check_out_datetime") &&
        JSON.parse(localStorage.getItem("check_out_datetime")) &&
        moment().isBefore(
          moment(JSON.parse(localStorage.getItem("check_out_datetime")))
        )
      ) {
      } else {
        // removeCookies(domainUrl);

        localStorage.setItem("sessionId", null);
        localStorage.setItem("check_out_datetime", null);
      }

      if (result?.data?.menus) {
        dispatch({
          type: SET_RESTAURANT_MENU,
          payload: result.data.id ? result.data : "",
        });
      } else {
        dispatch({
          type: SET_RESTAURANT_DETAILS,
          payload: result.data.id ? result.data : "",
        });
      }
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200 };
    } else {
      return { display_hotel_details: false, status: 200 };
    }
  } catch (error) {
    return { status: 400 };
  }
};
export const getVenueDetails = (url) => async (dispatch, getState) => {
  try {
    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? url === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) +
      "/details-slim/";
    const result = await axios.get(currentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {


      
      if (result?.data?.menus) {
        dispatch({
          type: SET_RESTAURANT_MENU,
          payload: result.data.id ? result.data : "",
        });
      } else {
        try {
          let sessionCookies = getCookie(
            "session",
            result?.data?.business_account?.domain
          );
          if(sessionCookies){
          const config2 = {
            headers: {
              "Content-type": "application/json",
              venue_uuid: result.data.uuid,
              "guest-session-uuid":sessionCookies
            },
          };
          const response2 = await API.get(
            `/api/venue/check-for-guest-session/`,
            config2
          );}
        } catch (error) {
        removeCookies(domainUrl);
          
        localStorage.setItem("sessionId", null);
        localStorage.setItem("check_out_datetime", null);
        localStorage.setItem("tabLink", "empty");
        localStorage.setItem("tabId", "empty");
        window.location.replace(
          window.location.origin + window.location.pathname
        );
        }
        

        dispatch({
          type: SET_RESTAURANT_DETAILS,
          payload: result.data.id ? result.data : "",
        });
      }
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200, data: result?.data };
    } else {
      return { display_hotel_details: false, status: 200, data: result?.data };
    }
  } catch (error) {
    return { status: 400 };
  }
};
export const getMenusList = () => async (dispatch, getState) => {
  let language = getState()?.languageReducer?.language;
  let selectedMenu=getState()?.menuReducer?.selectedMenu;
  console.log(selectedMenu)
  if (!getState()?.menuReducer?.requestInprogress) {
    try {
      const currentUrl =
        (process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_VENUE_API_URL
          : window.location.protocol + "//" + window.location.host) +
        "/menu-details/";
      dispatch({
        type: SET_MENU_REQUEST_LOADING,
        payload: true,
      });
      const result = await axios.get(currentUrl, {
        headers: {
          "Content-Type": "application/json",
          language: language,
        },
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          // do whatever you like with the percentage complete
          // maybe dispatch an action that will update a progress bar or something
        },
      });
      if (result?.data) {
        dispatch({
          type: SET_RESTAURANT_MENU,
          payload: { menus: [...result.data] },
        });
      }

      if (selectedMenu===null&&result?.data && result?.data[0]?.id) {
        dispatch({
          type: SELECTED_MENU,
          payload: result.data[0]?.id,
        });
      }
      dispatch({
        type: SET_MENU_REQUEST_LOADING,
        payload: false,
      });
      return { display_hotel_details: false, status: 200, data: result?.data };
    } catch (error) {
      return { status: 400 };
    }
  }
};
