import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import OrderNavbar from "./OrderNavbar";
import { Elements } from "@stripe/react-stripe-js";
import { useHistory, useLocation } from "react-router-dom";
import StripeCardForm from "./StripeForm";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

export default function Checkout() {
  const intl = useIntl();

  const menuReducer = useSelector((state) => state.menuReducer);
  let promise = null;
  if (
    menuReducer?.menu?.stripe_account_ref !== undefined &&
    menuReducer?.menu?.stripe_account_ref !== null
  ) {
    promise = loadStripe(
      process.env.NODE_ENV === "development"
        ? "pk_test_w9AhA5wmYeLTxzH2kfz4SPHd00Bpg1xLN0"
        : process.env.REACT_APP_STRIPE_KEY,
      {
        stripeAccount:
          menuReducer &&
          menuReducer.menu &&
          menuReducer.menu.stripe_account_ref,
      }
    );
  }
  const location = useLocation();
  let history = useHistory();
  const orderReducer = useSelector((state) => state.orderReducer);
  useEffect(() => {
    if (
      !location.state ||
      menuReducer?.menu?.stripe_account_ref === null ||
      menuReducer?.menu?.stripe_account_ref === undefined ||
      (orderReducer && !orderReducer.orderDetail) ||
      (orderReducer &&
        orderReducer.orderDetail &&
        orderReducer.orderDetail.status !== 0)
    ) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <OrderNavbar
        text={intl.formatMessage({
          id: "Checkout",
        })}
      />

      {location.state && promise !== null && (
        <Elements stripe={promise}>
          <StripeCardForm location={location} />
        </Elements>
      )}
    </div>
  );
}
