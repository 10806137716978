import {
  GET_DETAIL,
  SET_UPDATED,
  ORDER_HISTORY,
  SET_RESTAURANT_MENU,
  TABLE_LIST,
  SELECTED_MENU,
  GET_HOTEL_DETAILS,
  REORDER_LIST,
  SET_EMAIL,
  CLEAR_OLD_ORDER,
  UPDATE_ORDER_COUNT
} from "../constant/types";
import axios from "axios";
import API from "../utils/API";
import moment from "moment";
import { getCookie } from "../utils/common";
export const clearOldOrders = () => async (dispatch) => {
  dispatch({ type: CLEAR_OLD_ORDER });
};
export const sendEmail = (email) => async (dispatch) => {
  try {
    // ? process.env.REACT_APP_APP_TYPE === "hotel"

    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_APP_TYPE === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) +
      "/send-session-email/";
    const result = await axios.post(
      currentUrl,
      {
        email: email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({
      type: SET_EMAIL,
      payload: email,
    });
    return { status: 200 };
  } catch (error) {
    return { status: 400 };
  }
};
export const requestService = (payload) => async (dispatch) => {
  try {
    // const body = {
    //   request_type: 1,
    //   payment_method: "cash",
    // };
    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.getItem("tabId"),
              order_uuid: payload.order_uuid,
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
              order_uuid: payload.order_uuid,
            },
          };
    const result = await axios.post(
      "/api/order/request-service/",
      payload.body,
      config
    );
    return { status: 200, message: "success" };
  } catch (error) {
    return { status: 400, message: "A request is already Open for your Order" };
  }
};
export const getVenueDetailsBySlug = (slug) => async (dispatch, getState) => {
  try {
    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? process.env.REACT_APP_HOTEL_API_URL
        : window.location.protocol + "//" + window.location.host) +
      `/api/venue/details/${slug}/`;
    const result = await axios.get(currentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {
      dispatch({
        type: SET_RESTAURANT_MENU,
        payload: result.data.id ? result.data : "",
      });
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200 };
    } else {
      return { display_hotel_details: false, status: 200 };
    }
  } catch (error) {
    return { status: 400 };
  }
};
export const getVenueDetailsById = (url, id) => async (dispatch, getState) => {
  try {
    // ? process.env.REACT_APP_APP_TYPE === "hotel"

    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? url === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) + "/d/";
    const result = await axios.get(currentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {
      dispatch({
        type: SET_RESTAURANT_MENU,
        payload: result.data.id ? result.data : "",
      });
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200 };
    } else {
      return { display_hotel_details: false, status: 200 };
    }
  } catch (error) {
    return { status: 400 };
  }
};
export const getHotelDetails = (url, session) => async (dispatch, getState) => {
  try {
    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? url === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) + "/d/";
    const result = await axios.get(currentUrl, {
      headers: session
        ? {
            "Content-Type": "application/json",
            "guest-session-uuid": session,
          }
        : {
            "Content-Type": "application/json",
          },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.guest_session_details?.guest_session_uuid) {
        if (session !== null) {
          localStorage.setItem(
            "sessionId",
            result?.data?.guest_session_details?.guest_session_uuid
          );
        }
        localStorage.setItem(
          "check_out_datetime",
          JSON.stringify(
            result?.data?.guest_session_details?.check_out_datetime
          )
        );
      } else {
        localStorage.setItem("sessionId", null);
        localStorage.setItem("check_out_datetime", null);
      }
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {
      if (
        JSON.parse(localStorage.getItem("check_out_datetime")) &&
        moment().isBefore(
          moment(JSON.parse(localStorage.getItem("check_out_datetime")))
        )
      ) {
      } else {
        localStorage.setItem("sessionId", null);
        localStorage.setItem("check_out_datetime", null);
      }

      dispatch({
        type: SET_RESTAURANT_MENU,
        payload: result.data.id ? result.data : "",
      });
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200 };
    } else {
      return { display_hotel_details: false, status: 200 };
    }
  } catch (error) {
    return { status: 400 };
  }
};
export const getVenueDetails = (url) => async (dispatch, getState) => {
  try {
    // ? process.env.REACT_APP_APP_TYPE === "hotel"

    const currentUrl =
      (process.env.NODE_ENV === "development"
        ? url === "hotel"
          ? process.env.REACT_APP_HOTEL_API_URL
          : process.env.REACT_APP_VENUE_API_URL
        : window.location.protocol + "//" + window.location.host) + "/d/";
    const result = await axios.get(currentUrl, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    let obj = {
      url: window.location.protocol + "//" + window.location.host,
      display_hotel_details: result?.data?.display_hotel_details,
    };
    localStorage.setItem("hotel", JSON.stringify(obj));
    localStorage.setItem(
      "menuId",
      result?.data?.uuid ? result?.data?.uuid : ""
    );
    if (result?.data?.display_hotel_details) {
      if (result?.data?.colour) {
        localStorage.setItem("color", result?.data?.colour);
      }
      dispatch({
        type: GET_HOTEL_DETAILS,
        payload: result.data,
      });
    } else {
      dispatch({
        type: SET_RESTAURANT_MENU,
        payload: result.data.id ? result.data : "",
      });
    }
    if (result.data?.menus && result.data?.menus[0]?.id) {
      dispatch({
        type: SELECTED_MENU,
        payload: result.data?.menus[0]?.id,
      });
    }
    if (result?.data?.display_hotel_details) {
      return { display_hotel_details: true, status: 200, data: result?.data };
    } else {
      return { display_hotel_details: false, status: 200, data: result?.data };
    }
  } catch (error) {
    return { status: 400 };
  }
};

export const createOpenTabWithSession =
  ({ venue_uuid, first_name, table_number }) =>
  async (dispatch, getState) => {
    // set body
    let vDomain = getState()?.menuReducer?.menu?.business_account?.domain;

    const body = JSON.stringify({
      venue_uuid: venue_uuid,
      table_number: table_number,
      first_name: first_name,
    });
    let sessionCookies = getCookie("session", vDomain);

    const config = {
      headers: {
        "Content-type": "application/json",
        "guest-session-uuid":
          sessionCookies || localStorage.getItem("sessionId"),
      },
    };
    try {
      const response = await API.post(
        `/api/order/create-order-with-tab/`,
        body,
        config
      );
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        localStorage.setItem("tabId", response.data.tab_uuid);
        localStorage.setItem("tabLink", response.data.tab_link);

        dispatch({ type: "ORDER_EXPIRE", payload: false });

        return { status: response.status, tab_Link: response.data.tab_link };
      }
    } catch (error) {
      return { status: error.response.status, error: error };
    }
  };
export const createOpenTabWithSessionRoomService =
  ({ venue_uuid, first_name, table_number, guest_session_uuid }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      venue_uuid: venue_uuid,
      table_number: table_number,
      first_name: first_name,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        "guest-session-uuid": localStorage.getItem("sessionId"),
      },
    };
    try {
      const response = await API.post(
        `/api/order/create-order-with-tab/`,
        body,
        config
      );
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        localStorage.setItem("tabId", response.data.tab_uuid);
        localStorage.setItem("tabLink", response.data.tab_link);

        dispatch({ type: "ORDER_EXPIRE", payload: false });

        return { status: response.status, tab_Link: response.data.tab_link };
      }
    } catch (error) {
      return { status: error.response.status, error: error };
    }
  };
export const reOrder =
  ({ orderId, prevOrderId, orderItem }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-type": "application/json",
        "order-uuid": orderId,
      },
    };

    try {
let totalQuantity=0;

      const response = await API.post(
        `/api/order/re-order/`,
        JSON.stringify({ order_to_copy_uuid: prevOrderId }),
        config
      );
      if (response && response.data && response.data.order_uuid) {
        let d = response.data.order_items;
        let newArr = [];
        d.forEach((element) => {
          totalQuantity=totalQuantity+parseInt(element.quantity)
          let itemOrderPrice = parseFloat(
            parseFloat(element.price) * parseInt(element.quantity)
          );
          let option = [];
          let customisation_options = [];
          let subtotal = 0;
          element.order_item_options.forEach((options) => {
            subtotal = subtotal + parseFloat(options?.price);
            option.push(options?.customisation_option_ref);
            customisation_options.push({
              order_option_id: options?.order_option_id,
              customisation_option_id: options?.customisation_option_ref,
              name: options?.name,
              availability: { id: "84", out_of_stock: false },
              price: options?.price,
              position: options?.position,
              display_as_main_item: options?.display_as_main_item,
            });
          });

          newArr.push({
            ...element,
            item_id: element.item_ref,
            itemOrderPrice: parseFloat(itemOrderPrice) + subtotal,
            itemPrice: element.price,
            options: [...option],
            item_customisations: [
              {
                position: 0,
                availability: { id: "84", out_of_stock: false },
                customisation_options: [...customisation_options],
              },
            ],
          });
        });

        dispatch({ type: REORDER_LIST, payload: [...newArr] });
        dispatch({ type: UPDATE_ORDER_COUNT, payload: totalQuantity });

        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);

        return response?.status;
      }
    } catch (error) {
      return error?.response?.status;
    }
  };

export const createOrder =
  ({ venue_id, orderList, orderNote, tab_link, tab_id }) =>
  async (dispatch) => {
    const config =
      tab_id && tab_id !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": tab_id,
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    const body = JSON.stringify({ venue_uuid: venue_id });

    try {
      const response = await API.post("/api/order/create/", body, config);
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        // !tab_id&&localStorage.removeItem("tabId");
        // !tab_id&&localStorage.removeItem("orderId");
        tab_id && localStorage.setItem("tabId", tab_id);
        tab_id && tab_link !== "empty"
          ? localStorage.setItem("tabLink", `${tab_link}/menu/?tab=${tab_id}`)
          : localStorage.setItem("tabLink", "empty");

        dispatch({ type: "ORDER_EXPIRE", payload: false });
        dispatch({ type: "TAB_EXPIRE", payload: false });

        return response?.status;
      }
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch({ type: "ORDER_EXPIRE", payload: false });
        dispatch({ type: "TAB_EXPIRE", payload: false });
        localStorage.setItem("tabLink", "empty");
        localStorage.setItem("tabId", "empty");
        localStorage.setItem("show-tab-expire", "yes");

        window.location.replace(
          window.location.origin + window.location.pathname
        );
      }
      return error?.response?.status;
    }
  };

export const checkout =
  ({ note, tip_amount, table_number, payment_method, orderID }) =>
  async (dispatch) => {
    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.getItem("tabId"),
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    const body = JSON.stringify({
      note,
      tip_amount,
      table_number,
      payment_method,
    });
    dispatch({ type: "SET_LOADER", payload: true });
    try {
      const response = await API.post(
        `/api/order/${orderID}/checkout/`,
        body,
        config
      );
      if (response && response.data && response.data.order_uuid) {
        if (response.data.payment_intent) {
          dispatch({
            type: "SET_PAYMENT_INTENT",
            payload: response.data.payment_intent,
          });
        }
        dispatch({ type: SET_UPDATED, payload: true });
        dispatch({ type: "SET_LOADER", payload: false });
      }
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
    }
  };

export const getOrderDetail =
  ({ order_uuid }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    try {
      const response = await API.get(
        `/api/order/${order_uuid}/details/`,
        config
      );
      if (response && response.data) {
        dispatch({ type: GET_DETAIL, payload: response.data });
      }
    } catch (error) {
      console.log(error);
    }
  };

export const getTableListByUrl = (url) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  try {
    const response = await API.get(`${url}/tables/list/`, config);
    if (response && response.data) {
      // dispatch({ type: TABLE_LIST, payload: response.data });
    }
    return response;
  } catch (error) {
    return error;

    console.log(error);
  }
};
export const getTableAllList = (url) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  try {
    const response = await API.get(url, config);
    if (response && response.data) {
      dispatch({ type: TABLE_LIST, payload: response.data });
    }
    return { status: 200 };
  } catch (error) {
    console.log(error);
    return { status: 400 };
  }
};
export const getTableList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  try {
    const response = await API.get(`/tables/list/`, config);
    if (response && response.data) {
      dispatch({ type: TABLE_LIST, payload: response.data });
    }
    return { status: 200 };
  } catch (error) {
    console.log(error);
    return { status: 400 };
  }
};

export const addItemOrder =
  ({
    order_uuid,
    item_id,
    options,
    quantity,
    note,
    price_id,
    orderItem,
    orderCount,
  }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      item_id: item_id,
      options: options,
      quantity: quantity,
      note: note,
      price_id: price_id,
    });
    dispatch({ type: "SET_LOADER", payload: true });
    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.getItem("tabId"),
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    try {
      const response = await API.post(
        `/api/order/${order_uuid}/add-item/`,
        body,
        config
      );
      if (response.data && response.data.order_item_id) {
        dispatch({
          type: "ADD_ORDER_COUNT",
          payload: orderCount,
        });
        dispatch({
          type: "ADD_ORDER_ITEM",
          payload: { ...orderItem, price_id: price_id, ...response.data },
        });
      }
      dispatch({ type: "SET_LOADER", payload: false });
    } catch (error) {
      if (error?.response?.status === 403) {
        dispatch(getTabDetail({ order_uuid: order_uuid }));
      }

      dispatch({ type: "SET_LOADER", payload: false });
    }
  };
export const updateItemOrder =
  ({
    order_uuid,
    order_item_id,
    options,
    quantity,
    note,
    orderItem,
    orderCount,
    price_id,
  }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      order_item_id: order_item_id,
      options: options,
      quantity: quantity,
      note: note,
      price_id: price_id,
    });
    dispatch({ type: "SET_LOADER", payload: true });
    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.tabId,
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    try {
      // eslint-disable-next-line
      const response = await API.post(
        `/api/order/${order_uuid}/edit-item/`,
        body,
        config
      );
      dispatch({ type: "SET_LOADER", payload: false });
      dispatch({
        type: "ADD_ORDER_COUNT",
        payload: orderCount,
      });
      dispatch({
        type: "UPDATE_ORDER_ITEM",

        payload: { ...orderItem, price_id: price_id },
      });
    } catch (error) {
      dispatch({ type: "SET_LOADER", payload: false });
      if (error.response.status === 403) {
        dispatch(getTabDetail({ order_uuid: order_uuid }));
      }
    }
  };

export const addOrderNote =
  ({
    order_uuid,

    note,
  }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      note: note,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      // eslint-disable-next-line
      const response = await API.post(
        `/api/order/${order_uuid}/set-note/`,
        body,
        config
      );
    } catch (error) {}
  };
export const removeOrder =
  ({
    order_uuid,

    order_item_id,
    orderItem,
  }) =>
  async (dispatch) => {
    const body = JSON.stringify({
      order_item_id: order_item_id,
    });

    const config =
      localStorage.getItem("tabId") && localStorage.getItem("tabId") !== "empty"
        ? {
            headers: {
              "Content-Type": "application/json",
              "tab-uuid": localStorage.tabId,
            },
          }
        : {
            headers: {
              "Content-Type": "application/json",
            },
          };
    try {
      // eslint-disable-next-line
      const response = await API.post(
        `/api/order/${order_uuid}/remove-item/`,
        body,
        config
      );
      dispatch({
        type: "REDUCE_ORDER_COUNT",
        payload: orderItem.quantity,
      });
      dispatch({
        type: "REMOVE_ORDER_ITEM",
        payload: orderItem,
      });
    } catch (error) {
      console.log(error);
      if (error.response.status === 403) {
        dispatch(getTabDetail({ order_uuid: order_uuid }));
      }
    }
  };
export const addOrderTip =
  ({ order_uuid, tip }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      amount: tip,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      // eslint-disable-next-line
      const response = await API.post(
        `/api/order/${order_uuid}/set-tip/`,
        body,
        config
      );
    } catch (error) {}
  };
export const addItemInHistory = () => async (dispatch) => {
  let order_uuid = localStorage.getItem("orderId");

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  // set body
  try {
    const response = await API.get(`/api/order/${order_uuid}/details/`, config);
    if (response && response.data) {
      dispatch({ type: ORDER_HISTORY, payload: response.data });
      localStorage.setItem("orderId", null);
    }
  } catch (error) {
    console.log(error);
    if (error.response.status === 403) {
      dispatch(getTabDetail({ order_uuid: order_uuid }));
    }
  }
};

export const joinTabClient =
  ({ venue_uuid, table_number, password }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      venue_uuid: venue_uuid,
      table_number: table_number,
      password: password,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    try {
      const response = await API.post(`/api/order/join-tab/`, body, config);
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        // !tab_id&&localStorage.removeItem("tabId");
        // !tab_id&&localStorage.removeItem("orderId");
        localStorage.setItem("tabId", response.data.tab_uuid);
        localStorage.setItem("tabLink", response.data.tab_link);

        dispatch({ type: "ORDER_EXPIRE", payload: false });

        return response.status;
      }
    } catch (error) {
      return error.response.status;
    }
  };

export const createOpenTab =
  ({ venue_uuid, first_name, last_name, table_number }) =>
  async (dispatch) => {
    // set body
    const body = JSON.stringify({
      venue_uuid: venue_uuid,
      table_number: table_number,
      first_name: first_name,
      last_name: last_name,
    });

    const config = {
      headers: localStorage.getItem("sessionId")
        ? {
            "Content-type": "application/json",
            "guest-session-uuid": localStorage.getItem("sessionId"),
          }
        : {
            "Content-type": "application/json",
          },
    };
    try {
      const response = await API.post(
        `/api/order/create-order-with-tab/`,
        body,
        config
      );
      if (response && response.data && response.data.order_uuid) {
        await dispatch(
          getOrderDetail({
            order_uuid: response.data.order_uuid,
          })
        );
        localStorage.setItem("orderId", response.data.order_uuid);
        localStorage.setItem("tabId", response.data.tab_uuid);
        localStorage.setItem("tabLink", response.data.tab_link);

        dispatch({ type: "ORDER_EXPIRE", payload: false });

        return response?.status;
      }
    } catch (error) {
      return error?.response?.status;
    }
  };

export const getTabDetail =
  ({ order_uuid }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    try {
      if (
        localStorage.getItem("sessionId") !== null &&
        localStorage.getItem("sessionId") !== "null" &&
        localStorage.getItem("menuId")
      ) {
        const config2 = {
          headers: {
            "Content-Type": "application/json",
            "guest-session-uuid": localStorage.getItem("sessionId"),
            "venue-uuid": localStorage.getItem("menuId"),
          },
        };
        const response2 = await API.get(
          "/api/venue/check-for-existing-tab/",
          config2
        );
        if (!response2?.data?.id) {
          dispatch({ type: "TAB_EXPIRE", payload: true });
        }
      }
      const response = await API.get(
        `/api/order/${order_uuid}/status/`,
        config
      );
      if (response && response.data) {
        if (response.data.has_tab_expired === true) {
          dispatch({ type: "TAB_EXPIRE", payload: true });
        } else {
          dispatch({ type: "ORDER_EXPIRE", payload: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

export const updateTabExpire =
  ({ value }) =>
  async (dispatch) => {
    dispatch({ type: "TAB_EXPIRE", payload: value });
  };

export const checkForExistingTab =
  ({ venue_uuid, sessionId }) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    // set body
    try {
      if (venue_uuid && sessionId) {
        const config2 = {
          headers: {
            "Content-Type": "application/json",
            "guest-session-uuid": sessionId,
            "venue-uuid": localStorage.getItem("menuId"),
          },
        };
        const response2 = await API.get(
          "/api/venue/check-for-existing-tab/",
          config2
        );
        if (response2?.data?.id) {
          return { status: 200, data: response2 };
        } else {
          return { status: 400, data: response2 };
        }
      }
    } catch (error) {
      return { status: 400 };
    }
  };
