import React from "react";
import { Button, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { defaultThemeColor } from "../../constant/colors";
import thumbs_up from "../../assets/images/thumbs_up.png";

export default function TabExpireDialog(props) {
  const { open, handleClose, serviceType } = props;
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let tabExpire = useSelector((state) => state.orderReducer.tabExpire);

  const handleCloseYes = () => {
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{
          borderRadius: "20px",
        }}
        className={classes.rounded}
        onClose={handleCloseYes}
      >
        <DialogContent
          style={
            {
              // width: "300px",
              // height: "218px",
              // background: " #FFFFFF",
              // boxShadow: "0px 2px 23px rgba(0, 0, 0, 0.144928)",
            }
          }
        >
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <p className={classes.tabText}>
            {intl.formatMessage({
              id: "A_server_will_be_with",
            })}
            <br />
            {intl.formatMessage({
              id: "you_shortly",
            })}
          </p>
          <Grid container justify="center">
            <img
              src={thumbs_up}
              style={{
                borderRadius: "50%",
                width: "126px",
                height: "126px",
              }}
              alt="confirm server arival"
            />
          </Grid>

          <Grid
            container
            className={classes.buttonDiv2}
            style={{ marginTop: "30px" }}
          >
            <Button
              className={classes.button}
              onClick={handleCloseYes}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
            >
              {intl.formatMessage({
                id: "Ok",
              })}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  rounded: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 20px 0px",
  },

  button: {
    width: " 100%",
    height: "40px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    minWidth: "250px",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv2: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
    width: "100%",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
    maxWidth: "120px",
    minWidth: "115px",
  },
  dialog: {
    width: "300px",
  },
}));
