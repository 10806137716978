import React, { useState } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../assets/images/no-image-found.png";

function SmartImage(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  return (
    <>
      {isLoading ? (
        <Skeleton
          animation="wave"
          variant={props.type}
          width={props.width}
          height={props.height}
        />
      ) : null}

      <img
        style={{ display: isLoading ? "none" : "block" }}
        src={isError ? noimage : props.url}
        className={props.styleClass ? props.styleClass : ""}
        onLoad={() => {
          setIsLoading(false);
        }}
        title={props.title}
        alt={props.title}
        onError={(err) => {
          setIsLoading(false);
          setIsError(true);
        }}
      />
    </>
  );
}

export default SmartImage;
