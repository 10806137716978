import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    borderRadius: "10px",
    margin: "20px",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.08)",
    cursor: "default",
  },
  media: {
    height: 140,
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    marginBottom: "2px",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
  },
  actionButtons: {
    width: "105px",
    height: "40px",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    cursor: "pointer",
  },
}));

export default function VenueCard(props) {
  const classes = useStyles();
  const intl = useIntl();
  const { item } = props;
  return (
    <Card
      className={classes.root}
      onClick={() =>
        props.handleChangePage(
          item?.uuid,
          item?.domain,
          item?.read_only_menu?"view":"order",
          item?.type
        )
      }
    >
      <CardActionArea
        style={{
          cursor: "default",
        }}
      >
        <CardMedia
          className={classes.media}
          image={item?.cover_image}
          title="Contemplative Reptile"
        />
        <CardContent style={{padding:"0px", paddingBottom: "6px" }}>
        <Grid container justify="space-between" style={{ padding: "16px 16px 18px 20px" }}>
          <Grid item style={{ display: "flex", alignItems: "center",
       maxWidth:"calc(100% - 140px)"
        }}>
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.header}>{item?.name}</Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className={classes.body}>
                  {item?.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <img
              width={"100px"}
              height={"100px"}
              style={{ borderRadius: "20%" }}
              src={item?.logo}
            />
          </Grid>
        </Grid>
        {/* <Grid
            container
            style={{
              marginTop: "12px",
              marginBottom: "13px",
            }}
          >
            <Grid
              item
              className={classes.actionButtons}
              style={{ marginRight: "10px" }}
            >
              <Typography
                className={classes.buttonText}
                onClick={() =>
                  props.handleChangePage(
                    item?.uuid,
                    item?.domain,
                    "view",
                    item?.type
                  )
                }
              >
                {intl.formatMessage({ id: "view_menu" })}
              </Typography>
            </Grid>
            {!item?.read_only_menu && (
              <Grid item className={classes.actionButtons}>
                <Typography
                  className={classes.buttonText}
                  onClick={() =>
                    props.handleChangePage(
                      item?.uuid,
                      item?.domain,
                      "order",
                      item?.type
                    )
                  }
                >
                  {intl.formatMessage({ id: "order_now" })}
                </Typography>
              </Grid>
            )}
          </Grid>
     */}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
