import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Grid, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { defaultThemeColor } from "../../constant/colors";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  iconShare: {
    height: "23",
    marginTop: "7px",
    width: "17px",
  },
  activeRoomNumber: {
    paddingTop: "4px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    color: "#FFFFFF",
    margin: "0px",
    lineHeight: "14px",
    letterSpacing: "0.41px",
    textTransform: "uppercase",
  },
  tableText2: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    margin: "0px",

    fontSize: "16px",
    lineHeight: "18px",
    // textAlign: "center",
    color: "#FFFFFF",
    // marginTop: "-10px",
  },
  exitButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "20px",
    transform: "none",
    color: "#FFFFFF",
    border: "1px solid #FFFFFF",
    margin: "5px 8px 0px 0px",
    padding: "4px",
  },

  activeText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "18px",
    margin: "0px",
    color: "#FFFFFF",
    marginTop: "10px",
  },

  tableText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    margin: "0px",

    fontSize: "16px",
    lineHeight: "18px",
    // textAlign: "center",
    color: "#FFFFFF",
  },

  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    margin: "0px",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#FFFFFF",
  },
  appBar: {
    // eslint-disable-next-line
    position: "-webkit-sticky",
    // eslint-disable-next-line
    position: "sticky",
    top: 0,
    bottom: "auto",
    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
    paddingLeft: "0px",
    height: "40px",
  },
  YourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
  },
  toolbar: {
    paddingLeft: "0px",
    paddingRight: "0px",
  },
  icon: {
    marginLeft: "10px",
    fontSize: "20px",
    cursor: "pointer",
  },
}));
function TabBar(props) {
  const intl = useIntl();

  const classes = useStyles();
  const history = useHistory();
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error));
    } else {
      console.log("Share is Not available at this device");
    }
  }; // eslint-disable-next-line
  const [iosTest, setIosTest] = useState(false);
  useEffect(() => {
    // const venue_Id = localStorage.getItem("menuId");

    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;
    if (isIos() && !isInStandaloneMode()) {
      setIosTest(true);
    }
  }, []);

  const funOpen = () => {
    if (props.mainPage) {
      props.setSelectedCard(null);
      props.setShowDetails(false);
    }
    localStorage.setItem("from", "hotel");
    history.push("/login");
  };

  return (
    <>
    {hotelDetails?.guest_session_details?.room_number&&
      <AppBar
        position="sticky"
        className={classes.appBar}
        id="tab-bar"
        style={{
          background: localStorage.getItem("color")
            ? localStorage.getItem("color")
            : defaultThemeColor,
        }}
      >
        
        <Grid container style={{ paddingLeft: "17px" }}>
          <Grid item xs={6}>
            {hotelDetails?.guest_session_details?.room_number ? (
              <Grid>
                <p className={classes.activeRoomNumber}>
                  {intl.formatMessage({
                    id: "room",
                  })}
                </p>
                <p className={classes.tableText2}>
                  {hotelDetails?.guest_session_details?.room_number}{" "}
                </p>
              </Grid>
            ) : (
              <p className={classes.activeText}>
                <span className={classes.tabText}>
                  {intl.formatMessage({
                    id: "Staying_at_the_hotel",
                  })}
                </span>
              </p>
            )}
          </Grid>

          <Grid
            container
            item
            xs={6}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {hotelDetails?.guest_session_details?.room_number ? (
              <>
                <Button className={classes.exitButton} onClick={handleShare}>
                  {intl.formatMessage({
                    id: "Share_link",
                  })}
                </Button>
              </>
            ) : (
              <>
                <Button className={classes.exitButton} onClick={funOpen}>
                  {intl.formatMessage({
                    id: "Login",
                  })}
                </Button>
              </>
            )}
          </Grid>
        </Grid>
</AppBar>
  }   
      </>
  );
}

export default TabBar;
