import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../actions/order";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "15px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 10px 0px",
  },

  button: {
    width: " 100%",
    height: "100%",

    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function OrderExpireDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  const orderExpire = useSelector((state) => state.orderReducer.orderExpire);
  const intl = useIntl();

  const handleCloseYes = () => {
    localStorage.removeItem("orderId");
    dispatch(createOrder({ venue_id: menuReducer.menu?.uuid }));

    history.push("/");
  };

  return (
    <div>
      <Dialog
        open={orderExpire}
        style={{
          width: "100%",
          height: "500px",
          margin: "50px 0px 0px 0px",
          borderRadius: "20px",
        }}
      >
        <DialogContent>
          <p className={classes.tabText}>Your Order has expired. </p>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.button}
              onClick={handleCloseYes}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
