import { GET_HOTEL_DETAILS, SET_EMAIL } from "../constant/types";

const initialState = {
  details: null,
  email: null,
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_EMAIL: {
      return {
        ...state,
        email: payload,
      };
    }
    case GET_HOTEL_DETAILS: {
      return {
        ...state,
        details: payload,
      };
    }

    default:
      return state;
  }
}
