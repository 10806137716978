import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import { defaultThemeColor } from "../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  chipInactive: {
    margin: theme.spacing(0.5),
    border: "0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: " 6px 12px",
    height: "30px",
    color: "#777777",
    borderRadius: "7px",
    cursor: "pointer",
  },
  chipActive: {
    margin: theme.spacing(0.5),
    border: "0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: " 6px 12px",
    height: "30px",
    color: "#ffffff",

    borderRadius: "7px",
    cursor: "pointer",
  },
}));

export default function TopGridMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  // eslint-disable-next-line
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldScroll(true);
    }, 3000);
  }, []);

  return (
    <div style={props.style}>
      <Grid container>
        <Grid
          item
          xs={2}
          style={{
            maxWidth: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon
            onClick={() => history.push(`/search/${window.location.search}`)}
            style={{ color: "#0C0E0F", cursor: "pointer" }}
          />
        </Grid>
        <Grid item xs={10}>
          <div className="top-menubar-ul">
            {props.menuList.length > 0 &&
              props.menuList.map((item) => (
                <Chip
                  key={item.id}
                  variant="outlined"
                  size="medium"
                  label={item.name}
                  component="a"
                  className="scrollTest"
                  classes={{
                    root:
                      menuReducer && menuReducer.selectedMenu === item.id
                        ? classes.chipActive
                        : classes.chipInactive,
                  }}
                  style={{
                    backgroundColor:
                      menuReducer && menuReducer.selectedMenu !== item.id
                        ? "transparent"
                        : localStorage.getItem("color")
                        ? localStorage.getItem("color")
                        : defaultThemeColor,
                  }}
                  clickable={false}
                  onClick={() => {
                    dispatch({
                      type: "SELECTED_MENU",
                      payload: item.id,
                    });
                    // window.scrollTo(0, 0);
                    props.executeScroll(item.id);
                    // props.setSelectedSubMenu(null);
                  }}
                />
              ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
