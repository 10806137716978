import { applyMiddleware, createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "../reducers/index";
const middleWare = [thunk];

const reducer = persistReducer(
  {
    key: "rrsb", // key is required
    storage, // storage is now required
    // whitelist: ["orderReducer", "menuReducer"],
    whitelist: ["orderReducer","languageReducer"],
  },
  combineReducers({ ...rootReducer })
);

/* istanbul ignore next */
const configStore = (initialState = {}) => {
  const store = createStore(
    reducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleWare))
  );

  // if (module.hot) {
  //   module.hot.accept(() => {
  //     store.replaceReducer(require("../reducers/index").default);
  //   });
  // }

  return {
    persistor: persistStore(store),
    store,
  };
};

const { store, persistor } = configStore();

global.store = store;

export { store, persistor };
