import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { joinTabClient } from "../actions/order";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import { getTableAllList } from "../actions/order";
import { getVenueDetails } from "../actions/Menu";
import { defaultThemeColor } from "../constant/colors";
import { useIntl } from "react-intl";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../utils/constants";
import LanguageDropDown from "../components/SelectLanguage/Select";

import LoadingScreen from "../components/LoadingScreen/LoadingScreen";
const JoinTab = () => {
  const intl = useIntl();
  const location = useLocation();
  const [error, setError] = useState(null);
  let tableList = useSelector((state) => state?.menuReducer?.tableList);
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  const [tableListView, settableListView] = useState([]);
  const [password, setPassword] = useState(null);
  const [tableNumber, setTableNumber] = useState("");
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(window.textareaheight);
  const [table, setTable] = useState(null);

  useEffect(() => {
    let table_Number = new URLSearchParams(location.search).get("table");
    if (table_Number) {
      setTable(table_Number);
      setTableNumber(table_Number);
    }
    setHeight(window.textareaheight);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      menuReducer?.menu?.read_only_menu === true ||
      menuReducer?.menu?.accept_orders_without_tab === ACCEPT_ORDERS_WITHOUT_TAB
    ) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu]);

  const handleChange = (event) => {
    setTableNumber(event.target.value);
  };
  const onJoin = () => {
    if (password?.length > 0 && tableNumber?.length > 0) {
      setLoading(true);
      dispatch(
        joinTabClient({
          venue_uuid: menuReducer.menu?.uuid,
          password: password,
          table_number: tableNumber,
        })
      ).then((res) => {
        if (res && res === 200) {
          setError(null);
          setTableNumber(null);
          setPassword(null);
          setLoading(false);
          history.push("/menu");
        } else if (res && res === 404) {
          setLoading(false);
          setError(
            intl.formatMessage({
              id: "Table_number_or_password_match",
            })
          );
        } else if (res && res === 400) {
          setError(
            intl.formatMessage({
              id: "Missing_fields",
            })
          );
          setLoading(false);
        }
      });
    } else {
      setError(
        `${intl.formatMessage({
          id: "Enter",
        })} ${
          password?.length > 0
            ? ""
            : `(${intl.formatMessage({
                id: "Password",
              })})`
        } ${
          tableNumber?.length > 0
            ? ""
            : "(" +
              intl.formatMessage({
                id: "Table_Number",
              }) +
              ")"
        }`
      );
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    if (
      localStorage?.tabId !== null &&
      localStorage?.tabId !== undefined &&
      localStorage?.tabId !== "empty"
    ) {
      history.push(`/menu/?tab=${localStorage.getItem("tabId")}`)
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, [tableList]);

  const getData = async () => {
    setLoader(true);
    if (!menuReducer?.menu) {
      const url =
        (process.env.NODE_ENV === "development"
          ? "https://busaba.staging.blend.menu"
          : window.location.protocol + "//" + window.location.host) + "/d/";
      // eslint-disable-next-line
      let res = await dispatch(getVenueDetails(url));
    }
    let tables_url =
      (process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host) +
      "/tables/list-all/";
    // eslint-disable-next-line
    let res1 = await dispatch(getTableAllList(tables_url));
    if (res1?.status === 200) {
      setLoader(false);
    }
  };

  const handleCancel = () => {
    if (localStorage.getItem("from") === "menu") {
      localStorage.removeItem("from");
      history.push("/menu");
    } else {
      let path = `/?from=open-tab`;
      if (table) {
        path = path + `&table=${table}`;
      }
      history.push(path);
    }
  };
  const handleEnterText = (evt) => {
    if (evt?.target?.value?.length > 0) {
      let x = evt?.target?.value[evt?.target?.value?.length - 1];
      if (x >= 0 && x <= 9) {
        setPassword(evt.target.value);
      }
    } else {
      setPassword(evt.target.value);
    }
  };
  return (
    <>
      {loader ? (
        <LoadingScreen />
      ) : (
        <Grid
          container
          direction="column"
          style={{
            height: height,
            minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
              ? "90vh"
              : "85vh",
            display: "flex",
            justifyContent: "space-between",
            overflow: "hidden !important",
            width: "100%",
          }}
        >
          <Grid container justify="flex-end">
            <Grid item style={{ padding: "16px" }}>
              <LanguageDropDown />
            </Grid>
          </Grid>
          <Grid>
            <Grid style={{ marginTop: "6%", marginBottom: "10px" }}>
              <Typography className={classes.topText}>
                {intl.formatMessage({ id: "Join_tab" })}
              </Typography>
              <Typography className={classes.errorText}>{error}</Typography>
            </Grid>
            <DialogContent>
              <p className={classes.GenralText}>
                {intl.formatMessage({ id: "Table" })}
              </p>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={tableNumber}
                  className={classes.selectTable}
                  onChange={handleChange}
                  disabled={table}
                >
                  {tableListView.map((item) => (
                    <MenuItem value={item.id}>{item.id}</MenuItem>
                  ))}
                </Select>
              </FormControl>

              <p className={classes.GenralText}>
                {intl.formatMessage({ id: "Password" })}
              </p>
              <TextField
                variant="outlined"
                value={password}
                onChange={(e) => {
                  handleEnterText(e);
                }}
                type="tel"
                placeholder={intl.formatMessage({ id: "Enter_here" })}
                style={{ paddingBottom: 10 }}
                fullWidth
                // eslint-disable-next-line
                style={{
                  marginBottom: 10,
                }}
                inputProps={{
                  className: classes.TextInputStyle,
                }}
                InputProps={{
                  classes: {
                    notchedOutline: classes.outlinedStyle,
                  },
                }}
              />
            </DialogContent>
          </Grid>

          <Grid container style={{ marginBottom: "10%" }}>
            <Grid item xs={12} className={classes.buttonDiv}>
              {loading ? (
                <Button
                  className={classes.button1}
                  style={{
                    background: localStorage.getItem("color")
                      ? localStorage.getItem("color")
                      : defaultThemeColor,
                  }}
                >
                  <CircularProgress size={25} style={{ color: "#fff" }} />
                </Button>
              ) : (
                <Button
                  className={classes.button1}
                  onClick={onJoin}
                  style={{
                    background: localStorage.getItem("color")
                      ? localStorage.getItem("color")
                      : defaultThemeColor,
                  }}
                >
                  {intl.formatMessage({ id: "Join" })}
                </Button>
              )}
            </Grid>
            <Grid item xs={12} className={classes.buttonDiv}>
              <Button className={classes.cancelbutton} onClick={handleCancel}>
                {intl.formatMessage({ id: "Cancel" })}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default JoinTab;

const useStyles = makeStyles((theme) => ({
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF",
    },
  },
  outlinedStyle: {
    border: "0px",
  },
  formControl: {
    minWidth: "100%",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },
  topText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "10px 50px 0px 50px",
  },
  centerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#000000",
  },

  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    margin: "8px 0px",

    color: "#000000",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },

  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    color: "#777777 !important",
    background: "#FFFFFF",
    border: "1px solid #E3E3E3",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiFormHelperText-root.Mui-error": {
      color: "#f44336",
      marginTop: "-2px",
      textAlign: "center",
    },
    "& .MuiInputBase-root": {
      fontFamily: theme.fontFamily,
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      padding: "12px",
      display: "flex",
      alignItems: "center",
      color: "#777777",
      background: "#FFFFFF",
      border: "1px solid #E3E3E3",
      boxSizing: "border-box",
      borderRadius: "5px",
      maxHeight: "50px",
      height: "50px",
    },
    "& .MuiInputBase-input:focus": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiBadge-colorPrimary": {},

    "& .MuiInput-underline:before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline::after": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
      border: "1px solid #E3E3E3",
    },
    "& .MuiInputBase-input ": {
      padding: "0px",
    },
  },
  nullPadding: {
    "& .MuiAutocomplete-input": {
      padding: "1px !important",
    },
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineheight: "18px",
    textAlign: "center",
    color: "#000000",
  },

  button1: {
    width: " 100%",
    height: "100%",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },

  buttonDisabled: {
    width: " 100%",
    height: "100%",
    background: "#AEAEAE",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  // eslint-disable-next-line
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
