import React, { useState, useEffect, useLayoutEffect } from "react";
import OrderNavbar from "../Order/OrderNavbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { Grid, Chip } from "@material-ui/core";
import clsx from "clsx";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Divider from "@material-ui/core/Divider";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addItemOrder, updateItemOrder } from "../../actions/order";
import AddCartButton from "./AddCartButton";
import { CANCEL_SEARCH_OR_ADD_ITEM } from "../../constant/types";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      style={{ fontSize: "50px" }}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const makeInViewport = function (elemId) {
  let elem = document?.getElementById(elemId);

  elem &&
    elem.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
};
function AddCart(props) {
  const classes = useStyles();
  const intl = useIntl();

  const history = useHistory();
  let themeColor = useSelector((state) => state?.menuReducer?.themeColor);
  let dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState("");
  const [orderItemCount, setOrderItemCount] = useState(1);
  const [total, setTotal] = useState(0);
  let orderReducer = useSelector((state) => state.orderReducer);
  let searchReducer = useSelector((state) => state.searchReducer);
  const [options, setOptions] = useState({});
  const [tempTotalPrice, setTempTotalPrice] = useState(0);
  const [note, setNote] = useState("");
  const [shouldGoBack, setShouldGoBack] = useState(false);
  useEffect(() => {
    return () => {
      dispatch({ type: "SET_SELECTED_ORDER", payload: null });
    };
  }, [dispatch]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!orderReducer.loader && shouldGoBack) {
      if (searchReducer?.route?.last === "search") {
        dispatch({ type: CANCEL_SEARCH_OR_ADD_ITEM, payload: null });
        history.push("/menu");
      } else {
        history.goBack();
      }
      setShouldGoBack(false);
    }
    // eslint-disable-next-line
  }, [orderReducer?.loader, searchReducer?.route?.last, shouldGoBack]);
  useEffect(() => {
    if (
      (orderReducer && !orderReducer.selectedOrder) ||
      (orderReducer &&
        orderReducer.selectedOrder &&
        !orderReducer.selectedOrder.item_id)
    ) {
      history.goBack();
    } else {
      if (orderReducer.selectedOrder && orderReducer.selectedOrder.edit) {
        setOrderItemCount(orderReducer.selectedOrder.quantity);
        setOptions(orderReducer.selectedOrder.optionsObject);
        setNote(orderReducer.selectedOrder.note);
        setSelectedType(orderReducer.selectedOrder?.price_id);
        setTotal(orderReducer.selectedOrder.itemPrice);
      } else {
        let tempOptions = {};
        let tempTotal = 0;
        if (orderReducer.selectedOrder?.prices?.length === 1) {
          tempTotal = orderReducer.selectedOrder.prices[0]?.amount;
          setSelectedType(orderReducer.selectedOrder.prices[0]?.id);
        }
        // prices: Array(1)
        // 0: {id: 157, amount: "5.00",
        // setTotal(orderReducer.selectedOrder.price);
        orderReducer.selectedOrder.item_customisations &&
          orderReducer.selectedOrder.item_customisations.map((item) =>
            // eslint-disable-next-line
            item.customisation_options.map((option) => {
              if (option.pre_selected) {
                tempOptions = {
                  ...tempOptions,
                  [item.item_customisation_id]: tempOptions[
                    item.item_customisation_id
                  ]?.length
                    ? [
                        ...tempOptions[item.item_customisation_id],
                        option.customisation_option_id,
                      ]
                    : [option.customisation_option_id],
                };
                tempTotal = parseFloat(tempTotal) + parseFloat(option.price);
              }
            })
          );
        setTempTotalPrice(parseFloat(tempTotal));
        setTotal(tempTotal);
        setOptions(tempOptions);
      }
    }
    // eslint-disable-next-line 
  }, [orderReducer.selectedOrder]);

  const hasError = () => {
    let tempEmpty = false;
    if (selectedType === "") {
      tempEmpty = true;
    }
    orderReducer.selectedOrder &&
      orderReducer.selectedOrder.item_customisations &&
      orderReducer.selectedOrder.item_customisations.map((item) => {
        if (
          item?.availability?.out_of_stock === false &&
          item.required &&
          ((options && !options[item.item_customisation_id]) ||
            (options &&
              options[item.item_customisation_id] &&
              options[item.item_customisation_id].length === 0))
        ) {
          tempEmpty = true;
        }
        return null;
      });
    return tempEmpty;
  };
  const findRequiredIndex = () => {
    let tempEmpty = [];
    orderReducer.selectedOrder &&
      orderReducer.selectedOrder.item_customisations &&
      orderReducer.selectedOrder.item_customisations.map((item, index) => {
        if (
          item?.availability?.out_of_stock === false &&
          item.required &&
          ((options && !options[item.item_customisation_id]) ||
            (options &&
              options[item.item_customisation_id] &&
              options[item.item_customisation_id].length === 0))
        ) {
          tempEmpty.push(index);
        }
        return null;
      });
    return tempEmpty;
  };
  // id={`require${indexRequired}`}
  const handleSubmit = () => {
    if (hasError()) {
      const indexes = findRequiredIndex();
      indexes.filter.length > 0 && makeInViewport(`require${indexes[0]}`);
    } else {
      let tempOptions = [];
      console.log("options",options)
      console.log("updated",{
        order_uuid: orderReducer.orderId,
        price_id: selectedType,
        order_item_id: orderReducer.selectedOrder.order_item_id,
        options: tempOptions,
        quantity: orderItemCount,
        note: note,
        orderItem: {
          ...orderReducer.selectedOrder,
          quantity: orderItemCount,
          currency: orderReducer.selectedOrder.currency,
          options: tempOptions,
          optionsObject: options,
          note: note,
          itemPrice: total,
          itemOrderPrice: parseFloat(
            parseFloat(total) * parseInt(orderItemCount)
          ),
        },
        orderCount: orderItemCount - orderReducer.selectedOrder.quantity,
      } )
      if(typeof options ==="object"){      Object.keys(options).map((key) => {
        tempOptions = [...tempOptions, ...options[key]];
        return null;
      });
    }

      setShouldGoBack(true);
      if (orderReducer.selectedOrder.edit) {
        dispatch(
          updateItemOrder({
            order_uuid: orderReducer.orderId,
            price_id: selectedType,
            order_item_id: orderReducer.selectedOrder.order_item_id,
            options: tempOptions,
            quantity: orderItemCount,
            note: note,
            orderItem: {
              ...orderReducer.selectedOrder,
              quantity: orderItemCount,
              currency: orderReducer.selectedOrder.currency,
              options: tempOptions,
              optionsObject: options,
              note: note,
              itemPrice: total,
              itemOrderPrice: parseFloat(
                parseFloat(total) * parseInt(orderItemCount)
              ),
            },
            orderCount: orderItemCount - orderReducer.selectedOrder.quantity,
          })
        ).then(()=>{
          if (searchReducer?.route?.last === "search") {
            dispatch({ type: CANCEL_SEARCH_OR_ADD_ITEM, payload: null });
            history.push("/menu");
          } else {
            history.goBack();
          }
        })
      } else {
        dispatch(
          addItemOrder({
            order_uuid: orderReducer.orderId,
            price_id: selectedType,
            item_id: orderReducer.selectedOrder.item_id,
            options: tempOptions,
            quantity: orderItemCount,
            note: note,
            orderItem: {
              ...orderReducer.selectedOrder,
              quantity: orderItemCount,
              currency: orderReducer.selectedOrder.currency,
              options: tempOptions,
              optionsObject: options,
              note: note,
              itemPrice: total,
              itemOrderPrice: parseFloat(
                parseFloat(total) * parseInt(orderItemCount)
              ),
            },
            orderCount: orderItemCount,
          })
        )
      }
    }
  };

  return (
    <div>
      {orderReducer &&
        orderReducer.selectedOrder &&
        orderReducer.selectedOrder.item_id && (
          <>
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <OrderNavbar text={""} />
              </div>
              <div
                className={classes.header}
                style={{
                  marginTop: localStorage.tabId ? "6rem" : "4rem",
                }}
              >
                <p className={classes.name}>
                  {orderReducer.selectedOrder.name}{" "}
                </p>
                <p className={classes.description}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: orderReducer.selectedOrder.description,
                    }}
                  />
                  {/* {orderReducer.selectedOrder.description} */}
                </p>
              </div>
              {orderReducer?.selectedOrder?.prices?.length > 1 && (
                <div className={classes.girdlist}>
                  <div className={classes.itemheader}>
                    <p className={classes.name}>
                      {" "}
                      {intl.formatMessage({ id: "Choose_type" })}
                    </p>

                    <Chip
                      variant="default"
                      color="secondary"
                      size="small"
                      label={intl.formatMessage({ id: "Required" })}
                      style={{
                        color: "#FFFFFF",
                        backgroundColor: themeColor
                          ? themeColor
                          : defaultThemeColor,
                      }}
                    />
                  </div>
                  <FormControl
                    component="fieldset"
                    style={{ width: "99%", marginLeft: "-11px" }}
                  >
                    <RadioGroup
                      value={parseInt(selectedType)}
                      onChange={(e) => {
                        if (selectedType == e.target.value) {
                        } else {
                          let index1 =
                            orderReducer.selectedOrder &&
                            orderReducer?.selectedOrder?.prices &&
                            orderReducer?.selectedOrder?.prices.findIndex(
                              (n) => {
                                return n.id == selectedType;
                              }
                            );
                          let index =
                            orderReducer.selectedOrder &&
                            orderReducer?.selectedOrder?.prices &&
                            orderReducer?.selectedOrder?.prices.findIndex(
                              (n) => {
                                return n.id == e.target.value;
                              }
                            );
                          let totalPrice = total;
                          if (index1 > -1) {
                            totalPrice =
                              totalPrice -
                              parseFloat(
                                orderReducer?.selectedOrder?.prices[index1]
                                  .amount
                              );
                          }
                          if (index > -1) {
                            totalPrice =
                              totalPrice +
                              parseFloat(
                                orderReducer?.selectedOrder?.prices[index]
                                  .amount
                              );
                          }
                          setTotal(totalPrice);
                        }
                        setSelectedType(e.target.value);
                      }}
                      //   setOptions({
                      //     ...options,
                      //     [item.item_customisation_id]: [
                      //       parseInt(e.target.value),
                      //     ],
                      //   });
                      //   if (options[item.item_customisation_id]) {
                      //     const optionId =
                      //       options[item.item_customisation_id][0];
                      //     setTotal(
                      //       parseFloat(
                      //         item.customisation_options.filter(
                      //           (item) =>
                      //             item.customisation_option_id ===
                      //             parseInt(e.target.value)
                      //         )[0].price
                      //       ) +
                      //         parseFloat(total) -
                      //         parseFloat(
                      //           item.customisation_options.filter(
                      //             (item) =>
                      //               item.customisation_option_id === optionId
                      //           )[0].price
                      //         )
                      //     );
                      //   } else {
                      //     setTotal(
                      //       parseFloat(
                      //         item.customisation_options.filter(
                      //           (item) =>
                      //             item.customisation_option_id ===
                      //             parseInt(e.target.value)
                      //         )[0].price
                      //       ) + parseFloat(total)
                      //     );
                      //   }
                      // }}
                      style={{
                        paddingLeft: "25px",
                        fontSize: "50px",
                        width: "98%",
                      }}
                    >
                      <>
                        {orderReducer.selectedOrder &&
                          orderReducer?.selectedOrder?.prices &&
                          orderReducer?.selectedOrder?.prices
                            .sort((a, b) => a?.position - b?.position)
                            .map((option, indexRequired) => (
                              <>
                                <FormControlLabel
                                  classes={{
                                    label: classes.radiolabelstyle,
                                  }}
                                  value={option.id}
                                  control={<StyledRadio />}
                                  label={
                                    <Grid container justify="space-between">
                                      <Grid item xs={8}>
                                        <p className={classes.optionName}>
                                          {option.description}
                                        </p>
                                      </Grid>
                                      {option.amount &&
                                        option.amount !== "0.00" && (
                                          <Grid
                                            style={{
                                              textAlign: "right",
                                            }}
                                          >
                                            <p className={classes.optionPrice}>
                                              {/* + */}
                                              {parseFloat(
                                                option.amount
                                              ).toLocaleString("en-US", {
                                                style: "currency",
                                                currency:
                                                  orderReducer.selectedOrder
                                                    .currency,
                                              })}
                                            </p>
                                          </Grid>
                                        )}
                                    </Grid>
                                  }
                                />

                                <Divider style={{ marginRight: "16px" }} />
                              </>
                            ))}
                      </>
                    </RadioGroup>
                  </FormControl>
                </div>
              )}
              <div className={classes.girdlist}>
                {orderReducer.selectedOrder &&
                  orderReducer?.selectedOrder?.item_customisations &&
                  orderReducer?.selectedOrder?.item_customisations
                    .sort((a, b) => a?.position - b?.position)
                    .map(
                      (item, indexRequired) =>
                        item?.availability?.out_of_stock === false && (
                          <>
                            <div
                              className={classes.itemheader}
                              id={`require${indexRequired}`}
                            >
                              <p className={classes.name}> {item.name} </p>

                              {item.required && (
                                <Chip
                                  variant="default"
                                  color="secondary"
                                  size="small"
                                  label={intl.formatMessage({ id: "Required" })}
                                  style={{
                                    color: "#FFFFFF",
                                    backgroundColor: themeColor
                                      ? themeColor
                                      : defaultThemeColor,
                                  }}
                                />
                              )}
                              {item.max_selectable === 1 &&
                                item.required === false && (
                                  <p className={classes.description}>
                                    {intl.formatMessage({
                                      id: "Not_more_than_1",
                                    })}
                                  </p>
                                )}
                            </div>

                            {item.max_selectable === 1 &&
                            item.required === true ? (
                              <FormControl
                                component="fieldset"
                                style={{ width: "99%", marginLeft: "-11px" }}
                              >
                                <RadioGroup
                                  value={
                                    options &&
                                    options[item.item_customisation_id]
                                      ? options[item.item_customisation_id][0]
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setOptions({
                                      ...options,
                                      [item.item_customisation_id]: [
                                        parseInt(e.target.value),
                                      ],
                                    });
                                    if (options[item.item_customisation_id]) {
                                      const optionId =
                                        options[item.item_customisation_id][0];
                                      setTotal(
                                        parseFloat(
                                          item.customisation_options.filter(
                                            (item) =>
                                              item.customisation_option_id ===
                                              parseInt(e.target.value)
                                          )[0].price
                                        ) +
                                          parseFloat(total) -
                                          parseFloat(
                                            item.customisation_options.filter(
                                              (item) =>
                                                item.customisation_option_id ===
                                                optionId
                                            )[0].price
                                          )
                                      );
                                    } else {
                                      setTotal(
                                        parseFloat(
                                          item.customisation_options.filter(
                                            (item) =>
                                              item.customisation_option_id ===
                                              parseInt(e.target.value)
                                          )[0].price
                                        ) + parseFloat(total)
                                      );
                                    }
                                  }}
                                  style={{
                                    paddingLeft: "25px",
                                    fontSize: "50px",
                                    width: "98%",
                                  }}
                                >
                                  <>
                                    {item.customisation_options
                                      .sort((a, b) => a.position - b.position)
                                      .map(
                                        (option) =>
                                          option?.availability?.out_of_stock ===
                                            false && (
                                            <>
                                              <FormControlLabel
                                                classes={{
                                                  label:
                                                    classes.radiolabelstyle,
                                                }}
                                                value={
                                                  option.customisation_option_id
                                                }
                                                control={<StyledRadio />}
                                                label={
                                                  <Grid
                                                    container
                                                    justify="space-between"
                                                  >
                                                    <Grid item xs={9}>
                                                      <p
                                                        className={
                                                          classes.optionName
                                                        }
                                                      >
                                                        {option.name}
                                                      </p>
                                                    </Grid>
                                                    {option.price &&
                                                      option.price !==
                                                        "0.00" && (
                                                        <Grid
                                                          style={{
                                                            textAlign: "right",
                                                          }}
                                                        >
                                                          <p
                                                            className={
                                                              classes.optionPrice
                                                            }
                                                          >
                                                            +
                                                            {parseFloat(
                                                              option.price
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency:
                                                                  orderReducer
                                                                    .selectedOrder
                                                                    .currency,
                                                              }
                                                            )}
                                                          </p>
                                                        </Grid>
                                                      )}
                                                  </Grid>
                                                }
                                              />

                                              <Divider
                                                style={{ marginRight: "16px" }}
                                              />
                                            </>
                                          )
                                      )}
                                  </>
                                </RadioGroup>
                              </FormControl>
                            ) : (
                              <List
                                dense
                                component="div"
                                role="list"
                                style={{ paddingRight: "9px" }}
                              >
                                {item.customisation_options.map(
                                  (option, index) =>
                                    option?.availability?.out_of_stock ===
                                      false && (
                                      <>
                                        <ListItem
                                          key={index}
                                          role="listitem"
                                          button
                                          disabled={
                                            options &&
                                            options[
                                              item.item_customisation_id
                                            ] &&
                                            !options[
                                              item.item_customisation_id
                                            ].includes(
                                              option.customisation_option_id
                                            ) &&
                                            options[item.item_customisation_id]
                                              .length >= item.max_selectable
                                          }
                                          style={{ padding: "0" }}
                                          onClick={() => {
                                            if (
                                              options &&
                                              options[
                                                item.item_customisation_id
                                              ] &&
                                              options[
                                                item.item_customisation_id
                                              ].length
                                            ) {
                                              setOptions({
                                                ...options,
                                                [item.item_customisation_id]: options[
                                                  item.item_customisation_id
                                                ].includes(
                                                  option.customisation_option_id
                                                )
                                                  ? options[
                                                      item.item_customisation_id
                                                    ].filter(
                                                      (item) =>
                                                        item !==
                                                        option.customisation_option_id
                                                    )
                                                  : [
                                                      ...options[
                                                        item
                                                          .item_customisation_id
                                                      ],
                                                      option.customisation_option_id,
                                                    ],
                                              });
                                              options[
                                                item.item_customisation_id
                                              ].includes(
                                                option.customisation_option_id
                                              )
                                                ? setTotal(
                                                    parseFloat(total) -
                                                      parseFloat(option.price)
                                                  )
                                                : setTotal(
                                                    parseFloat(total) +
                                                      parseFloat(option.price)
                                                  );
                                            } else {
                                              setOptions({
                                                ...options,
                                                [item.item_customisation_id]: [
                                                  option.customisation_option_id,
                                                ],
                                              });
                                              setTotal(
                                                parseFloat(option.price) +
                                                  parseFloat(total)
                                              );
                                            }
                                          }}
                                        >
                                          <ListItemIcon
                                            style={{
                                              padding: "0",
                                              minWidth: "0px",
                                            }}
                                          >
                                            <Checkbox
                                              checked={
                                                options &&
                                                options[
                                                  item.item_customisation_id
                                                ]
                                                  ? options[
                                                      item.item_customisation_id
                                                    ].includes(
                                                      option.customisation_option_id
                                                    )
                                                  : false
                                              }
                                              style={{
                                                color:
                                                  options &&
                                                  options[
                                                    item?.item_customisation_id
                                                  ]?.includes(
                                                    option?.customisation_option_id
                                                  )
                                                    ? themeColor
                                                      ? themeColor
                                                      : defaultThemeColor
                                                    : "",
                                              }}
                                            />
                                          </ListItemIcon>
                                          <ListItemText>
                                            <Grid
                                              container
                                              justify="space-between"
                                            >
                                              <Grid item xs={9}>
                                                <p
                                                  className={classes.optionName}
                                                >
                                                  {option.name}
                                                </p>
                                              </Grid>
                                              {option.price &&
                                                option.price !== "0.00" && (
                                                  <Grid item>
                                                    <p
                                                      className={
                                                        classes.optionPrice
                                                      }
                                                    >
                                                      +{" "}
                                                      {parseFloat(
                                                        option.price
                                                      ).toLocaleString(
                                                        "en-US",
                                                        {
                                                          style: "currency",
                                                          currency:
                                                            orderReducer
                                                              .selectedOrder
                                                              .currency,
                                                        }
                                                      )}
                                                    </p>
                                                  </Grid>
                                                )}
                                            </Grid>
                                          </ListItemText>
                                        </ListItem>

                                        <Divider />
                                      </>
                                    )
                                )}
                              </List>
                            )}
                          </>
                        )
                    )}
                <div className={classes.itemheader}>
                  <p
                    className={classes.name}
                    style={{ paddingBottom: 10, paddingTop: 10 }}
                  >
                    {" "}
                    {intl.formatMessage({ id: "Note" })}{" "}
                  </p>
                </div>
                <TextField
                  variant="outlined"
                  placeholder={intl.formatMessage({ id: "Leave_a_note" })}
                  className={classes.fieldtext}
                  style={{
                    paddingBottom: 10,
                    paddingTop: 10,
                    paddingLeft: 12,
                    width: "95%",
                  }}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
                <div className={classes.footer}>
                  <Grid container>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <Fab
                        size="medium"
                        style={{ background: "#F4F4F4", boxShadow: "none" }}
                        onClick={() => setOrderItemCount(orderItemCount - 1)}
                        disabled={orderItemCount <= 1}
                      >
                        <RemoveIcon
                          variant="outline"
                          style={{ color: "#AEAEAE" }}
                        />
                      </Fab>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{ marginTop: "auto", marginBottom: "auto" }}
                    >
                      <p className={classes.count}>{orderItemCount}</p>
                    </Grid>
                    <Grid item xs={2}>
                      <Fab
                        size="medium"
                        style={{
                          background: "white",
                          boxSizing: "border-box",
                          boxShadow: "none",
                          border: "1px solid #E3E3E3",
                        }}
                        onClick={() => setOrderItemCount(orderItemCount + 1)}
                      >
                        <AddIcon variant="outline" />
                      </Fab>
                    </Grid>
                    {/* </div> */}
                    <Grid item xs={3}></Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={0}
                    style={{
                      paddingTop: "36px",
                      paddingLeft: "14px",
                      paddingRight: "7px",
                    }}
                  >
                    <Grid item xs={6}>
                      <Grid>
                        <p className={classes.totaltext}>
                          {intl.formatMessage({ id: "Total" })}
                        </p>
                      </Grid>
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "end" }}>
                      <p className={classes.pricetext}>
                        {parseFloat(
                          parseFloat(total) * parseInt(orderItemCount)
                        ).toLocaleString("en-US", {
                          style: "currency",
                          currency: orderReducer.selectedOrder.currency,
                        })}
                      </p>
                    </Grid>
                  </Grid>
                  <div className={classes.buttonDiv}></div>
                </div>
              </div>

              <AddCartButton
                // disable={orderReducer.loader || hasError()}
                orderClass={
                  orderReducer.loader || hasError()
                    ? classes.buttonDisabled
                    : classes.button
                }
                style={
                  orderReducer.loader || hasError()
                    ? { backgroundColor: "#AEAEAE !important" }
                    : {
                        backgroundColor: localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor,
                      }
                }
                handleSubmit={handleSubmit}
                loader={orderReducer?.loader}
                edit={orderReducer?.selectedOrder?.edit}
              />
            </div>
          </>
        )}
    </div>
  );
}

export default AddCart;
const useStyles = makeStyles((theme) => ({
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
      marginRight: "20px",
    },
  },
  checkedIcon: {
    backgroundColor: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : defaultThemeColor,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  description: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textAlign: "left",
    paddingBottom: "0rem",
    "& > div > p": {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "13px",
      lineHeight: "18px",
      color: "#777777",
      marginTop: "0rem",
      marginBottom: "0rem",
    },
    "& > div > h1": {
      fontFamily: "Open Sans",
      lineHeight: "32px",
      color: "#777777",
      marginTop: "0rem",
      marginBottom: "0rem",
    },
    "& > div > h2": {
      fontFamily: "Open Sans",
      color: "#777777",
      marginTop: "0rem",
      marginBottom: "0rem",
      lineHeight: "27px",
    },
  },
  name: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    display: "inline",
    marginRight: "0.5rem",
  },
  header: {
    marginLeft: "1.3rem",
    marginBottom: "0rem",
    marginRight: "2rem",
  },
  item: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    textAlign: "left",
  },
  girdlist: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  itemheader: {
    marginLeft: "0.8rem",
    marginTop: "2rem",
    marginBottom: "0.5rem",
  },
  addbutton: {
    backgroundColor: "white",
  },
  addremovebutton: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  count: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
    margin: 0,
  },
  button: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    paddingTop: "51px",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.5rem",
    bottom: 13,
  },
  totaltext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "start",
    color: "#000000",
    margin: "24px 0 0 0",
  },
  pricetext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
    margin: "24px 0 0 0",
    textAlign: "end",
  },
  footer: {
    marginTop: "60px",
  },
  radiolabelstyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    width: "100%",
    color: "#777777",
    margin: "0px !important",
  },
  optionName: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    margin: 0,
  },
  optionPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "18px",
    color: "#AEAEAE",
    margin: 0,
    paddingLeft: "15px",
  },
  test: {
    marginLeft: "0px",
  },
}));
