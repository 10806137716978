import React, { useEffect } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import PreviousOrderBar from "./PreviousOrderBar";
import { clearOldOrders } from "../../actions/order";
import { Typography } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  top: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
  },
  bottom: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    paddingBottom: "5px",
  },
  icon: {
    fontSize: "30px",
    cursor: "pointer",
  },
}));
function PreviousOrderList() {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearOldOrders());
    // eslint-disable-next-line
  }, []);
  const orderHistory = useSelector((state) => state.orderReducer.orderHistory);

  return (
    <div>
      <PreviousOrderBar
        text={intl.formatMessage({
          id: "My_Orders",
        })}
        center={true}
      />
      <Grid style={{ padding: "78px 15px 0px 15px " }}>
        {orderHistory &&
          orderHistory
            .slice(0)
            .reverse()
            .map((item) => (
              <>
                <Grid
                  container
                  style={{ paddingTop: "15px" }}
                  onClick={() =>
                    history.push({
                      pathname: "/order-detail",
                      state: { item: item },
                    })
                  }
                >
                  <Grid item xs={11}>
                    <Typography className={classes.top}>
                      {" "}
                      {intl.formatMessage({
                        id: "Order",
                      })}
                      {item.reference_id}{" "}
                      {item.status === 4 &&
                        `(${intl.formatMessage({
                          id: "Declined",
                        })})`}
                      {item.status === 3 &&
                        `(${intl.formatMessage({
                          id: "Accepted",
                        })})`}
                    </Typography>
                  </Grid>

                  <Grid item xs={1}>
                    <ChevronRightIcon
                      className={classes.icon}
                      // onClick={() => history.goBack()}
                    />
                  </Grid>
                </Grid>

                <Grid>
                  <Typography className={classes.bottom}>
                    {moment(item.submitted_for_review).format(
                      "D MMM, YYYY - HH:mm"
                    )}{" "}
                    |{" "}
                    {item.payment_method === "cash"
                      ? intl.formatMessage({
                          id: "Not_Paid",
                        })
                      : intl.formatMessage({
                          id: "Paid",
                        })}
                  </Typography>
                </Grid>

                <Divider />
              </>
            ))}
        {orderHistory.length < 1 && (
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "100px",
            }}
          >
            <Typography className={classes.top}>
              {intl.formatMessage({
                id: "No_Previous_Order",
              })}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}

export default PreviousOrderList;
