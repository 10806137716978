import React, { useState, useEffect } from "react";
import PreviousOrderBar from "../Order/PreviousOrderBar";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import AddToOrder from "./AddToOrder";
// import { buttonColor } from "../../constant/methods";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { reOrder } from "../../actions/order";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";
const useStyles = makeStyles((theme) => ({
  button2: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled2: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },

  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",

    color: "#000000",
  },
  ordercount: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",

    display: "inline",
    // marginRight: "5px",
  },
  header: {
    paddingTop: "40px",
    paddingLeft: "1.2rem",
    paddingRight: "1.2rem",
  },
  Gridlist: {
    marginTop: "3rem",
    marginLeft: "1.2rem",
    marginRight: "1.2rem",
  },
  list: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#777777",
    // marginLeft: "1rem",
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
  },
  listitem: {
    margin: 0,
  },
  totalorder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    margin: "0",
  },
  incl: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    margin: "0px",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    margin: "0",
  },
  totalPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
    margin: "0",
  },

  buttonDiv: {
    paddingTop: "51px",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "1.5rem",
    paddingRight: "1.6rem",
    bottom: 13,
  },
  cashcard: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",

    textAlign: "center",
  },
}));
function OrderDetails() {
  let location = useLocation();
  const dispatch = useDispatch();
  const intl = useIntl();

  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loader, setLoader] = useState(false);

  const classes = useStyles();
  const { menu } = useSelector((state) => state.menuReducer);
  const { orderId } = useSelector((state) => state.orderReducer);

  let orderReducer = location.state.item;
  useEffect(() => {
    setTotal(
      parseFloat(orderReducer && orderReducer.order_price) -
        parseFloat(orderReducer && orderReducer.tip_amount)
    );

    // eslint-disable-next-line
  }, [orderReducer]);
  const handleReorder = async () => {
    setLoader(true);
    let res = await dispatch(
      reOrder({
        orderItem: orderReducer?.order_items,
        prevOrderId: orderReducer?.order_uuid,
        orderId: orderId,
      })
    );
    if (res === 200) {
      history.push("/menu");
    }
    setLoader(false);
  };
  return (
    <div>
      <PreviousOrderBar
        text={moment(orderReducer.submitted_for_review).format(
          "D MMM, YYYY - HH:mm"
        )}
        center={true}
      />
      <div className={classes.header}></div>
      <div className={classes.Gridlist}>
        {orderReducer &&
        orderReducer.order_items &&
        orderReducer.order_items.length > 0 ? (
          orderReducer.order_items.map((item, index) => (
            <div key={item.item_ref}>
              <div className={"detailHeader"}>
                <Grid container justify="space-between">
                  <Grid item style={{ marginTop: "3px" }}>
                    <Typography
                      className={classes.ordercount}
                      style={{
                        color: localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor,
                      }}
                    >
                      x{item.quantity}
                    </Typography>
                  </Grid>

                  <Grid item xs={8} style={{ marginTop: "3px" }}>
                    <Typography
                      className={classes.GenralText}
                      style={{ display: "inline" }}
                    >
                      {item.name}
                    </Typography>
                    <div className={classes.list}>
                      {item.order_item_options.map((item_option) => (
                        <p style={{ margin: "0.3rem 0rem" }}>
                          {item_option.name}({" "}
                          {parseFloat(
                            parseFloat(item_option.price * item.quantity)
                          ).toLocaleString("en-US", {
                            style: "currency",
                            currency: menu ? menu.currency : "usd",
                          })}
                          )
                        </p>
                      ))}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={3}
                    style={{ marginTop: "3px", textAlign: "end" }}
                  >
                    {" "}
                    <Typography
                      className={classes.GenralText}
                      style={{ display: "inline", marginTop: "3px" }}
                    >
                      {parseFloat(
                        parseFloat(item.price * item.quantity)
                      ).toLocaleString("en-US", {
                        style: "currency",
                        currency: menu ? menu.currency : "usd",
                      })}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
              {item && item.note && (
                <TextField
                  inputProps={{ style: { backgroundColor: "#F2F2F2" } }}
                  disabled
                  variant="outlined"
                  value={item.note}
                  className={classes.fieldtext}
                  style={{ paddingBottom: 20, color: "#F2F2F2" }}
                  fullWidth
                />
              )}
              <Divider />{" "}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      {orderReducer && orderReducer.note && (
        <div className={classes.header}>
          <p
            className={classes.GenralText}
            style={{ margin: "0px 0px 10px 0px" }}
          >
            {intl.formatMessage({
              id: "Order_Note",
            })}
          </p>
          <TextField
            variant="outlined"
            placeholder=""
            className={classes.fieldtext}
            disabled
            // style={{ paddingBottom: 10 }}
            value={orderReducer.note}
            fullWidth
          />
        </div>
      )}
      <Grid style={{ margin: "52px 20px 16px 18px " }}>
        <Grid container spacing={0} style={{ padding: "16px 0px " }}>
          <Grid item xs={6}>
            <Grid>
              <p className={classes.totalorder}>
                {intl.formatMessage({
                  id: "Subtotal",
                })}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <p className={classes.price}>
              {parseFloat(parseFloat(total)).toLocaleString("en-US", {
                style: "currency",
                currency: menu ? menu.currency : "usd",
              })}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ padding: "16px 0px " }}>
          <Grid item xs={6}>
            <Grid>
              <p className={classes.totalorder}>
                {intl.formatMessage({
                  id: "Tip",
                })}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <p className={classes.price}>
              {parseFloat(
                parseFloat(
                  orderReducer && orderReducer.tip_amount
                    ? orderReducer && orderReducer.tip_amount
                    : 0
                )
              ).toLocaleString("en-US", {
                style: "currency",
                currency: menu ? menu.currency : "usd",
              })}
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={0} style={{ padding: "16px 0px" }}>
          <Grid item xs={6}>
            <Grid>
              <p className={classes.totalorder}>
                {intl.formatMessage({
                  id: "Total",
                })}
              </p>
            </Grid>
            <Grid>
              <p className={classes.incl}>
                (
                {intl.formatMessage({
                  id: "incl_VAT",
                })}
                )
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <p className={classes.totalPrice}>
              {parseFloat(
                parseFloat(orderReducer && orderReducer.order_price)
              ).toLocaleString("en-US", {
                style: "currency",
                currency: menu ? menu.currency : "usd",
              })}{" "}
            </p>
          </Grid>
        </Grid>
      </Grid>
      <AddToOrder
        disable={loader}
        handleReorder={handleReorder}
        orderClass={classes.button2}
        loader={loader}
      />
    </div>
  );
}

export default OrderDetails;
