//order
export const CREATE_ORDER = "CREATE_ORDER";
export const ADD_ORDER_COUNT = "ADD_ORDER_COUNT";
export const REDUCE_ORDER_COUNT = "REDUCE_ORDER_COUNT";
export const ADD_ORDER_ITEM = "ADD_ORDER_ITEM";
export const UPDATE_ORDER_ITEM = "UPDATE_ORDER_ITEM";
export const REMOVE_ORDER_ITEM = "REMOVE_ORDER_ITEM";
export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const SET_SELECTED_ORDER = "SET_SELECTED_ORDER";
export const SET_UPDATED = "SET_UPDATED";
export const SET_LOADER = "SET_LOADER";
export const SET_PAYMENT_INTENT = "SET_PAYMENT_INTENT";
export const SET_MENU_ID = "SET_MENU_ID";
export const ORDER_STATUS = "ORDER_STATUS";
export const ORDER_HISTORY = "ORDER_HISTORY";
export const SELECTED_MENU = "SELECTED_MENU";
export const TABLE_LIST = "TABLE_LIST";
export const REORDER_LIST = "REORDER_LIST";
export const CLEAR_OLD_ORDER = "CLEAR_OLD_ORDER";
export const UPDATE_ORDER_COUNT = "UPDATE_ORDER_COUNT";
//search

export const SET_FORMATTED_MENU_LOCAL = "SET_FORMATTED_MENU_LOCAL";
export const REMOVE_FORMATTED_MENU_LOCAL = "REMOVE_FORMATTED_MENU_LOCAL";
export const SET_SEARCHED_ITEMS = "SET_SEARCHED_ITEMS";
export const REMOVE_SEARCHED_ITEMS = "REMOVE_SEARCHED_ITEMS";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const REMOVE_SEARCH_TEXT = "REMOVE_SEARCH_TEXT";
export const SET_ROUTE = "SET_ROUTE";
export const SET_CURRENT_SELECTED_ITEM = "SET_CURRENT_SELECTED_ITEM";
export const CANCEL_SEARCH_OR_ADD_ITEM = "CANCEL_SEARCH_OR_ADD_ITEM";

//menus

export const SET_RESTAURANT_MENU = "SET_RESTAURANT_MENU";
export const SET_RESTAURANT_DETAILS = "SET_RESTAURANT_DETAILS";

export const GET_DETAIL = "GET_DETAIL";
export const ADD_ITEM = "ADD_ITEM";

//tab
export const ORDER_EXPIRE = "ORDER_EXPIRE";
export const TAB_EXPIRE = "TAB_EXPIRE";

export const JOIN_TAB = "JOIN_TAB";
export const OPEN_TAB = "OPEN_TAB";
export const TAB_DETAIL = "TAB_DETAIL";
export const ALL_TABLE = "ALL_TABLE";

//hotel
export const  SET_MENU_REQUEST_LOADING="SET_MENU_REQUEST_LOADING";
export const GET_HOTEL_DETAILS = "GET_HOTEL_DETAILS";
//email
export const SET_EMAIL = "SET_EMAIL";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
