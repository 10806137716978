import React, { useEffect, useState } from "react";
import * as JsSearch from "js-search";
import TabExpireDialog from "../components/Tab/TabExpireDialog";
import axios from "axios";
import {
  Grid,
  Chip,
  Card,
  CardContent,
  Typography,
  Divider,
  CircularProgress,
} from "@material-ui/core";
import { createOrder } from "../actions/order";
import { useHistory, useLocation } from "react-router-dom";
import { defaultThemeColor } from "../constant/colors";
import DetailCard from "../components/DetailCard";
import Truncate from "react-truncate";
import TopGridSearchMenu from "../components/TopGridSearchMenu";
import "../style/home.css";
import "../style/search.css";
import API from "../utils/API";
import SmartImage from "../components/SmartImage";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetail, updateTabExpire } from "../actions/order";
import { useRef } from "react";
import { useTheme } from "@material-ui/styles";
import truncateHtml from "truncate-html";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../utils/constants";
// eslint-disable-next-line
import {
  SET_FORMATTED_MENU_LOCAL,
  SET_SEARCHED_ITEMS,
  REMOVE_SEARCHED_ITEMS,
  SET_SEARCH_TEXT,
  SET_CURRENT_SELECTED_ITEM,
} from "../constant/types";
import { useIntl } from "react-intl";
import CardFooter from "../components/Footer/CardFooter";
import { getVenueDetails, getMenusList } from "../actions/Menu";
import TruncateMarkup from "react-truncate-markup";

const SearchContainer = (props) => {
  const intl = useIntl();

  let theme = useTheme();
  useEffect(() => {
    // eslint-disable-next-line
  }, []);
  let location = useLocation();
  let dispatch = useDispatch();
  const history = useHistory();

  let buttonColor = useSelector((state) => state?.menuReducer?.buttonColor);
  const [selectedMenu, setSelectedMenu] = useState(null);
  let orderReducer = useSelector((state) => state.orderReducer);
  let menuReducer = useSelector((state) => state.menuReducer);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const [tabExpire, setTabExpire] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loader, setLoader] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [orderCount, setOrderCount] = useState(0);
  const [viewOnlyMenu, setViewOnlyMenu] = useState(false);
  useEffect(() => {
    let type = new URLSearchParams(location.search).get("type");
    if (type === "view") {
      setViewOnlyMenu(true);
    }
  }, [location.search]);

  const [searchText, setSearchtext] = useState("");
  let searchReducer = useSelector((state) => state.searchReducer);

  const handleChangeSearchText = (e) => {
    setSearchtext(e.target.value);
  };

  const handleSearch = () => {
    dispatch({ type: SET_SEARCH_TEXT, payload: searchText });
    Js_Search(searchReducer?.formattedMenu, searchText);
  };

  const getCount = (orderReducer, dish) => {
    let totalCount = 0;
    let count = orderReducer.orderList.filter(
      (item) => item.item_id === dish.item_id
    );
    // eslint-disable-next-line
    count.map((item) => {
      totalCount++;
      if (item.quantity > 1) {
        totalCount = totalCount + item.quantity - 1;
      }
    });
    return totalCount;
  };

  useEffect(() => {
    menuReducer &&
      menuReducer.menu &&
      menuReducer.menu.menus &&
      menuReducer.menu.menus.map(
        (menu) =>
          menuReducer.selectedMenu === menu.id &&
          setSelectedSubMenu(menu.menu_categories)
      );
    // eslint-disable-next-line
  }, [menuReducer, menuReducer?.selectedMenu]);

  useEffect(() => {
    if (!menuReducer.menu) {
      setLoader(true);

      if (
        new URLSearchParams(location.search).get("tab") ||
        localStorage.tabId
      ) {
        orderReducer?.orderId &&
          dispatch(
            createOrder({
              venue_id: localStorage.menuId,
              tab_id: new URLSearchParams(location.search).get("tab")
                ? new URLSearchParams(location.search).get("tab")
                : localStorage.tabId,
              tab_link: new URLSearchParams(location.search).get("tab")
                ? window.location.protocol + "//" + window.location.host
                : localStorage.tab_link,
            })
          ).then((res) => {
            if (res === 403) {
              dispatch(
                updateTabExpire({
                  value: true,
                })
              );
              setTabExpire(true);
            } else if (res === 200) {
            } else {
              localStorage.setItem("tabId", "empty");
              localStorage.removeItem("orderId");
              localStorage.setItem("tabLink", "empty");

              dispatch(
                createOrder({
                  venue_id: menuReducer.menu?.uuid,
                  tab_id: "empty",
                  tab_link: "empty",
                })
              );

              history.push("/");
            }
          });
      }
      const url =
        (process.env.NODE_ENV === "development"
          ? "https://busaba.staging.blend.menu"
          : window.location.protocol + "//" + window.location.host) + "/d/";
      dispatch(getVenueDetails(url))
        .then((result) => {
          dispatch(getMenusList());

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);

          console.log(err);
        });
      // axios
      //   .get(
      //     (process.env.NODE_ENV === "development"
      //       ? "https://busaba.staging.blend.menu"
      //       : window.location.protocol + "//" + window.location.host) + "/d/",

      //     {
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //     }
      //   )

      //   .then((result) => {
      //     // setDataMenu(result.data.id ? result.data : "");
      //     localStorage.setItem(
      //       "menuId",
      //       result.data.uuid ? result.data.uuid : ""
      //     );
      //     dispatch({
      //       type: "SET_RESTAURANT_MENU",
      //       payload: result.data.id ? result.data : "",
      //       //
      //       //  payload: menuData
      //     });
      //     // setDataMenu(menuData);

      //     dispatch({
      //       type: "SELECTED_MENU",
      //       payload: result.data.menus[0]?.id,
      //     });

      //     setLoader(false);
      //   })
      //   .catch((err) => {
      //     setLoader(false);

      //     console.log(err);
      //   });
    } else {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu, orderReducer?.orderId]);
  useEffect(() => {
    if (!orderReducer.orderId && menuReducer.menu?.uuid) {
      if (
        new URLSearchParams(location.search).get("tab") ||
        localStorage.tabId
      ) {
        dispatch(
          createOrder({
            venue_id: menuReducer.menu?.uuid,
            tab_id: new URLSearchParams(location.search).get("tab")
              ? new URLSearchParams(location.search).get("tab")
              : localStorage.tabId,
            tab_link: new URLSearchParams(location.search).get("tab")
              ? window.location.protocol + "//" + window.location.host
              : localStorage.tab_link,
          })
        ).then((res) => {
          if (res === 403) {
            dispatch(
              updateTabExpire({
                value: true,
              })
            );
            setTabExpire(true);
          } else if (res === 200) {
          } else {
            localStorage.setItem("tabId", "empty");
            localStorage.setItem("tabLink", "empty");

            localStorage.removeItem("orderId");
            dispatch(createOrder({ venue_id: menuReducer.menu?.uuid }));

            history.push("/");
          }
        });
      } else {
        dispatch(
          createOrder({
            venue_id: menuReducer.menu?.uuid,
            tab_id: "empty",
            tab_link: "empty",
          })
        );
      }
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderId, menuReducer?.menu]);

  useEffect(() => {
    if (orderReducer.orderId) {
      dispatch(getOrderDetail({ order_uuid: orderReducer.orderId }));
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderId]);

  useEffect(() => {
    const functionCall = async () => {
      try {
        const response = await API.get(
          `/api/order/${orderReducer.orderDetail.order_uuid}/status/`
        );
        if (response && response.data && response.data.status === 2) {
          history.push({
            pathname: "/order",
          });

          return;
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (orderReducer?.orderDetail?.order_uuid) {
      functionCall();
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderDetail?.order_uuid]);

  const Js_Search = (menuList, searchText) => {
    if (menuList) {
      setLoader(true);
      let search = new JsSearch.Search("item_id");
      search.addIndex("name1");
      search.addIndex(["tag"]);
      search.addIndex("menus");
      search.addIndex("menu_categories");
      search.addDocuments(menuList);

      const searchedList = search.search(searchText);

      let arr = [];
      // eslint-disable-next-line
      searchedList.map((item) => {
        let newItem = { ...item, name: item.name1 };
        delete newItem.menus;
        delete newItem.menu_categories;
        delete newItem.menus_details;
        delete newItem.menu_categories_details;
        delete newItem.name1;
        let index = arr.findIndex(
          (menuItem) => menuItem.id === item.menus_details.id
        );
        if (index > -1) {
          let menu = arr[index];
          let index2 = menu.menu_categories.findIndex(
            (categoryItem) =>
              categoryItem.id === item.menu_categories_details.id
          );
          if (index2 > -1) {
            let category = menu.menu_categories[index2];
            category.items.push(newItem);
            menu.menu_categories[index2] = category;
            arr[index] = menu;
          } else {
            const objCategory = {
              ...item.menu_categories_details,
            };
            objCategory.items = [{ ...newItem }];
            menu.menu_categories.push({ ...objCategory });
            arr[index] = menu;
          }
        } else {
          const objCategory = {
            ...item.menu_categories_details,
          };
          objCategory.items = [{ ...newItem }];
          const obj = {
            ...item.menus_details,
            menu_categories: [{ ...objCategory }],
          };
          arr.push(obj);
        }
      });
      setSelectedMenu(arr);
      dispatch({ type: SET_SEARCHED_ITEMS, payload: arr });
      setLoader(false);
    }
  };

  const titleRef = useRef();
  const [goTop, setGoTop] = useState(false);

  const executeScroll = () => {
    setGoTop(true);
  };
  useEffect(() => {
    if (selectedSubMenu && goTop) {
      // eslint-disable-next-line
      const element = document.getElementById("top-bar");
      // window.scrollTo(0, element.offsetHeight + 50);
    }
    // eslint-disable-next-line
  }, [goTop, selectedSubMenu]);

  //formated data for search
  useEffect(() => {
    if (menuReducer?.menu?.menus) {
      let arr = [];
      // eslint-disable-next-line
      menuReducer.menu.menus.map((menu_item, index) => {
        let obj = {
          menus: menu_item.name,
          menus_details: { ...menu_item },
        };
        delete obj.menus_details.menu_categories;
        // eslint-disable-next-line
        menu_item.menu_categories.map((category_item, index2) => {
          let obj1 = {
            ...obj,
            menu_categories: category_item.name,
            menu_categories_details: { ...category_item },
          };
          delete obj1.menu_categories_details.items;
          // eslint-disable-next-line
          category_item.items.map((single_item, index3) => {
            let tag = [];
            // eslint-disable-next-line
            single_item.tags.map((item) => {
              tag.push(item.name);
            });
            let obj2 = {
              ...obj1,
              tag: [...tag],
              name1: single_item.name,
              ...single_item,
            };
            delete obj2.name;
            arr.push(obj2);
          });
        });
      });
      dispatch({ type: SET_FORMATTED_MENU_LOCAL, payload: arr });
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu?.menus]);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    searchReducer?.searchText && setSearchtext(searchReducer?.searchText);
    searchReducer?.searchedItems &&
      setSelectedMenu(searchReducer?.searchedItems);
    searchReducer?.currentSelectedItem &&
      setSelectedCard(searchReducer?.currentSelectedItem);
    setShowDetails(true);
    setLoaded(true);
    // eslint-disable-next-line
  }, [
    searchReducer?.currentSelectedItem,
    searchReducer?.searchText,
    searchReducer?.searchedItems,
  ]);
  useEffect(() => {
    dispatch({ type: SET_CURRENT_SELECTED_ITEM, payload: selectedCard });
    // eslint-disable-next-line
  }, [selectedCard]);

  useEffect(() => {
    if (
      (searchText === "" || searchText === null) &&
      (searchReducer?.route?.last === "search" ||
        searchReducer?.route === null) &&
      loaded
    ) {
      dispatch({ type: REMOVE_SEARCHED_ITEMS, payload: null });
      setSelectedMenu(null);
    }
    // eslint-disable-next-line
  }, [loaded, searchReducer.route, searchText]);
  useEffect(() => {
    if (
      (searchText === "" || searchText === null) &&
      searchReducer?.route?.last === "menu"
    ) {
      dispatch({ type: REMOVE_SEARCHED_ITEMS, payload: null });
      setSelectedMenu(null);
    }
    // eslint-disable-next-line
  }, [searchReducer?.route?.last, searchText]);

  return (
    <div>
      <>
        <Grid>
          <Grid item>
            <Grid
              ref={titleRef}
              id="hh"
              className={"menuBar"}
              style={
                localStorage.tabId &&
                !menuReducer?.menu?.read_only_menu &&
                !viewOnlyMenu &&
                menuReducer?.menu?.accept_orders_without_tab !==
                  ACCEPT_ORDERS_WITHOUT_TAB &&
                menuReducer?.menu?.read_only_menu !== undefined
                  ? { minHeight: "115px" }
                  : { minHeight: "75px" }
              }
            >
              <TopGridSearchMenu
                executeScroll={executeScroll}
                handleChangeSearchText={handleChangeSearchText}
                handleSearch={handleSearch}
                setSelectedCard={setSelectedCard}
                setShowDetails={setShowDetails}
                mainPage={true}
                searchText={searchText}
              />
            </Grid>

            {selectedMenu ? (
              <Grid container>
                <Grid item xs={12} className="cardListBody">
                  <Grid className="cardListMainGrid">
                    {selectedMenu &&
                      selectedMenu.map((menuItem) => {
                        return (
                          menuItem &&
                          menuItem.menu_categories.map((menu) => (
                            <Grid key={menu.id} id={menu?.name}>
                              <Typography
                                className="menu"
                                variant="h4"
                                component="h1"
                                style={{
                                  paddingLeft: "1rem",
                                }}
                              >
                                {`${menuItem.name}/${menu.name}`}
                              </Typography>

                              {menu.items &&
                                menu.items.length &&
                                menu.items.map((dish, index) => {
                                  const dishTags =
                                    dish.tags && dish.tags.length
                                      ? dish.tags.filter(
                                          (tag) =>
                                            tag.name && tag.name === "Popular"
                                        )
                                      : [];
                                  return (
                                    <div key={index}>
                                      {showDetails &&
                                      selectedCard ===
                                        dish.item_id +
                                          `_${menuReducer.menu.name}` ? (
                                        <DetailCard
                                          key={dish.item_id}
                                          dishImage={
                                            menuReducer.menu.cover_image
                                          }
                                          dish={dish}
                                          setSelectedCard={setSelectedCard}
                                          setShowDetails={setShowDetails}
                                          currency={menuReducer.menu.currency}
                                          orderCount={orderCount}
                                          setOrderCount={setOrderCount}
                                          readOnly={
                                            menuReducer.menu.read_only_menu ||
                                            viewOnlyMenu
                                              ? true
                                              : false
                                          }
                                          is_currently_accepting_orders={
                                            menuReducer?.menu
                                              ?.is_currently_accepting_orders &&
                                            !viewOnlyMenu
                                          }
                                        />
                                      ) : (
                                        <Card
                                          key={dish.item_id}
                                          className="dishCard"
                                          style={{
                                            paddingLeft: "1rem",
                                            borderLeft:
                                              orderReducer.orderList &&
                                              orderReducer.orderList.length >
                                                0 &&
                                              orderReducer.orderList.filter(
                                                (item) =>
                                                  item.item_id === dish.item_id
                                              ).length > 0
                                                ? `solid ${
                                                    localStorage.getItem(
                                                      "color"
                                                    )
                                                      ? localStorage.getItem(
                                                          "color"
                                                        )
                                                      : defaultThemeColor
                                                  } 6px`
                                                : "unset",
                                            borderRadius: "0px",
                                          }}
                                          onClick={() => {
                                            setSelectedCard(
                                              dish.item_id +
                                                `_${menuReducer.menu.name}`
                                            );

                                            setShowDetails(true);
                                          }}
                                        >
                                          {dish.image ? (
                                            <Grid
                                              container
                                              justify="space-between"
                                              spacing={3}
                                            >
                                              <Grid
                                                item
                                                xs={7}
                                                lg={8}
                                                style={{
                                                  paddingTop: "0px",
                                                  marginTop: "12px",
                                                }}
                                              >
                                                <CardContent className="dishCardContentWithImage">
                                                  <Grid container>
                                                    <Grid item></Grid>
                                                    <Grid item>
                                                      <h1
                                                        className="dishName"
                                                        style={{
                                                          textDecoration:
                                                            dish?.availability
                                                              ?.out_of_stock ===
                                                            true
                                                              ? "line-through"
                                                              : "",
                                                        }}
                                                      >
                                                        {orderReducer.orderList &&
                                                          orderReducer.orderList
                                                            .length > 0 &&
                                                          orderReducer.orderList.filter(
                                                            (item) =>
                                                              item.item_id ===
                                                              dish.item_id
                                                          ).length > 0 && (
                                                            <Typography
                                                              style={{
                                                                fontFamily:
                                                                  theme?.fontFamily,
                                                                fontStyle:
                                                                  "normal",
                                                                fontWeight:
                                                                  "normal",
                                                                fontSize:
                                                                  "13px",
                                                                lineHeight:
                                                                  "18px",
                                                                color:
                                                                  localStorage.getItem(
                                                                    "color"
                                                                  )
                                                                    ? localStorage.getItem(
                                                                        "color"
                                                                      )
                                                                    : defaultThemeColor,
                                                                display:
                                                                  "inline",
                                                                marginRight:
                                                                  "5px",
                                                              }}
                                                            >
                                                              x
                                                              {getCount(
                                                                orderReducer,
                                                                dish
                                                              )}
                                                            </Typography>
                                                          )}{" "}
                                                        {dish.name}
                                                      </h1>
                                                    </Grid>
                                                  </Grid>

                                                  <div
                                                    className="dishDescription truncate-overflow"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.description && (
                                                      <TruncateMarkup lines={2} ellipsis=" ...">
                                                       
                                                        <div>
                                                          {dish.description
                                                            ?.replace(/\&nbsp;/g, ' ').replace(
                                                              /(<([^>]+)>)/gi,
                                                              " "
                                                            ).replace(
                                                              /\&amp;/g,
                                                              "&"
                                                            )
                                                            .replace(
                                                              "nbsp;",
                                                              " "
                                                            )}
                                                        </div>
                                                      </TruncateMarkup>
                                                    )}
                                                  </div>
                                                  <Grid
                                                    container
                                                    justify="flex-start"
                                                    className="priceGrid"
                                                  >
                                                    <CardFooter
                                                      currency={
                                                        menuReducer?.menu
                                                          ?.currency
                                                      }
                                                      dish={dish}
                                                    />
                                                    {/* <Grid
                                                      item
                                                      style={{
                                                        marginTop: "1rem",
                                                      }}
                                                    >
                                                      <Typography
                                                        className="dishPrice"
                                                        variant="body2"
                                                        component="p"
                                                      >
                                                        {parseFloat(
                                                          dish.price
                                                        ) === parseFloat("0")
                                                          ? ""
                                                          : parseFloat(
                                                              dish.price
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency:
                                                                  menuReducer
                                                                    .menu
                                                                    .currency,
                                                              }
                                                            )}
                                                      </Typography>
                                                    </Grid> */}
                                                    <Grid
                                                      item
                                                      style={{
                                                        paddingTop: "0px",
                                                        marginTop: "1rem",
                                                      }}
                                                    >
                                                      {dishTags.length ? (
                                                        <>
                                                          {dishTags[0].image ? (
                                                            <Chip
                                                              icon={
                                                                <img
                                                                  src={
                                                                    dishTags[0]
                                                                      .image
                                                                  }
                                                                  alt={
                                                                    dishTags[0]
                                                                      .name
                                                                  }
                                                                />
                                                              }
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "#000",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#000",
                                                                marginRight:
                                                                  "0.5rem",
                                                                marginLeft:
                                                                  "0.25rem",
                                                              }}
                                                            />
                                                          ) : (
                                                            <Chip
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "#000",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#000",
                                                                marginRight:
                                                                  "0.5rem",
                                                                marginLeft:
                                                                  "0.25rem",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      ) : (
                                                        <> </>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Grid>
                                              <Grid
                                                item
                                                xs={5}
                                                md={5}
                                                lg={3}
                                                className="CardImageGrid"
                                                style={{
                                                  paddingTop: "0px",
                                                  marginTop: "14px",
                                                }}
                                              >
                                                <SmartImage
                                                  url={dish.image}
                                                  title={dish.name}
                                                  styleClass="dishMediaImage"
                                                  width={"100%"}
                                                  height={100}
                                                  type={"rect"}
                                                />
                                              </Grid>
                                            </Grid>
                                          ) : (
                                            <Grid
                                              container
                                              justify="space-between"
                                              spacing={3}
                                            >
                                              <Grid item xs={12}>
                                                <CardContent className="dishCardContent">
                                                  <h1
                                                    className="dishName"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.name}
                                                  </h1>
                                                  <div
                                                    className="dishDescription truncate-overflow"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    {dish.description &&
                                                    
                                                    <TruncateMarkup lines={2}
                                                    ellipsis=" ..."
                                                    >
                                                      <div>
                                                        {dish.description
                                                          ?.replace(/\&nbsp;/g, ' ').replace(
                                                            /(<([^>]+)>)/gi,
                                                            " "
                                                          ).replace(
                                                            /\&amp;/g,
                                                            "&"
                                                          )
                                                          .replace(
                                                            "nbsp;",
                                                            " "
                                                          )}
                                                      </div>
                                                      </TruncateMarkup>

                                                    }
                                                  </div>
                                                  {/* <Truncate
                                                    lines={2}
                                                    ellipsis={"..."}
                                                    className="dishDescription"
                                                    style={{
                                                      textDecoration:
                                                        dish?.availability
                                                          ?.out_of_stock ===
                                                        true
                                                          ? "line-through"
                                                          : "",
                                                    }}
                                                  >
                                                    <div
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          dish.description,
                                                      }}
                                                    />
                                                  </Truncate> */}

                                                  <Grid
                                                    container
                                                    justify="flex-start"
                                                    spacing={1}
                                                    style={{
                                                      marginTop: "0rem",
                                                    }}
                                                    className="priceGrid"
                                                  >
                                                    <CardFooter
                                                      currency={
                                                        menuReducer?.menu
                                                          ?.currency
                                                      }
                                                      dish={dish}
                                                    />
                                                    {/* <Grid item>
                                                      <Typography
                                                        className="dishPrice"
                                                        variant="body2"
                                                        component="p"
                                                      >
                                                        {parseFloat(
                                                          dish.price
                                                        ) === parseFloat("0")
                                                          ? ""
                                                          : parseFloat(
                                                              dish.price
                                                            ).toLocaleString(
                                                              "en-US",
                                                              {
                                                                style:
                                                                  "currency",
                                                                currency:
                                                                  menuReducer
                                                                    .menu
                                                                    .currency,
                                                              }
                                                            )}
                                                      </Typography>
                                                    </Grid> */}
                                                    <Grid
                                                      item
                                                      style={{
                                                        paddingTop: "0px",
                                                        marginBottom: "0.5rem",
                                                      }}
                                                    >
                                                      {dishTags.length ? (
                                                        <>
                                                          {dishTags[0].image ? (
                                                            <Chip
                                                              icon={
                                                                <img
                                                                  src={
                                                                    dishTags[0]
                                                                      .image
                                                                  }
                                                                  alt={
                                                                    dishTags[0]
                                                                      .name
                                                                  }
                                                                />
                                                              }
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "black",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#fff",
                                                              }}
                                                            />
                                                          ) : (
                                                            <Chip
                                                              variant="default"
                                                              size="small"
                                                              label={
                                                                dishTags[0].name
                                                              }
                                                              style={{
                                                                backgroundColor:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .bg_hex
                                                                    : "black",
                                                                color:
                                                                  dishTags[0]
                                                                    .colour
                                                                    ? dishTags[0]
                                                                        .colour
                                                                        .text_hex
                                                                    : "#fff",
                                                              }}
                                                            />
                                                          )}
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </CardContent>
                                              </Grid>
                                            </Grid>
                                          )}
                                          {/* <Divider className="divider" light /> */}
                                        </Card>
                                      )}
                                      <Grid
                                        container
                                        style={{ paddingLeft: "1rem" }}
                                      >
                                        <Divider className="divider" light />
                                      </Grid>
                                    </div>
                                  );
                                })}
                            </Grid>
                          ))
                        );
                      })}
                  </Grid>
                  {selectedMenu?.length === 0 && (
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      {intl.formatMessage({ id: "no_items_found" })}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            ) : (
              <Grid container justify="center">
                {loader && (
                  <CircularProgress
                    classes={{
                      root: "circularProgress",
                    }}
                    size={40}
                    style={{ padding: "10%" }}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {tabExpire && (
            <TabExpireDialog
              tabExpire={tabExpire}
              setTabExpire={setTabExpire}
            />
          )}

          {/* {orderReducer.orderCount && orderReducer.orderCount > 0 ? (
            <div>
              {
                <OrderBar
                  width={props?.width}
                  count={orderReducer.orderCount}
                />
              }
            </div>
          ) : (
            <></>
          )} */}
        </Grid>
      </>
      {/* )} */}
    </div>
  );
};

export default SearchContainer;
