import React, { useState, useEffect } from "react";
import OrderNavbar from "./OrderNavbar";
import OrderLoading from "./OrderLoading";
import cooking from "../../assets/images/thumbs_up.png";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { addItemInHistory } from "../../actions/order";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@material-ui/core/Grid";
import API from "../../utils/API";
import OrderDeclinePic from "../../assets/images/thumbs_down.png";
import ConfirmOrderButton from "./ConfirmOrderButton";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  Orderconfirmed: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "22px",
  },
  detail: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#777777",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0px",
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
  button: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    bottom: 13,
  },
  orderNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#777777",
    padding: "42px 0px 0px 0px",
  },
}));
function OrderComplete() {
  const intl = useIntl();

  const [callApi, setcallApi] = useState(false);
  const [orderAccepted, setorderAccepted] = useState("");
  const [orderExpires, setOrderExpires] = useState(false);
  const [apiInProgress, setApiInProgress] = useState(false);

  const classes = useStyles();
  const [isLoading, setisLoading] = useState(true);
  const history = useHistory();
  const orderReducer = useSelector((state) => state.orderReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    if (callApi === null) {
    } else {
      setisLoading(true);

      const timer = setTimeout(() => {
        setcallApi(true);
        const functionCall = async () => {
          if(!apiInProgress){
          try {
            setApiInProgress(true)
            const response = await API.get(
              `/api/order/${orderReducer.orderDetail.order_uuid}/status/`
            );
            setApiInProgress(false)
            if (response && response.data && response.data.status === 3) {
              setorderAccepted(true);
              setisLoading(false);
              setcallApi(null);

              return;
            } else if (
              response &&
              response.data &&
              response.data.status === 4
            ) {
              if (response && response.data && response.data.tab_status === 4) {
                localStorage.setItem("tabId", "empty");
                localStorage.setItem("tabLink", "empty");
              }
              setorderAccepted(false);
              setisLoading(false);
              setcallApi(null);

              return;
            } else if (
              response &&
              response.data &&
              response.data.has_order_expired
            ) {
              setOrderExpires(true);
              setisLoading(false);
              setcallApi(null);

              return;
            }
          } catch (error) {
            console.log(error);
          }
        }
        };

        functionCall();
      }, 3000);
      setcallApi(false);

      return () => clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [callApi]);

  useEffect(() => {
    if (!orderReducer.orderDetail) {
      history.goBack();
    } else {
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderDetail]);

  const handleClick = () => {
    dispatch(addItemInHistory());

    history.push({
      pathname: "/menu",
    });
  };
  return (
    <div>
      <OrderNavbar
        text={intl.formatMessage({
          id: "Your_order",
        })}
        backurl={"/checkout"}
        backButtonDisable={true}
      />

      {isLoading ? (
        <OrderLoading />
      ) : (
        <div>
          <Grid xs={12} style={{ textAlign: "center" }}>
            <p className={classes.orderNumber}>
              {intl.formatMessage({
                id: "Order",
              })}
              :
              {orderReducer.orderDetail &&
                orderReducer.orderDetail.reference_id}
            </p>
          </Grid>
          <div
            style={{
              textAlign: "center",
              width: "100%",
              padding: " 100px 0 0  0",
            }}
          >
            {orderAccepted ? (
              <>
                <img src={cooking} width={"190px"} alt=""></img>

                <p className={classes.Orderconfirmed}>
                  {intl.formatMessage({
                    id: "Order_confirmed",
                  })}
                </p>
                <p className={classes.detail}>
                  {" "}
                  {intl.formatMessage({
                    id: "Order_confirm_Message",
                  })}
                </p>
              </>
            ) : (
              <>
                {orderExpires ?
                  <>
                  <img src={OrderDeclinePic} width={"190px"} alt=""></img>
  
                  <p className={classes.Orderconfirmed}>
                    {intl.formatMessage({
                      id: "Order_expired",
                    })}
                  </p>
                  <p className={classes.detail}>
                    {" "}
                    {intl.formatMessage({
                      id: "Order_expired_message",
                    })}
                  </p>
                </>:
                <>
                <img src={OrderDeclinePic} width={"190px"} alt=""></img>

                <p className={classes.Orderconfirmed}>
                  {intl.formatMessage({
                    id: "Order_declined",
                  })}
                </p>
                <p className={classes.detail}>
                  {" "}
                  {intl.formatMessage({
                    id: "Order_decline_message",
                  })}
                </p>
              </>}
              </>
            )}

            <ConfirmOrderButton
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
              orderClass={classes.button}
              handleClick={handleClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderComplete;
