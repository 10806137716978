import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { store, persistor } from "./store/index";
import "opensans-npm-webfont";
import theme from "./utils/theme";
import { ThemeProvider } from "@material-ui/core/styles";
import "@openfonts/open-sans_all/index.css";
// import { IntlProvider } from "react-intl";
// import en from "./utils/locales/en";
// import es from "./utils/locales/es";
// import fr from "./utils/locales/fr";
require("dotenv").config();
// var locale = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en';

// let locale = localStorage.getItem("language")?localStorage.getItem("language"):"en";
// const msg = {
//   en: en,
//   es: es,
//   fr: fr,
// };
ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate persistor={persistor}>
        {/* <IntlProvider locale={locale} messages={msg[locale]}> */}
          <App />
        {/* </IntlProvider> */}
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
