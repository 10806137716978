import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useIntl } from "react-intl";

const MessageBox = (props) => {
  const {message}=props;
  const intl = useIntl();
  const classes = useStyles();
  return (
    <Grid className={classes.root}>
      <Typography component="span" className={classes.textMessage}>
        {message}
        {/* {intl.formatMessage({
          id: "only_order_food_menu_table_area",
        })} */}
      </Typography>
    </Grid>
  );
};
export default MessageBox;
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    width: "calc(100% - 80px)",
    minHeight: "44px",
    left: "20px",
    top: "434px",
    padding: "13px 20px",
    background: "#F2F2F2",
    borderRadius: "5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textMessage: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#152A38",
  },
}));
