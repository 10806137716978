
const messages = {
  staying_at_the_hotel: "Staying at the hotel",
  view_menu: "View menu",
  order_now: "Order now",
  room: "ROOM",
  table: "TABLE",
  password: "PASSWORD",
  open_or_join_tab: "Open or Join a tab",
  read_only_mode: "Read only Mode",
  venue_currently_not_accepting_orders: "Venue currently not accepting orders",
  share: "Share",
  exit_tab: "Exit Tab",
  join_tab: "Join Tab",
  open_tab: "Open Tab",
  welcome: "Welcome",
  join_an_existing_tab_or_open_a_new_one:
    "Join an existing tab or open a new one.",
  just_having_a_quick_drink_Select_Order_pay_now:
    "just having a quick drink? Select Order & pay now",
  open_new_tab: "Open new tab",
  order_pay_now: " Order & pay now",
  Join_tab: "Join tab",
  you_do_not_have_any_unpaid_orders: "You do not have any unpaid orders.",
  Back: "Back",
  noImg: "no Img",
  My_orders: "My orders",
  Get_bill: "Get bill",
  Call_server: "Call server",
  No_items_Available: "No items Available",
  Powered_by_Blend: "Powered by Blend",
  No_available_items: "No available items",
  Add_Blend_Menu_To_Home_Screen: "Add Blend Menu To Home  Screen",
  Url_Not_Found: "Url Not Found",
  Enter: "Enter",
  First_Name: "First Name",
  Table_Number: "Table Number",
  Last_Name: "Last Name",
  Open_tab: "Open tab",
  Enter_here: "Enter here",
  Cancel: "Cancel",
  Join: "Join",
  Password: "Password",
  Table: "Table",
  Home: "Home",
  Error_404: "Error 404",
  Page_Not_Found: "Page Not Found",
  check_it_out: "check it out",
  logo: "logo",
  no_items_found: "No items found",
  Choose_type: "Choose type",
  Required: "Required",
  Not_more_than_1: "Not more than 1",
  Note: "Note",
  Leave_a_note: "Leave a note",
  Total: "Total",
  Update_order: "Update order",
  Add_to_order: "Add to order",
  Add_items_to_order: "Add items to new order",

  Your_order: "Your order",
  Room: "Room",
  Select: "Select",
  Number: "Number",
  Order_Note: "Order Note",
  Leave_a_note_for_the_server: "Leave a note for the server",
  Payment_Status: "Payment Status",
  accepted: "accepted",
  only: "only",
  Add_to_tab: "Add to tab",
  Cash: "Cash",
  Card: "Card",
  Pay_now: "Pay now",
  Tip_Server: "Tip Server",
  other: "other",
  Not_Now: "Not Now",
  Subtotal: "Subtotal",
  Tip: "Tip",
  Total: "Total",
  incl_VAT: "incl. VAT",
  Place_Order: "Place Order",
  Order_Note: "Order Note",
  Subtotal: "Subtotal",
  Amount_to_tip: "Amount to tip",
  Add_tip: "Add tip",
  Cancel: "Cancel",
  Would_you_like_to_call_a: "Would you like to call a",
  server: "server?",
  Yes: "Yes",
  Ok: "Ok",
  How_would_you_like: "How would you like",
  to_pay: "to pay?",
  A_server_will_be_with: "A server will be with",
  you_shortly: "you shortly.",
  Staying_at_the_hotel: "Staying at the hotel?",
  Share_link: "Share link",
  Login: "Login",
  Blend_Menu: "Blend Menu",
  add_home_screen_message:
    "Add this app to your home screen for esay access and better experience",
  Tap: "Tap",
  Add_to_Home_Screen: "then 'Add to Home Screen'",
  Checkout: "Checkout",
  View_menu: "View menu",
  Order: "Order",
  Order_confirmed: "Order confirmed!",
  Order_confirm_Message:
    "Your order has been confirmed, to view it go to Previous Orders on the home screen.",
  Order_declined: "Order declined",
  Order_expired: "Order expired",
  Great: "Great!",
  Order_expired_message:
    "Your order has been expired, for more information please speak to the management.",
  Order_decline_message:
    "Your order has been declined, for more information please speak to the management.",
  Order_decline_kitchen:
    "Looks like the kitchen declined your order, for more information please speak to the manager",
  order_complete_message:
    "Your order has been sent, waiting for the venue to accept it.",
  Order_number: "Order number",
  Pay: "Pay",
  card: "card",
  payment_message:
    "Please choose payment method below, keep in mind if you pick cash to not have an empty wallet!",
  How_would_you_like_to_pay: "How would you like to pay?",
  My_Orders: "My Orders",
  Order: "Order",
  Declined: "Declined",
  Accepted: "Accepted",
  Not_Paid: "Not Paid",
  Paid: "Paid",
  No_Previous_Order: "No Previous Order",
  Payment_failed: "Payment failed",
  Are_you_sure_you: "Are you sure you",
  want_to_exit: "want to exit",
  No: "No",
  Your_Tab_has_expired: "Your Tab has expired",
  About: "About",
  Add_item: "Add item",
  Error_404: "Error 404",
  Page_Not_Found: "Page Not Found ",
  check_it_out: "check it out",
  Search: "Search",
  Email_sent_Successfully: "Email sent Successfully",
  Something_went_wrong_Try_Again: "Something went wrong. Try Again",
  We_have_sent_you_an_email: "We’ve sent you an email",
  with_your_session_link: "with your session link",
  Go_to_your_inbox: "Go to your inbox",
  Send_again: "Send again",
  Enter_your_table_number: "Enter your table number",
  Enter_your_room_number: "Enter your room number",
  View_menu_and_order: "View menu and order",
  something_went_wrong: "something went wrong!",
  Enter_email: "Enter email",
  You_will_receive_your_access_link: "You’ll receive your access link",
  Enter_email_used_during_check_in: "Enter email used during check-in",
  Enter_email_here: "Enter email here",
  Invalid_Email: "Invalid Email",
  Field_is_required: "Field is required",
  Send_Email: "Send Email",
  Table_number_or_password_match: "Table number or password don’t match",
  Missing_fields: "Missing fields",
  your_card_details:"Your Card details",
  payment_information:"Payment information",
  Or_enter_card_details:"Or enter card details",
  Credit_Card_Number:"Credit Card Number",
  Expiration_Date:"Expiration Date",
  CVC:"CVC",
  Email_Receipt_optional:"Email Receipt (optional)",
  Write_your_email:"Write your email",
  Email:"Email",
  Order_total:"Order total",
  continue:"Continue",
  not_staying_at_the_hotel:"Not staying at the hotel",
  check_out_date:"Check-out date",
  enter_room_number:"Ente room number",
  room_number:"Room number",
  enter_surname:"Enter surname",
  surname:"Surname",
  hotel_guest_login:"Hotel guest login",
  just_view_menu:"Just view menu",
  only_order_food_menu_table_area:"You can only order from the food menu at the table area.",
  enter_table_number:"Enter table number",
  Charge_my_room:"Charge my room",
  select_table_number:"Select Table Number",
  select_room_number:"Select Room Number",
  A_server_will_be_with_you_shortly:"A server will be with you shortly",
  How_would_you_like_to_pay:"How would you like to pay",
  Would_you_like_to_call_a_server:"Would you like to call a server",
  We_have_sent_you_an_email_with_your_session_link:"We have sent you an email with your session link",
  };

export default messages;
