import React from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  detail: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#777777",

    paddingLeft: "2rem",
    paddingRight: "2rem",
    position: "absolute",
    bottom: 13,
  },
  loadingicon: {
    textAlign: "center",
    padding: " 13rem 0",
  },
  loadingtext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#000000",
    margin: "10px 0px 0px 5px",
  },
  orderNumber: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    color: "#777777",
    padding: "42px 0px 0px 0px ",
  },
}));
function OrderLoading() {
  const intl = useIntl();

  const classes = useStyles();
  let orderReducer = useSelector((state) => state.orderReducer);

  return (
    <div>
      <Grid xs={12} style={{ textAlign: "center" }}>
        <p className={classes.orderNumber}>
          {intl.formatMessage({
            id: "Order_number",
          })}{" "}
          : {orderReducer.orderDetail && orderReducer.orderDetail.reference_id}
        </p>
      </Grid>
      <div className={classes.loadingicon}>
        <CircularProgress color="secondary" />
        <p className={classes.loadingtext}>
          {intl.formatMessage({
            id: "order_complete_message",
          })}
        </p>
      </div>
    </div>
  );
}

export default OrderLoading;
