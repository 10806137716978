const messages = {
  staying_at_the_hotel: "Rester à l'hôtel",
  view_menu: "Afficher le menu",
  order_now: "Commandez maintenant",
  room: "PIÈCE",
  table: "TABLE",
  password: "LE MOT DE PASSE",
  open_or_join_tab: "Ouvrir ou rejoindre",
  read_only_mode: "Mode lecture seule",
  venue_currently_not_accepting_orders:
    "Le lieu n'accepte pas les commandes pour le moment",
  share: "Partager",
  exit_tab: " Quitter",
  join_tab: " Rejoindre",
  open_tab: " l'onglet",
  welcome: "Bienvenue",
  join_an_existing_tab_or_open_a_new_one:
    "Rejoignez un onglet existant ou ouvrez-en un nouveau.",
  just_having_a_quick_drink_Select_Order_pay_now:
    "juste en train de boire un verre? Sélectionnez Commander et payer maintenant",
  open_new_tab: "Ouvrir un nouvel onglet",
  order_pay_now: "Commandez et payez maintenant",
  Join_tab: "Onglet Rejoindre",
  you_do_not_have_any_unpaid_orders: "Vous n'avez aucune commande impayée.",
  Back: "Retour",
  noImg: "pas d'img",

  My_orders: "Mes commandes",
  Get_bill: "Obtenir la facture",
  Call_server: "Serveur d'appel",
  No_items_Available: "Aucun article disponible",
  Powered_by_Blend: "Propulsé par Blend",
  No_available_items: "Aucun article disponible",
  Add_Blend_Menu_To_Home_Screen: "Ajouter le menu de fusion à l'écran d'accueil",
  Url_Not_Found: "URL introuvable",
  Enter: "Entrer",
  First_Name: "Prénom",
  Table_Number: "Numéro de table",
  Last_Name: "Nom de famille",
  Open_tab: "Ouvrir l'onglet",
  Enter_here: "Entrer ici",
  Cancel: "Annuler",
  Join: "Rejoindre",
  Password: "Mot de passe",
  Table: "Tableau",
  Home: "Domicile",
  Error_404: "Erreur 404",
  Page_Not_Found: "Page non trouvée",
  check_it_out: "Vérifiez-le",
  logo: "logo",
  no_items_found: "Aucun élément trouvé",
  Choose_type: "Choisissez le type",
  Required: "Obligatoire",
  Not_more_than_1: "Pas plus de 1",
  Note: "Noter",
  Leave_a_note: "Laisser une note",
  Total: "Le total",
  Update_order: "Mise à jour de la commande",
  Add_to_order: "Ajouter à la commande",
  Add_items_to_orders: "Ajouter des articles à une nouvelle commande",
  Your_order: "Votre commande",
  Room: "Chambre",
  Select: "Sélectionner",
  Number: "Nombre",
  Order_Note: "Note de commande",
  Leave_a_note_for_the_server: "Laisser une note pour le serveur",
  Payment_Status: "Statut de paiement",
  accepted: "accepté",
  only: "seule",
  Add_to_tab: "Ajouter à l'onglet",
  Cash: "En espèces",
  Card: "Carte",
  Pay_now: "Payez maintenant",
  Tip_Server: "Serveur de conseils",
  other: "autre",
  Not_Now: "Pas maintenant",
  Subtotal: "sous-total",
  Tip: "Conseil",
  Total: "Le total",
  incl_VAT: "incl. VAT",
  Place_Order: "Passer la commande",
  Order_Note: "Note de commande",
  Subtotal: "sous-total",
  Amount_to_tip: "Montant pour pourboire",
  Add_tip: "Ajouter un pourboire",
  Cancel: "Annuler",
  Would_you_like_to_call_a: "Souhaitez-vous appeler un",
  server: "serveuse",
  Yes: "Oui",
  Ok: "D'accord",
  How_would_you_like: "Comment veux-tu",
  to_pay: "payer?",
  A_server_will_be_with: "Un serveur sera avec",
  you_shortly: "vous sous peu.",
  Staying_at_the_hotel: "Staying at the hotel?",
  Share_link: "Share link",
  Login: "Login",
  Blend_Menu: "Blend Menu",
  add_home_screen_message:
    "Add this app to your home screen for esay access and better experience",
  Tap: "Tap",
  Add_to_Home_Screen: "then 'Add to Home Screen'",
  Checkout: "Checkout",
  View_menu: "View menu",
  Order: "Order",
  Order_confirmed: "Order confirmed!",
  Order_confirm_Message:
    "Your order has been confirmed, to view it go to Previous Orders on the home screen.",
  Order_declined: "Order declined",
  Order_expired: "Order expired",
  Great: "Great!",

  Order_expired_message:
    "Your order has been expired, for more information please speak to the management.",
  Order_decline_message:
    "Your order has been declined, for more information please speak to the management.",
  Order_decline_kitchen:
    "Looks like the kitchen declined your order, for more information please speak to the manager",
  order_complete_message:
    "Your order has been sent, waiting for the venue to accept it.",
  Order_number: "Order number",
  Pay: "Pay",
  card: "card",
  payment_message:
    "Please choose payment method below, keep in mind if you pick cash to not have an empty wallet!",
  How_would_you_like_to_pay: "How would you like to pay?",
  My_Orders: "My Orders",
  Order: "Order",
  Declined: "Declined",
  Accepted: "Accepted",
  Not_Paid: "Not Paid",
  Paid: "Paid",
  No_Previous_Order: "No Previous Order",
  Payment_failed: "Payment failed",
  Are_you_sure_you: "Are you sure you",
  want_to_exit: "want_to_exit",
  No: "No",
  Your_Tab_has_expired: "Your Tab has expired",
  About: "About",
  Add_item: "Add item",
  Error_404: "Error 404",
  Page_Not_Found: "Page Not Found ",
  check_it_out: "check it out",
  Search: "Search",
  Email_sent_Successfully: "Email_sent_Successfully",
  Something_went_wrong_Try_Again: "Something went wrong. Try Again",
  We_have_sent_you_an_email: "We’ve sent you an email",
  with_your_session_link: "with your session link",
  Go_to_your_inbox: "Go to your inbox",
  Send_again: "Send again",
  Enter_your_table_number: "Enter_your_table_number",
  View_menu_and_order: "View menu and order",
  something_went_wrong: "something went wrong!",
  Enter_email: "Enter email",
  You_will_receive_your_access_link: "You’ll receive your access link",
  Enter_email_used_during_check_in: "Enter email used during check-in",
  Enter_email_here: "Enter email here",
  Invalid_Email: "Invalid Email",
  Field_is_required: "Field is required",
  Send_Email: "Send Email",
  Enter_your_table_number: "Enter your table number",
  Field_is_required: "Field is  required",
  View_menu_and_order: "View menu and order",
  Table_number_or_password_match: "Table number or password don’t match",
  Missing_fields: "Missing fields",
  select_table_number:"sélectionner le numéro de table",
  select_room_number:"sélectionner le numéro de chambre"
};
export default messages
