import React from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createOrder } from "../../actions/order";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from "react-router-dom";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  topText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "10px 50px 0px 50px",
  },
  centerText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    color: "#000000",
  },
  button: {
    width: " 100%",
    height: "100%",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function TabDialog(props) {
  const intl = useIntl();

  const { open, setOpen } = props;
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let orderDetail = useSelector((state) => state.orderReducer.orderDetail);
  const handleClose = () => {
    setOpen(false);
  };
  const handleCloseYes = () => {
    setOpen(false);
    localStorage.setItem("tabId", "empty");
    localStorage.setItem("tabLink", "empty");
    localStorage.removeItem("orderId");
    dispatch(createOrder({ venue_id: menuReducer.menu?.uuid }));

    if (
      localStorage.getItem("sessionId") !== null &&
      localStorage.getItem("sessionId") !== "null"
    ) {
      history.push(`/menu/?sessionid=${localStorage.getItem("sessionId")}`);
    } else {
      if (menuReducer?.menu?.accept_card === true) {
        history.push(`/menu`);
      } else {
        history.push(`/`);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        style={{ width: "100%", height: "500px", margin: "50px 0px 0px 0px" }}
      >
        <DialogTitle id="form-dialog-title" className={classes.topText}>
          {intl.formatMessage({ id: "Are_you_sure_you" })}
          <br />
          {intl.formatMessage({ id: "want_to_exit" })}
        </DialogTitle>
        <DialogContent>
          <p className={classes.centerText}>
            {orderDetail && orderDetail.table_number} -{" "}
            {orderDetail && orderDetail.tab_name}?
          </p>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.button}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "No" })}
            </Button>
          </div>
          <div className={classes.buttonDiv}>
            <Button className={classes.cancelbutton} onClick={handleCloseYes}>
              {intl.formatMessage({ id: "Yes" })}
            </Button>
          </div>
        </DialogContent>
        {/* <DialogActions> */}

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
