import { UPDATE_LANGUAGE} from "../constant/types";

const initialState = {
  language: "en",
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    

    default:
      return state;
  }
}
