import React, { useState, useEffect } from "react";
import { Button, Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { requestService } from "../../actions/order";
import { defaultThemeColor } from "../../constant/colors";
import SelectTableDialog from "./SelectTableDialog";
// import { ROOM_SERVICE } from "../utils/constants";
import {ROOM_SERVICE} from "../../utils/constants"
export default function TabExpireDialog(props) {
  const { open, handleClose, confirm } = props;
  const classes = useStyles();
  const intl = useIntl();

  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let tabExpire = useSelector((state) => state.orderReducer.tabExpire);
  let orderReducer = useSelector((state) => state.orderReducer);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const [tableNumber, setTableNumber] = useState(null);
  const [openSelctTable, setOpenSelectTable] = useState(true);
  let orderDetail = useSelector((state) => state.orderReducer.orderDetail);
  const handleContinue = (table_number) => {
    setTableNumber(table_number);
    setOpenSelectTable(false);
  };
  useEffect(() => {
    if (localStorage.getItem("tabId") !== "empty") {
      if (orderDetail?.table_number) {
        setOpenSelectTable(false);
      }
    }
    // eslint-disable-next-line
  }, [orderDetail?.table_number]);
  const handleCloseYes = async (method) => {
    setType(method);
    setShowLoader(true);

    let res = await dispatch(
      requestService({
        order_uuid: orderReducer.orderId,
        body: tableNumber?{
          request_type: 0,
          payment_method: method,
          table_number:tableNumber
        }:{  request_type: 0,
          payment_method: method},
      })
    );

    if (res.status === 200) {
      setShowLoader(false);
      confirm(true, "server");
    } else {
      setShowLoader(false);
      setMessage(res.message);
      setTimeout(() => {
        setMessage("");
      }, 10000);
    }
  };
  const handleCloseDialog = () => {
    handleClose(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        style={{
          borderRadius: "20px",
        }}
        className={classes.rounded}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {!openSelctTable && (
            <p className={classes.tabText}>
              {intl.formatMessage({
                id: "How_would_you_like",
              })}
              <br />
              {intl.formatMessage({
                id: "to_pay",
              })}
            </p>
          )}
          {message && (
            <Grid container justify="center">
              <Typography
                className={classes.errorText}
                style={{ marginBottom: "20px" }}
              >
                {message}
              </Typography>
            </Grid>
          )}
          {openSelctTable ? (
            <SelectTableDialog handleContinue={handleContinue} />
          ) : (<>
        { menuReducer?.menu?.business_account?.type === 1&&
         menuReducer?.menu?.type !== ROOM_SERVICE&&
        <Grid
            container
            className={classes.buttonDiv2}
            style={{ marginTop: "30px",marginBottom:"4px" }}
            justify="center"
          >
            <Button
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor
                  ,maxWidth:"calc(100% - 9px)"

              }}
              className={classes.button}
              onClick={() => handleCloseYes("room_charge")}
            >
              {intl.formatMessage({
                id: "Charge_my_room",
              })}
            </Button>
          </Grid>}
            <Grid container justify="center" >
              <Grid item>
                <div
                  className={classes.buttonDiv}
                  style={{ marginRight: "10px" }}
                >
                  <Button
                    className={ menuReducer?.menu?.business_account?.type === 1&&
                      menuReducer?.menu?.type !== ROOM_SERVICE?classes.button2:classes.button}
                    onClick={() => handleCloseYes("card")}
                    style={ menuReducer?.menu?.business_account?.type === 1&&
                      menuReducer?.menu?.type !== ROOM_SERVICE?{
                        border: `1px solid ${
                          localStorage.getItem("color")
                            ? localStorage.getItem("color")
                            : defaultThemeColor
                        }`,
                      }:{
                      background: localStorage.getItem("color")
                        ? localStorage.getItem("color")
                        : defaultThemeColor,
                    }}
                  >
                    {showLoader && type === "card" ? (
                      <CircularProgress size={20} style={{ color: "#fff" }} />
                    ) : (
                      intl.formatMessage({
                        id: "Card",
                      })
                    )}
                  </Button>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.buttonDiv}>
                  <Button
                    className={classes.button2}
                    onClick={() => handleCloseYes("cash")}
                    style={{
                      border: `1px solid ${
                        localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor
                      }`,
                    }}
                  >
                    {showLoader && type === "cash" ? (
                      <CircularProgress size={20} style={{ color: "#000" }} />
                    ) : (
                      intl.formatMessage({
                        id: "Cash",
                      })
                    )}
                  </Button>
                </div>
              </Grid>
            </Grid></>
          )}

          <Grid
            container
            className={classes.buttonDiv2}
            style={{ marginTop: openSelctTable ? "10px" : "30px" }}
          >
            <Button className={classes.button3} onClick={handleCloseDialog}>
              {intl.formatMessage({
                id: "Cancel",
              })}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },
  rounded: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 20px 0px",
  },

  button2: {
    width: " 100%",
    height: "40px",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  button3: {
    width: " 100%",
    height: "40px",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#777777",
    minWidth: "250px",

    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  button: {
    width: " 100%",
    height: "40px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv2: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
    width: "100%",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
    maxWidth: "120px",
    minWidth: "115px",
  },
  dialog: {
    width: "300px",
  },
}));
