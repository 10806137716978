import React from "react";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import exportIcon from "../../assets/images/exportIcon.jpg";
import { useIntl } from "react-intl";
const useStyles = makeStyles((theme) => ({
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    display: "inline",

    color: "#000000",
  },
  DetailText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "inline",

    color: "#777777",
  },

  button: {
    width: " 100%",
    height: "100%",
    background: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "1rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function InstallDialog(props) {
  const { open, setOpen } = props;
  const classes = useStyles();
  const intl = useIntl();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        style={{ width: "100%", height: "500px", margin: "50px 0px 0px 0px" }}
        // aria-labelledby="form-dialog-title"
        // className="MuiDialog-paper"
        fullScreen={false}
        // fullWidth={true}
        // maxWidth={maxWidth}
      >
        {/* <DialogTitle id="form-dialog-title">Amount to tip</DialogTitle> */}
        <DialogContent>
          <Grid style={{ textAlign: "end" }}>
            <div onClick={handleClose}>
              <ClearIcon />
            </div>
          </Grid>
          <Grid style={{ textAlign: "center" }}>
            {" "}
            <img src={props.BlendIcon} width={"50px"} alt=""></img>
          </Grid>
          <Grid style={{ textAlign: "center" }}>
            <p>
              {intl.formatMessage({
                id: "Blend_Menu",
              })}
            </p>
          </Grid>

          <Grid style={{ textAlign: "center", marginBottom: "30px" }}>
            <p className={classes.DetailText}>
              {intl.formatMessage({
                id: "add_home_screen_message",
              })}
            </p>
          </Grid>
          <Grid style={{ textAlign: "center", marginBottom: "30px" }}>
            <p className={classes.GenralText}>
              {intl.formatMessage({
                id: "Tap",
              })}{" "}
            </p>
            <span>
              <img src={exportIcon} width={"15px"} alt=""></img>
            </span>
            <p className={classes.GenralText}>
              {intl.formatMessage({
                id: "Add_to_Home_Screen'",
              })}
            </p>
          </Grid>
        </DialogContent>
        {/* <DialogActions> */}

        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}
