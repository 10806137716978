import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory, useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { CANCEL_SEARCH_OR_ADD_ITEM } from "../constant/types";
import AppBar from "@material-ui/core/AppBar";
import TabBar from "../components/Tab/TabBarSearch";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
// import { buttonColor } from "../constant/methods";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  outlinedStyle: {
    border: "0px",
  },
  TextInputStyle: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#303A5F",
    border: "0px",
    padding: "12px",
  },
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  cancelButton: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "right",
    color: "#000000",
    cursor: "pointer",
  },
  appBar: {
    position: "-webkit-fixed",
    // eslint-disable-next-line
    position: "fixed",
    top: 0,

    bottom: "auto",
    background: "#fff",
    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
    paddingLeft: "0px",
    right: "unset",
  },
}));
function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
export default function TopGridMenu(props) {
  const location = useLocation();
  const intl = useIntl();

  const [viewOnlyMenu, setViewOnlyMenu] = useState(false);
  useEffect(() => {
    let type = new URLSearchParams(location.search).get("type");
    if (type === "view") {
      setViewOnlyMenu(true);
    }
  }, [location.search]);
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  const { handleChangeSearchText, handleSearch, searchText } = props;
  let menuReducer = useSelector((state) => state.menuReducer);
  // eslint-disable-next-line
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldScroll(true);
    }, 3000);
  }, []);
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.target.blur();
      handleSearch();
    }
    if (event.target.value.trim().length > 0 && event.keyCode === 32) {
      handleSearch();
    }
  };
  const cancelSearch = () => {
    dispatch({ type: CANCEL_SEARCH_OR_ADD_ITEM, payload: null });
    history.push(`/menu/${window.location.search}`);
  };
  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);
  return (
    <ElevationScroll {...props}>
      <AppBar
        position="fixed"
        style={{ maxWidth: width ? width : "526px" }}
        className={classes.appBar}
      >
        {localStorage.tabId &&
          !menuReducer?.menu?.read_only_menu &&
          !viewOnlyMenu &&
          menuReducer?.menu?.read_only_menu !== undefined && (
            <TabBar
              setSelectedCard={props?.setSelectedCard}
              setShowDetails={props?.setShowDetails}
              mainPage={true}
            />
          )}
        <Grid container style={{ minHeight: "75px" }}>
          <Grid
            item
            xs={2}
            style={{
              maxWidth: "50px",
            }}
            className={classes.flexCenter}
          >
            <SearchIcon
              onClick={() => handleSearch()}
              style={{ color: "#0C0E0F", cursor: "pointer" }}
            />
          </Grid>
          <Grid item xs={8} className={classes.flexCenter}>
            <TextField
              id="standard-search"
              variant="outlined"
              placeholder={intl.formatMessage({ id: "Search" })}
              onChange={(e) => handleChangeSearchText(e)}
              value={searchText}
              onKeyDown={handleKeyDown}
              type="search"
              className={classes.textField}
              margin="none"
              on
              fullWidth
              inputProps={{
                className: classes.TextInputStyle,
              }}
              InputProps={{
                classes: {
                  notchedOutline: classes.outlinedStyle,
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs={2}
            onClick={() => cancelSearch()}
            className={classes.flexCenter}
          >
            <Typography className={classes.cancelButton}>
              {intl.formatMessage({ id: "Cancel" })}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
    </ElevationScroll>
  );
}
