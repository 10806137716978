import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useDispatch, useSelector } from "react-redux";
import { updateLanguage } from "../../actions/Menu";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 40,
    background: "transparent",
    maxHeight: "30px !important",
    marginTop: "-6px",
  },
  selectEmpty: {
    border:"0px",
    underlineColor:'transparent',
    "& .MuiSelect-icon":{
      color:"#000",
      border:"0px"
    }
  },
  selectEmptyMenu: {
    border:"0px",
    underlineColor:'transparent',
    "& .MuiSelect-icon":{
      color:"#fff",
      border:"0px"
    }
  },
}));

export default function LanguageDropDown({type}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [language, setLanguage] = React.useState("en");
  useEffect(() => {
    setLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "en"
    );
  }, [localStorage.getItem("language")]);
  useEffect(() => {
    setLanguage(
      localStorage.getItem("language") ? localStorage.getItem("language") : "en"
    );
  }, []);
  const handleChange = (event) => {
    setLanguage(event.target.value);
    dispatch(updateLanguage({ data: event.target.value }));
    localStorage.setItem("language", event.target.value);
  };
  const supported_translations_dict = useSelector(
    (state) => state?.menuReducer?.menu?.supported_translations_dict
  );
  const default_language = useSelector(
    (state) => state?.menuReducer?.menu?.default_language
  );
  
  useEffect(() => {
    if(!localStorage.getItem("language")){
    setLanguage(default_language)
    dispatch(updateLanguage({ data: default_language }));
    localStorage.setItem("language", default_language);
    }
  }, []);
  return (
    <div>{
      supported_translations_dict&&Object.keys(supported_translations_dict).length>1&&
      
      <FormControl required className={classes.formControl}>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={language}
          variant="standard"
          onChange={handleChange}
          className={type==="menu"?classes.selectEmptyMenu:classes.selectEmpty}
          style={{ textTransform: "uppercase",
          
          color:type==="menu"?"#fff":"#000",borderColor:"transparent" }}
        >
          {supported_translations_dict &&
            Object.keys(supported_translations_dict).map((item) => {
              return (
                <MenuItem value={item} style={{ textTransform: "uppercase" }}>
                  {supported_translations_dict[item]}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
}</div>
  );
}
