import {
  SET_RESTAURANT_MENU,
  SET_MENU_ID,
  SELECTED_MENU,
  TABLE_LIST,
  SET_RESTAURANT_DETAILS,
  SET_MENU_REQUEST_LOADING

} from "../constant/types";

const initialState = {
  menu: null,
  menuId: null,
  selectedMenu: null,
  requestInprogress:null,

  tableList: null,
  borderColor: "#FF3366",
  buttonColor: "#FF3366",
  themeColor: "#FF3366",

  // colour
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MENU_REQUEST_LOADING: {
      return {
        ...state,
        requestInprogress:payload
      }
    }
    case SET_RESTAURANT_DETAILS: {
      let color = payload?.colour;
      localStorage.setItem("color", color);
      return {
        ...state,
        menu: { ...payload },
        menuId: payload.id,
        borderColor: color,
        buttonColor: color,
        themeColor: color,
      };
    }
    case SET_RESTAURANT_MENU: {
      let menus = [...payload?.menus];
      menus &&
        menus.forEach((menu, indexMenu) => {
          let menuAvailability = menu?.availability?.out_of_stock;
          let newMenu = { ...menu };

          newMenu.menu_categories.forEach((category, indexCategory) => {
            let categoryAvailability = category?.availability?.out_of_stock;
            let newCat = { ...category };

            category.items.forEach((item, indexItem) => {
              let itemAvailability = item?.availability?.out_of_stock;
              let newItem = { ...item };
              newItem.availability.out_of_stock =
                menuAvailability || categoryAvailability || itemAvailability;
              newCat[indexItem] = newItem;
            });

            newMenu[indexCategory] = newCat;
          });
          return menus[indexMenu];
        });

      return {
        ...state,
        menu: { ...state.menu, menus: [...menus] ,
          requestInprogress:false,

        },
      };
    }
    case SET_MENU_ID:
      return {
        ...state,
        menuId: payload,
      };
    case TABLE_LIST:
      return {
        ...state,
        tableList: payload,
      };
    case SELECTED_MENU:
      return {
        ...state,
        selectedMenu: payload,
      };

    default:
      return state;
  }
}
