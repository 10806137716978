import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { useSelector } from "react-redux";
import { Button, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    maxWidth: 530,
    width: "100%",
    right: "unset",
  },
  yourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "left",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
  },
  countBadge: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    height: "32px",
    width: "32px",
    borderRadius: "5px",
    justifyContent: "center",
    backgroundColor: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
  },
  innerBox: {
    margin: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
  },
  badgeGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function AddCartButton(props) {
  const classes = useStyles();
  const intl = useIntl();

  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );

  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);
  return (
    <div style={{ cursor: "pointer" }}>
      <Grid container>
        <Grid container>
          <AppBar
            position="fixed"
            color="inherit"
            className={classes.appBar}
            style={{
              maxWidth: width ? width : "530px",
            }}
          >
            <Toolbar style={{ padding: "0px" }}>
              <Grid container className={classes.innerBox}>
                <Grid item xs={12}>
                  <Button
                    className={props?.orderClass}
                    // disabled={props?.disable}
                    onClick={props?.handleSubmit}
                    style={{ ...props?.style }}
                  >
                    {props?.loader ? (
                      <CircularProgress style={{ color: "#fff" }} size={20} />
                    ) : props?.edit ? (
                      intl.formatMessage({ id: "Update_order" })
                    ) : (
                      intl.formatMessage({ id: "Add_to_order" })
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </div>
  );
}

export default AddCartButton;
