import React, { useState,useEffect } from "react";

import splashLogoPic from "../../assets/images/splashLogoPic.png";
import Skeleton from "@material-ui/lab/Skeleton";
import { useIntl } from "react-intl";

const Logo = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const intl = useIntl();

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob); 
      reader.onloadend = () => {
        const base64data = reader.result;   
        resolve(base64data);
      }
    });
  }
  
  const toDataURL=(src, callback, outputFormat)=> {
    var img = new Image();
    img.crossOrigin = 'Anonymous';

    img.onload = function() {
      var canvas = document.createElement('CANVAS');
      var ctx = canvas.getContext('2d');
      var dataURL;
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      ctx.drawImage(this, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
    };
    img.onerror=(er)=>{
      console.log("er",er)
      localStorage.setItem("logo","empty")
    }
    img.src = src;

    if (img.complete || img.complete === undefined) {
      img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
      img.src = src;
    }
  }
  
  
  useEffect(()=>{
    let url=`${
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.origin
    }/logo.jpeg`;
    toDataURL(
      url,
      function(dataUrl) {
        localStorage.setItem("logo",dataUrl)
      }
    )
    //  getBase64FromUrl(url).then((res)=>(
    // localStorage.setItem("logo",res))    ).catch(e=>(localStorage.setItem("logo","empty")))
 // eslint-disable-next-line
   },[])
  return (
    <>
      {(localStorage.getItem("logo")===null||localStorage.getItem("logo")==="empty"||localStorage.getItem("logo")===undefined)&&
      isLoading ? (
        <Skeleton
          animation="wave"
          variant={"rect"}
          style={{borderRadius:"20px"}}
          width={"130px"}
          height={"130px"}
        />
      ) : null}
      <img
        onLoad={() => {
          setIsLoading(false);
        }}
        onError={(err) => {
          setIsLoading(false);
          setIsError(true);
        }}
        // src={localStorage.getItem("logo")}
        
        src={(localStorage.getItem("logo")!==null&&localStorage.getItem("logo")!=="empty"&&localStorage.getItem("logo")!==undefined)?localStorage.getItem("logo"): `${
                process.env.NODE_ENV === "development"
                  ? "https://busaba.staging.blend.menu"
                  : window.location.origin
              }/logo.jpeg`}
        // src={localStorage.getItem("icon")?`data:image/jpeg;base64,${localStorage.getItem("icon")}`:splashLogoPic}
        // src={splashLogoPic}
        width={"130px"}
        style={{
          borderRadius: "15%",
          height: "130px",

          // display: isLoading ? "none" : "block",
        }}
        alt={intl.formatMessage({ id: "logo" })}
      ></img>
    </>
  );
};

export default Logo;
