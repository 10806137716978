import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { buttonColor } from "../../constant/methods";
import { useIntl } from "react-intl";
import { defaultThemeColor } from "../../constant/colors";

const useStyles = makeStyles((theme) => ({
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",

    color: "#000000",
  },

  button: {
    width: " 100%",
    height: "100%",
    background: buttonColor ? buttonColor : "#FF3366",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: buttonColor ? buttonColor : "#FF3366",
    },
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "1rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function TipModel(props) {
  const { open, setOpen, tipAmount, setTipAmount } = props;
  const classes = useStyles();
  const intl = useIntl();
  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        // onClose={handleClose}
        style={{
          width: "100%",
          height: "500px",
        }}
        // aria-labelledby="form-dialog-title"
        // className="MuiDialog-paper"
        // fullScreen={true}
        fullWidth={true}
        maxWidth={"sm"}
      >
        <DialogTitle id="form-dialog-title">
          {intl.formatMessage({
            id: "Amount_to_tip",
          })}
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We will send updates
            occasionally.
          </DialogContentText> */}
          <TextField
            variant="outlined"
            value={tipAmount}
            autoFocus={open}
            onChange={(e) => {
              if (e.target.value >= 0 || e.target.value === "") {
                setTipAmount(e.target.value);
              }
            }}
            placeholder="0.00"
            type="number"
            style={{ paddingBottom: 10 }}
            fullWidth
          />
          <div className={classes.buttonDiv}>
            <Button className={classes.button} onClick={handleClose}
            style={{background:localStorage.getItem("color")
            ? localStorage.getItem("color")
            : defaultThemeColor}}
            >
              {intl.formatMessage({
                id: "Add_tip",
              })}
            </Button>
          </div>
          <div className={classes.buttonDiv}>
            <Button className={classes.cancelbutton} onClick={handleClose}>
              {intl.formatMessage({
                id: "Cancel",
              })}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
