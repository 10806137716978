import React, { useState, useEffect } from "react";
import { Button, Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { requestService } from "../../actions/order";
import { defaultThemeColor } from "../../constant/colors";
import { getTableAllList } from "../../actions/order";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
export default function TabExpireDialog(props) {
  const { open, handleClose, confirm,handleContinue } = props;
  const classes = useStyles();
  const intl = useIntl();

  const [tableListView, settableListView] = useState([]);
  const [password, setPassword] = useState(null);
  const [tableNumber, setTableNumber] = useState("");
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(window.textareaheight);
  const [table, setTable] = useState(null);

  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let tabExpire = useSelector((state) => state.orderReducer.tabExpire);
  let tableList = useSelector((state) => state?.menuReducer?.tableList);

  let orderReducer = useSelector((state) => state.orderReducer);
  const [showLoader, setShowLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [type, setType] = useState("");
  const handleCloseYes = async (method) => {
    setType(method);
    setShowLoader(true);

    let res = await dispatch(
      requestService({
        order_uuid: orderReducer.orderId,
        body: {
          request_type: 0,
          payment_method: method,
        },
      })
    );

    if (res.status === 200) {
      setShowLoader(false);
      confirm(true, "server");
    } else {
      setShowLoader(false);
      setMessage(res.message);
      setTimeout(() => {
        setMessage("");
      }, 10000);
    }
  };
  const handleCloseDialog = () => {
    handleContinue(tableNumber)
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [menuReducer?.menu]);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, [tableList]);

  const getData = async () => {
    setLoader(true);
    if (menuReducer?.menu) {
      let tables_url =
        (process.env.NODE_ENV === "development"
          ? "https://busaba.staging.blend.menu"
          : window.location.protocol + "//" + window.location.host) +
        "/tables/list-all/";
      // eslint-disable-next-line
      let res1 = await dispatch(getTableAllList(tables_url));
      if (res1?.status === 200) {
        setLoader(false);
      }
    }
  };
  const handleChange = (event) => {
    setTableNumber(event.target.value);
  };
  return (
    <div>
      <DialogContent>
        <p className={classes.tabText}>
          {true ? (
            <>
              {intl.formatMessage({
                id: "enter_table_number",
              })}
            </>
          ) : (
            <>
              {intl.formatMessage({
                id: "How_would_you_like",
              })}
              <br />
              {intl.formatMessage({
                id: "to_pay",
              })}
            </>
          )}
        </p>
       {message&& <Grid container justify="center">
          <Typography
            className={classes.errorText}
            style={{ marginBottom: "20px" }}
          >
            {message}
          </Typography>
        </Grid>}
        <Grid container justify="center">
        
          <FormControl variant="outlined"
              placeholder={"enter table"}
          
          className={classes.formControl}>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={tableNumber}
              className={classes.selectTable}
              onChange={handleChange}
              disabled={table}
              placeholder={"enter table"}
            >
              {tableListView.map((item) => (
                <MenuItem value={item.id}>{item.id}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
            container
            className={classes.buttonDiv2}
            style={{ marginTop: "30px" }}
          >
            <Button
              disabled={!tableNumber}
              onClick={handleCloseDialog}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
              className={classes.button}

            >
              {intl.formatMessage({
                id: "continue",
              })}
            </Button>
          </Grid>
      </DialogContent>
    </div>
  );
}


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "100%",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "50px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },
  rounded: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 20px 0px",
  },

  button2: {
    width: " 100%",
    height: "40px",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  button3: {
    width: " 100%",
    height: "40px",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#777777",
    minWidth: "250px",

    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },

  button: {
    width: " 100%",
    height: "40px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "19px",
    color: "#FFFFFF",
    textTransform: "none",
"&:disabled":{
    color: "#FFFFFF",
backgroundColor:"#AEAEAE !important"
}
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv2: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
    width: "100%",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
    maxWidth: "120px",
    minWidth: "115px",
  },
  dialog: {
    width: "300px",
  },
}));
