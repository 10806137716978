import React, { useState } from "react";
import { Button, Grid, CircularProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import { defaultThemeColor } from "../../constant/colors";
export default function Message(props) {
  const { open, handleClose, message } = props;
  const classes = useStyles();
  const intl = useIntl();

  const handleCloseDialog = () => {
    handleClose(false);
  };
  return (
    <div>
      <Dialog
        open={open}
        style={{
          borderRadius: "20px",
        }}
        className={classes.rounded}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          <p className={classes.tabText}>{message}</p>
          <Grid container justify="center">
            <Typography
              className={classes.errorText}
              //  style={{ maxWidth: "200px" }}
              style={{ marginBottom: "20px" }}
            >
              {/* {message} */}
            </Typography>
          </Grid>
          <Grid
            container
            className={classes.buttonDiv2}
            style={{ marginTop: "30px" }}
          >
            <Button
              className={classes.button}
              onClick={handleCloseDialog}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
            >
              {intl.formatMessage({
                id: "Ok",
              })}
            </Button>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  errorText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FF3366",
  },
  rounded: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "20px",
    },
  },
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 20px 0px",
  },

  button3: {
    width: " 100%",
    height: "40px",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#777777",
    minWidth: "250px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  button: {
    width: " 100%",
    height: "40px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    minWidth: "250px",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv2: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
    width: "100%",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    bottom: 13,
    maxWidth: "120px",
    minWidth: "115px",
  },
  dialog: {
    width: "300px",
  },
}));
