import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { createOrder, updateTabExpire } from "../../actions/order";

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { useHistory,useLocation } from "react-router-dom";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  tabText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "29px",
    textAlign: "center",
    color: "#000000",
    padding: "0px",
    margin: "5px 0px 10px 0px",
  },

  button: {
    width: " 100%",
    height: "100%",

    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    textTransform: "none",
  },
  buttonDiv: {
    paddingTop: "0rem",
    paddingBottom: "0.5rem",
    height: "50px",
    // width:"100%",
    paddingLeft: "0rem",
    paddingRight: "0rem",
    // position:"absolute",
    bottom: 13,
  },
  dialog: {
    width: "300px",
  },
}));

export default function TabExpireDialog(props) {
  const classes = useStyles();
  const history = useHistory();
  let dispatch = useDispatch();
  let menuReducer = useSelector((state) => state.menuReducer);
  let tabExpire = useSelector((state) => state.orderReducer.tabExpire);
  const intl = useIntl();
  let location = useLocation();

  const [expires, setExpires] = useState(false);
  useEffect(() => {
    if (
      window.location.pathname !== "/" 
      &&
      !new URLSearchParams(location.search).get("tab")
    ) {
      setExpires(localStorage.getItem("show-tab-expire")==="yes");
    } else {
      // setExpires(false);
      // localStorage.setItem("show-tab-expire","no")
    }
  }, [localStorage.getItem("show-tab-expire")]);
  const handleCloseYes = () => {
    localStorage.setItem("show-tab-expire","no")
    localStorage.setItem("tabId", "empty");
    localStorage.setItem("tabLink", "empty");
    dispatch(createOrder({ venue_id: menuReducer.menu?.uuid }));
    dispatch(updateTabExpire({ value: false }));
    localStorage.removeItem("orderId");
    if (
      localStorage.getItem("sessionId") !== null &&
      localStorage.getItem("sessionId") !== "null"
    ) {
      history.push(`/menu/?sessionid=${localStorage.getItem("sessionId")}`);
    } else {
      history.push(`/menu`);
    }

    history.push("/");
  };

  return (
    <div>
      <Dialog
        open={expires}
        // onClose={handleClose}
        style={{
          width: "100%",
          height: "500px",
          margin: "50px 0px 0px 0px",
          borderRadius: "20px",
        }}
      >
        <DialogContent>
          <p className={classes.tabText}>
            {intl.formatMessage({ id: "Your_Tab_has_expired" })}
          </p>
          <div className={classes.buttonDiv}>
            <Button
              className={classes.button}
              style={{
                background: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
              onClick={handleCloseYes}
            >
              {intl.formatMessage({ id: "Ok" })}
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
