const messages = {
  sign_in: "Είσοδος",
  staying_at_the_hotel: "Μένετε στο ξενοδοχείο?",
  view_menu: "Δείτε το Μενού",
  order_now: "Παραγγείλτε τώρα",
  room: "ΔΩΜΑΤΙΟ",
  table: "ΤΡΑΠΕΖΙ",
  password: "ΚΩΔΙΚΟΣ",
  open_or_join_tab: "Ανοίξτε ή μπείτε σε ένα ανοικτό τραπέζι",
  read_only_mode: "Μόνο για ανάγνωση",
  venue_currently_not_accepting_orders:
    "Το κατάστημα προσωρινά δε δέχεται παραγγελίες",
  share: "Μοιραστείτε",
  exit_tab: "Έξοδος",
  join_tab: "Mπείτε",
  open_tab: "Ανοίξτε",
  welcome: "Καλώς ήρθατε",
  join_an_existing_tab_or_open_a_new_one:
    "Ανοίξτε ή μπείτε σε υφιστάμενο λογαριασμό.",
  just_having_a_quick_drink_Select_Order_pay_now:
    "Είστε για ένα γρήγορο ποτό; Επιλέξτε Παραγγελία και Άμεση Πληρωμή",
  open_new_tab: "Ανοίξτε λογαριασμό",
  order_pay_now: "Παραγγελία & Άμεση Πληρωμή",
  Join_tab: "Υφιστάμενος λογαριασμός",
  you_do_not_have_any_unpaid_orders: "Δεν έχετε απλήρωτες παραγγελίες",
  Back: "Πίσω",
  noImg: "Χωρίς εικόνα",
  My_orders: "Οι παραγγελίες μου",
  Get_bill: "Ζητήστε λογαριασμό",
  Call_server: "Καλέστε σερβιτόρο",
  No_items_Available: "Δεν υπάρχουν διαθέσιμες επιλογές",
  Powered_by_Blend: "Powered by Blend",
  No_available_items: "Δεν υπάρχουν διαθέσιμες επιλογές",
  Add_Blend_Menu_To_Home_Screen: "Add the menu to your home screen",
  Url_Not_Found: "Η σελίδα δεν βρέθηκε",
  Enter: "Είσοδος",
  First_Name: "Όνομα",
  Table_Number: "Αριθμός τραπεζιού",
  Last_Name: "Επίθετο",
  Open_tab: "Ανοίξτε λογαριασμό",
  Enter_here: "",
  Cancel: "Ακύρωση",
  Join: "Είσοδος",
  Password: "Κωδικός",
  Table: "Τραπέζι",
  Home: "Αρχική",
  Error_404: "Σφάλμα 404",
  Page_Not_Found: "Η σελίδα δεν βρέθηκε",
  check_it_out: "Δείτε το",
  logo: "Λογότυπο",
  no_items_found: "Δεν βρέθηκαν προϊόντα",
  Choose_type: "Επιλέξτε",
  Required: "Απαιτούμενο",
  Not_more_than_1: "Όχι περισσότερο από ένα",
  Note: "Σχόλια",
  Leave_a_note: "Αφήστε ένα σχόλιο",
  Total: "Σύνολο",
  Update_order: "Ολοκλήρωση παραγγελίας",
  Add_to_order: "Προσθέστε στην παραγγελία",
  Add_items_to_order: "Προσθήκη στοιχείων σε νέα παραγγελία",
  Your_order: "Η παραγγελία σας",
  Room: "Δωμάτιο",
  Select: "Επιλογή",
  Number: "Αριθμός",
  Order_Note: "Σχόλια παραγγελίας",
  Leave_a_note_for_the_server: "Αφήστε ένα σχόλιο για τον σερβιτόρο",
  Payment_Status: "Τρόποι Πληρωμής",
  accepted: "αποδεκτό",
  only: "μόνο",
  Add_to_tab: "Προσθέστε στο λογαριασμό",
  Cash: "Μετρητά",
  Card: "Κάρτα",
  Pay_now: "Άμεση πληρωμή",
  Tip_Server: "Φιλοδώρημα",
  other: "Άλλο",
  Not_Now: "Όχι τώρα",
  Subtotal: "Υποσύνολο",
  Tip: "Φιλοδώρημα",
  Total: "Σύνολο",
  incl_VAT: "Συμπεριλαμβάνεται ΦΠΑ",
  Place_Order: "Ολοκλήρωση παραγγελίας",
  Order_Note: "Σχόλιο παραγγελίας",
  Subtotal: "Υποσύνολο",
  Amount_to_tip: "Φιλοδώρημα",
  Add_tip: "Προσθέστε φιλοδώρημα",
  Cancel: "Ακύρωση",
  Would_you_like_to_call_a: "Θέλετε να καλέσετε ",
  server: "σερβιτόρο",
  Yes: "Ναι",
  Ok: "ΟΚ",
  How_would_you_like: "Πως θέλετε να",
  to_pay: "πληρώσετε;",
  A_server_will_be_with: "Ένας σερβιτόρος θα είναι σύντομα ",
  you_shortly: "κοντά σας σύντομα",
  Staying_at_the_hotel: "Μένετε στο ξενοδοχείο;",
  Share_link: "Μοιραστείτε",
  Login: "Είσοδος",
  Blend_Menu: "Blend Menu",
  add_home_screen_message:
    "Add this app to your home screen for esay access and better experience",
  Tap: "Λογαριασμός",
  Add_to_Home_Screen: "then 'Add to Home Screen'",
  Checkout: "Ολοκλήρωση παραγγελίας",
  View_menu: "Δείτε το Μενού",
  Order: "Παραγγελία",
  Order_confirmed: "Η παραγγελία σας επιβεβαιώθηκε!",
  Order_confirm_Message: "Για να την δείτε πηγαίνετε πίσω στην αρχική οθόνη.",
  Order_declined: "Η παραγγελία σας απορρίφθηκε",
  Order_expired: "Η παραγγελία σας έληξε",
  Great: "Υπέροχα!",
  Order_expired_message:
    "Για περισσότερες πληροφορίες παρακαλώ επικοινωνήστε με τη διεύθυνση.",
  Order_decline_message:
    "Για περισσότερες πληροφορίες παρακαλώ επικοινωνήστε με τη διεύθυνση.",
  Order_decline_kitchen:
    "Φαίνεται πως η κουζίνα απέρριψε την παραγγελία σας για περισσότερες πληροφορίες παρακαλώ επικοινωνήστε με τη διεύθυνση",
  order_complete_message:
    "Η παραγγελία σας στάλθηκε σε αναμονή επιβεβαίωσης από το κατάστημα.",
  Order_number: "Αριθμός παραγγελίας",
  Pay: "Πληρωμή",
  card: "Kάρτα",
  payment_message: "Επιλέξτε τρόπο πληρωμής",
  How_would_you_like_to_pay: "Πως θέλετε να πληρώσετε;",
  My_Orders: "Οι παραγγελίες μου",
  Order: "Παραγγελία",
  Declined: "Απορρίφθηκε",
  Accepted: "Αποδεκτό",
  Not_Paid: "Απλήρωτη",
  Paid: "Πληρωμένη",
  No_Previous_Order: "Καμία προηγούμενη παραγγελία",
  Payment_failed: "Η πληρωμή απέτυχε",
  Are_you_sure_you: "Είστε σίγουρος πως ",
  want_to_exit: "θέλετε βγείτε από το λογαριασμό;",
  No: "Οχι",
  Your_Tab_has_expired: "Ο λογαριασμός σας έχει κλείσει",
  About: "Σχετικά",
  Add_item: "Προσθέστε",
  Error_404: "Σφάλμα 404",
  Page_Not_Found: "Η σελίδα δεν βρέθηκε",
  check_it_out: "Δείτε το",
  Search: "Αναζήτηση",
  Email_sent_Successfully: "Το email σας στάλθηκε επιτυχώς",
  Something_went_wrong_Try_Again: "Κάτι πήγε στραβά. Προσπαθήστε ξανά.",
  We_have_sent_you_an_email: "Σας στείλαμε ένα email με τον",
  with_your_session_link: "προσωπικό σας σύνδεσμο",
  Go_to_your_inbox: "Ε,ίσοδος στο γραμματοκιβώτιό σας",
  Send_again: "Στείλτε ξανά",
  Enter_your_table_number: "Aριθμός τραπεζιού ",
  View_menu_and_order: "Δείτε το μενού και παραγγείλτε",
  something_went_wrong: "Κάτι πήγε στραβά",
  Enter_email: "Πληκτρολογήστε το email σας",
  You_will_receive_your_access_link: "Θα λάβετε το σύνδεσμο πρόσβασης",
  Enter_email_used_during_check_in:
    "Πληκτρολογήστε το email που χρησιμοποιήσατε στο check-in σας",
  Enter_email_here: "Εmail",
  Invalid_Email: "Μη-αποδεκτό email",
  Field_is_required: "Αυτό το πεδίο απαιτείται",
  Send_Email: "Συνέχεια",
  Enter_your_table_number: "Aριθμός τραπεζιού ",
  Field_is_required: "Αυτό το πεδίο απαιτείται",
  View_menu_and_order: "Δείτε το μενού και παραγγείλτε",
  Table_number_or_password_match:
    "Ο αριθμός τραπεζιού ή ο κωδικός δεν ταιριάζουν",
  Missing_fields: "Λείπουν πεδία",
  your_card_details: "Τα στοιχεία της κάρτας σας",
  payment_information: "Πληροφορίες Πληρωμής",
  Or_enter_card_details: "Ή εισαγάγετε τα στοιχεία της κάρτας",
  Credit_Card_Number: "Aριθμός πιστωτικής κάρτας",
  Expiration_Date: "Ημερομηνία λήξης",
  CVC: "CVC",
  Email_Receipt_optional: "Απόδειξη με email (προαιρετικό)",
  Write_your_email: "Γράψτε το email σας",
  Email: "Email",
  Order_total: "Σύνολο παραγγελίας",
  continue: "Συνέχεια",
  not_staying_at_the_hotel: "Δεν μένω στο ξενοδοχείο",
  check_out_date: "Ημερομηνία αναχώρησης",
  enter_room_number: "Αριθμός δωματίου",
  room_number: "Αριθμός δωματίου",
  enter_surname: "Εισαγάγετε το επώνυμο",
  surname: "Επώνυμο",
  hotel_guest_login: "Σύνδεση επισκέπτη ξενοδοχείου",
  Charge_my_room: "Χρεώστε το δωμάτιο",
  enter_table_number: "Τραπέζι",
  select_table_number:"επιλέξτε αριθμό πίνακα",
  select_room_number:"επιλέξτε αριθμό δωματίου"
};

export default messages;
