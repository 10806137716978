import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import TabBar from "../Tab/TabBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  appBar: {
    bottom: "auto",
    top: 0,
    maxWidth: 530,
    width: "100%",
    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
    paddingLeft: "0px",
    height: "40px",
    background: "white",
    left: "unset",
    right: "unset",
  },

  //
  YourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
  },
  YourOrderCenter: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  toolbar: {
    paddingLeft: "0px",
    background: "white",
  },
  icon: {
    marginLeft: "10px",
    marginTop: "12px",
    fontSize: "18px",
    cursor: "pointer",
  },
}));
function OrderEditNavBar(props) {
  const classes = useStyles();
  const intl = useIntl();

  const history = useHistory();
  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);

  return (
    <div>
      <AppBar
        position="fixed"
        color="inherit"
        className={classes.appBar}
        style={{
          maxWidth: width ? width : "530px",
        }}
      >
        {localStorage.tabId && (
          <Grid item xs={12}>
            {" "}
            <TabBar />
          </Grid>
        )}

        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={1}>
              <ArrowBackIosIcon
                className={classes.icon}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={6}>
              {props.center
                ? props.text && (
                    <p className={classes.YourOrderCenter}>{props.text}</p>
                  )
                : props.text && (
                    <p className={classes.YourOrder}>{props.text}</p>
                  )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default OrderEditNavBar;
