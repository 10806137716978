import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const useStyles = makeStyles((theme) => ({
  appBar: {
    top: 0,
    bottom: "auto",
    maxWidth: 530,
    width: "100%",
    boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",

    right: "unset",
  },
  // appBar: {
  //   top: 0,
  //   bottom: "auto",
  //   width: "100%",
  //   boxShadow: "0 -19px 19px 2px rgba(0, 0, 0, 0.1)",
  //   paddingLeft: "0px",
  // },
  YourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#000000",
  },
  YourOrderCenter: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "7px",
  },
  toolbar: {
    paddingLeft: "0px",
  },
  icon: {
    marginLeft: "19px",
    marginTop: "12px",
    fontSize: "18px",
    cursor: "pointer",
  },
}));
function PreviousOrderBar(props) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);
  return (
    <div>
      <AppBar
        position="fixed"
        color="inherit"
        className={classes.appBar}
        style={{
          maxWidth: width ? width : "530px",
        }}
      >
        <Toolbar className={classes.toolbar}>
          <Grid container>
            <Grid item xs={2}>
              <ArrowBackIosIcon
                className={classes.icon}
                onClick={() => history.goBack()}
              />
            </Grid>
            <Grid item xs={9}>
              {props.text && (
                <p className={classes.YourOrderCenter}>{props.text}</p>
              )}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default PreviousOrderBar;
