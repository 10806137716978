import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Scrollspy from "react-scrollspy";
import SearchIcon from "@material-ui/icons/Search";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  chipInactive: {
    margin: theme.spacing(0.5),
    border: "0px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    padding: " 6px 12px",
    height: "30px",
    color: "#777777",
    backgroundColor: "transparent",
  },
}));

export default function GridMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  const [shouldScroll, setShouldScroll] = useState(false);
  const [ClickEvent, setClickEvent] = useState(null);
  const [TempChipValue, setTempChipValue] = useState(null);
  const [DoubleClickCheck, setDoubleClickCheck] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShouldScroll(true);
    }, 3000);
  }, []);

  const makeInViewport = function (elemId) {
    try{
    let bounding = document.getElementById(elemId).getBoundingClientRect();

    if (!(bounding.left <= window.innerWidth - 30)) {
      document.getElementsByClassName("menubar-ul")[0].scrollLeft =
        bounding.left + 70;
    }
    if (!(bounding.left >= 0)) {
      document.getElementsByClassName("menubar-ul")[0].scrollLeft = -100;
    }
  }catch(e){
    console.log(e)
  }
  };

  const scrollToCategory = (name) => {
    if (shouldScroll) {
      makeInViewport(name);
    }
  };

  return (
    <Grid container>
      {props?.menuLength === 1 && (
        <Grid
          item
          xs={2}
          style={{
            maxWidth: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SearchIcon
            onClick={() => history.push(`/search/?${window.location.search}`)}
            style={{ color: "#0C0E0F", cursor: "pointer" }}
          />
        </Grid>
      )}
      <Grid item xs={props?.menuLength === 1 ? 10 : 12}>
        <div style={props.style}>
          <Scrollspy
            id="checked"
            items={
              props?.menuList?.length > 0
                ? props.menuList.map((menu) => menu.name)
                : []
            }
            currentClassName={"chipActive"}
            scrolledPastClassName={"chipInactive"}
            className="menubar-ul"
            onUpdate={(element) => {
              if (element) {
                setDoubleClickCheck(false);
                scrollToCategory(`${element.id}_menu`);
                if (ClickEvent) {
                  window.scrollBy(0, -160);

                  setClickEvent(false);
                } else {
                  props.menuList.map((menu) => {
                    if (menu.name === element.id) {
                      setTempChipValue(`${element.id}_menu`);
                    }
                    return "";
                  });
                }
              }
            }}
            offset={-135}
          >
            {props.menuList.length > 0 &&
              props.menuList.map((item) => (
                <Chip
                  id={`${item.name}_menu`}
                  onClick={() => {
                    setClickEvent(true);
                    var result = `${item.name}_menu`.localeCompare(
                      TempChipValue
                    );

                    if (result === 0) {
                      setDoubleClickCheck(true);
                    } else {
                      setDoubleClickCheck(false);
                    }
                    setTempChipValue(`${item.name}_menu`);
                  }}
                  key={item.id}
                  ref={`${item.name}_menu`}
                  variant="outlined"
                  size="medium"
                  label={item.name}
                  component="a"
                  href={!DoubleClickCheck ? `#${item.name}` : null}
                  className={classes.chipInactive + " chipSpan"}
                  clickable={false}
                />
              ))}
          </Scrollspy>
        </div>
      </Grid>
    </Grid>
  );
}
