import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import OrderEditNavBar from "../Order/OrderEditNavBar";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import { TextField, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { useHistory } from "react-router-dom";
import TipDialogue from "./TipModel";
import ClearIcon from "@material-ui/icons/Clear";
import { checkout, removeOrder } from "../../actions/order";
import { useSelector, useDispatch } from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "axios";
import PlaceOrderButton from "./PlaceOrderButton";
import { defaultThemeColor } from "../../constant/colors";
import { ROOM_SERVICE } from "../../utils/constants";
import { useTheme } from "@material-ui/styles";
import { useIntl } from "react-intl";

const useResize = (myRef) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [reachedBottom, setReachedBottom] = useState(false);
  useEffect(() => {
    let dataHeight =
      document.getElementById("open-cart") &&
      document.getElementById("open-cart").offsetHeight;

    if (dataHeight < window.innerHeight) {
      setReachedBottom(true);
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      if (myRef.current) {
        const { scrollHeight } = myRef.current;
        if (window.innerHeight + window.scrollY > scrollHeight) {
          setReachedBottom(true);
        }
      }
      setWidth(myRef?.current?.offsetWidth);
      setHeight(myRef?.current?.offsetHeight);
    };
    window.addEventListener("scroll", handleResize);
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
      window.addEventListener("scroll", handleResize);
    };
  }, [myRef]);

  return { width, height, reachedBottom };
};

const useStyles = makeStyles((theme) => ({
  fieldtext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    "& .MuiInputBase-input": {
      fontSize: "14px",
    },
  },
  buttonGroupActive: {
    padding: "1rem 0rem !important",
    fontFamily: "Open Sans !important",
    fontStyle: "normal !important",
    fontWeight: " 500 !important",
    fontSize: "13px !important",
    boxSizing: "border-box",
    borderRadius: "5px 0px 0px 5px",
  },
  buttonGroup: {
    boxSizing: "border-box",
    padding: "1rem 0rem",
    fontFamily: theme.fontFamily,
    fontStyle: "normal !important",
    fontWeight: "600 !important",
    fontSize: "13px !important",
  },
  GenralText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",

    color: "#000000",
  },
  ordercount: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",

    display: "inline",
    // marginRight: "5px",
  },
  header: {
    paddingTop: "40px",
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",
  },
  Gridlist: {
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
  },
  list: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "28px",
    color: "#777777",
    // marginLeft: "1rem",
    marginTop: "0.8rem",
    marginBottom: "0.8rem",
  },
  listitem: {
    margin: 0,
  },
  totalorder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    margin: "0",
  },
  incl: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    margin: "0px",
  },
  price: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000000",
    margin: "0",
  },
  totalPrice: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000000",
    margin: "0",
  },

  button: {
    width: " 100%",
    height: "100%",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    minHeight: "54px",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    paddingTop: "51px",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "1.5rem",
    paddingRight: "1.6rem",
    bottom: 13,
  },
  cashcard: {
    paddingLeft: "1.5rem",
    paddingRight: "1.5rem",

    textAlign: "center",
  },
}));

function CartOpen(props) {
  const theme = useTheme();
  const innerRef = useRef();
  const intl = useIntl();

  const { reachedBottom } = useResize(innerRef);
  const [Loading, setLoading] = useState(true);
  const [cardSelected, setCardSelected] = useState(null);

  const [isSelected, setIsSelected] = useState(2);
  let dispatch = useDispatch();
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [subTotal, setSubtotal] = useState(0);
  const [orderNote, setOrderNote] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  // eslint-disable-next-line
  const [openTableList, setOpenTableList] = useState(true);
  const [OpenList, setOpenList] = useState(true);
  const [loader, setLoader] = useState(false);

  const [tableListView, settableListView] = useState([]);

  const [total, setTotal] = useState(0);
  const [tipAmount, setTipAmount] = useState(0);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const orderReducer = useSelector((state) => state.orderReducer);

  const { menu } = useSelector((state) => state.menuReducer);
  useEffect(() => {
    setLoading(false);
  }, [orderReducer.orderCount]);

  useEffect(() => {
    if (orderReducer.orderList.length) {
      const tempSubTotal = orderReducer.orderList.reduce(
        (a, b) => a + (b["itemOrderPrice"] || 0),
        0
      );

      setSubtotal(parseFloat(tempSubTotal));
      setTotal(parseFloat(tempSubTotal) + parseFloat(tipAmount));
    } else {
      history.goBack();
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderList, tipAmount]);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (orderReducer?.updated) {
      history.push("/order");
      dispatch({ type: "SET_UPDATED", payload: false });
    }
    // eslint-disable-next-line
  }, [orderReducer?.updated]);
  useEffect(() => {
    if (menu && menu.accept_card) {
      if (
        menu?.stripe_account_ref !== null &&
        menu?.stripe_account_ref !== undefined &&
        menu?.stripe_account_ref !== ""
      ) {
        setPaymentMethod("card");
      } else {
        setPaymentMethod("cash");
      }
    } else if (menu && menu.accept_cash) {
      setPaymentMethod("cash");
    }

    setLoader(true);
    let url =
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host;
    axios
      .get(
        `${url}${
          localStorage.tabId && localStorage.tabId === "empty"
            ? "/tables/list-all/"
            : "/tables/list/"
        }`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        dispatch({ type: "TABLE_LIST", payload: result.data });

        let temp = tableListView;
        result.data &&
          result.data.map((item) => (temp = [...temp, { id: `${item}` }]));
        settableListView(temp);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
    // eslint-disable-next-line
  }, [menu]);

  useEffect(() => {
    if (paymentMethod === "card") {
      if (isSelected === 2) {
        setTipAmount(1);
      } else if (isSelected === 3) {
        setTipAmount(2);
      } else if (isSelected === 4) {
        // setIsSelected(2);
        setTipAmount(1);
      } else if (isSelected === 1) {
        setTipAmount(0);
      }
    } else {
      setTipAmount(0);
    }
    // eslint-disable-next-line
  }, [paymentMethod, isSelected]);

  useEffect(() => {
    setTotal(
      parseFloat(subTotal) + (parseFloat(tipAmount) ? parseFloat(tipAmount) : 0)
    );
  }, [tipAmount, subTotal]);
  useEffect(() => {
    if (
      orderReducer.orderDetail &&
      (orderReducer.orderDetail.table_number ||
        orderReducer.orderDetail.note ||
        orderReducer.orderDetail.tip_amount)
    ) {
      setTableNumber(orderReducer.orderDetail.table_number);
    }
    // eslint-disable-next-line
  }, [orderReducer?.orderDetail]);
  useEffect(() => {
    let tableNumber = localStorage.getItem("table_number");

    if (tableNumber && tableNumber !== "empty") {
      setTableNumber(localStorage.getItem("table_number"));
      setOpenList(false);
    }
  }, []);
  useEffect(() => {
    if (
      menu?.stripe_account_ref !== null &&
      menu?.stripe_account_ref !== undefined &&
      menu?.stripe_account_ref !== "" &&
      localStorage.tabId &&
      localStorage.tabId === "empty"
    ) {
      setPaymentMethod("card");
    } else {
      setPaymentMethod("cash");
    }
    if (menu && menu.accept_card) {
      if (localStorage.tabId && localStorage.tabId !== "empty") {
        setPaymentMethod("cash");
      } else {
        if (
          menu?.stripe_account_ref !== null &&
          menu?.stripe_account_ref !== undefined &&
          menu?.stripe_account_ref !== ""
        ) {
          setPaymentMethod("card");
        } else {
          setPaymentMethod("cash");
        }
      }
    } else if (menu && menu.accept_cash) {
      setPaymentMethod("cash");
    }
  }, [menu]);

  useEffect(() => {
    if (!menu || !menu.uuid) {
      history.push("/");
    }
    // eslint-disable-next-line
  }, [menu]);
  const handleSubmit = () => {
    if (reachedBottom) {
      paymentMethod === "card"
        ? history.push({
            pathname: "/checkout",
            state: {
              total: total,
              currency: menu ? menu.currency : "usd",
              table_number: tableNumber,
              tip: tipAmount,
              orderNote,
              orderID: orderReducer.orderId,
            },
          })
        : dispatch(
            checkout({
              note: orderNote,
              tip_amount: tipAmount,
              table_number: tableNumber,
              payment_method: "cash",
              orderID: orderReducer.orderId,
            })
          );
    } else {
      document
        .getElementById("open-cart")
        .scrollIntoView({ behavior: "smooth", block: "end" });
    }
  };
  const getPriceDescription = (item) => {
    let name = "";
    if (item?.prices?.length > 1) {
      item.prices.forEach((item1) => {
        if (item1.id == item.price_id) {
          name = "(" + item1.description + ")";
        }
      });
    }
    return name;
  };
  return (
    <div ref={innerRef} id="open-cart">
      <OrderEditNavBar
        text={intl.formatMessage({ id: "Your_order" })}
        center={true}
      />
      <div
        className={classes.header}
        style={{
          marginTop:
            localStorage.tabId &&
            localStorage.tabId !== "empty" &&
            localStorage.tabId &&
            (localStorage.tabId !== "empty") !== "empty"
              ? "70px"
              : "40px",
        }}
      >
        <p className={classes.GenralText}>
          {menu?.type === ROOM_SERVICE
            ? intl.formatMessage({ id: "Room" })
            : intl.formatMessage({ id: "Table" })}
        </p>

        <Autocomplete
          id="combo-box-demo"
          options={tableListView}
          loading={loader}
          disabled={
            localStorage.tabId && localStorage.tabId !== "empty" ? true : false
          }
          open={
            localStorage.tabId && localStorage.tabId !== "empty"
              ? false
              : OpenList
          }
          getOptionLabel={(option) => option.id}
          value={{
            id:
              localStorage.tabId && localStorage.tabId !== "empty"
                ? orderReducer.orderDetail.table_number
                : tableNumber,
          }}
          onChange={(event, newValue) => {
            setOpenTableList(newValue);
            setTableNumber(newValue && newValue.id);
            setOpenList(false);
            localStorage.setItem("table_number", "empty");
          }}
          style={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`${
                menu?.type === ROOM_SERVICE
                  ? intl.formatMessage({ id: "select_room_number" })
                  : intl.formatMessage({ id: "select_table_number" })
              } `}
              onClick={() => setOpenList(true)}
              variant="outlined"
            />
          )}
        />

        <Divider />
      </div>
      <div className={classes.Gridlist}>
        {orderReducer &&
        orderReducer.orderList &&
        orderReducer.orderList.length > 0 ? (
          orderReducer.orderList.map((item, index) => (
            <div key={item.item_id}>
              <div className={"detailHeader"}>
                <Grid container justify="space-between">
                  <Grid item style={{ marginTop: "3px" }}>
                    <Typography
                      className={classes.ordercount}
                      style={{
                        color: localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor,
                      }}
                    >
                      x{item.quantity}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    xs={8}
                    style={{ marginTop: "3px" }}
                    onClick={() => {
                      history.push({
                        pathname: "/addcart",
                        state: {
                          dish: item,
                          currency: menu && menu.currency,
                          edit: true,
                          index: index,
                          price_id: item?.price_id,
                        },
                      });
                      dispatch({
                        type: "SET_SELECTED_ORDER",
                        payload: {
                          ...item,
                          currency: menu?.currency ? menu.currency : "usd",
                          edit: true,
                          index: index,
                        },
                      });
                    }}
                  >
                    <Typography
                      className={classes.GenralText}
                      style={{ display: "inline" }}
                    >
                      {item.name}
                      {item.price_id !== null &&
                        item.price_id !== undefined &&
                        item.price_id !== "" &&
                        getPriceDescription(item)}
                    </Typography>
                    <div className={classes.list}>
                      {item &&
                        item.options &&
                        item.options.map((item_option) => {
                          let name = null;
                          item.item_customisations.map((coms) =>
                            coms.customisation_options.map((option_item) => {
                              if (
                                option_item.customisation_option_id ===
                                parseInt(item_option)
                              ) {
                                name = option_item.name;
                                if (option_item.price) {
                                  name =
                                    name +
                                    " (" +
                                    parseFloat(
                                      option_item.price
                                    ).toLocaleString("en-US", {
                                      style: "currency",
                                      currency: menu ? menu.currency : "usd",
                                    }) +
                                    ")";
                                }
                              }
                              return null;
                            })
                          );
                          return (
                            <p
                              className={classes.listitem}
                              style={{ marginTop: "3px" }}
                              key={item_option.customisation_option_id}
                            >
                              {name}
                            </p>
                          );
                        })}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={2}
                    style={{ marginTop: "3px" }}
                    onClick={() => {
                      if (
                        item &&
                        item.item_customisations &&
                        item.item_customisations.length > 0
                      ) {
                        history.push({
                          pathname: "/addcart",
                          state: {
                            dish: item,
                            currency: menu && menu.currency,
                            edit: true,
                            index: index,
                          },
                        });
                        dispatch({
                          type: "SET_SELECTED_ORDER",
                          payload: {
                            ...item,
                            currency: menu.currency,
                            edit: true,
                            index: index,
                          },
                        });
                      }
                    }}
                  >
                    {" "}
                    <Typography
                      className={classes.GenralText}
                      style={{ display: "inline", marginTop: "3px" }}
                    >
                      {item.price_id !== null &&
                      item.price_id !== undefined &&
                      item.price_id !== ""
                        ? item.prices.map((item1) => {
                            return (
                              <>
                                {item1.id == item.price_id &&
                                  parseFloat(
                                    parseFloat(item1.amount)
                                  ).toLocaleString("en-US", {
                                    style: "currency",
                                    currency: menu ? menu.currency : "usd",
                                  })}
                              </>
                            );
                          })
                        : parseFloat(parseFloat(item.price)).toLocaleString(
                            "en-US",
                            {
                              style: "currency",
                              currency: menu ? menu.currency : "usd",
                            }
                          )}
                    </Typography>
                  </Grid>

                  <Grid item xs={1} style={{ cursor: "pointer" }}>
                    {Loading && item.order_item_id === cardSelected ? (
                      <Grid item xs={12}>
                        <CircularProgress
                          style={{
                            color: localStorage.getItem("color")
                              ? localStorage.getItem("color")
                              : defaultThemeColor,
                          }}
                          size={20}
                        />
                      </Grid>
                    ) : (
                      <ClearIcon
                        onClick={() => {
                          if (Loading === false) {
                            setLoading(true);
                            setCardSelected(item.order_item_id);

                            dispatch(
                              removeOrder({
                                order_uuid: orderReducer.orderId,
                                order_item_id: item.order_item_id,
                                orderItem: item,
                              })
                            );
                          }
                        }}
                        style={{ marginLeft: "4px" }}
                      />
                    )}
                  </Grid>
                </Grid>
              </div>
              {item &&
                item.item_customisations &&
                item.item_customisations.length > 0 &&
                item.note && (
                  <TextField
                    inputProps={{ style: { backgroundColor: "#F2F2F2" } }}
                    variant="outlined"
                    value={item.note}
                    disabled
                    className={classes.fieldtext}
                    style={{ paddingBottom: 20, color: "#F2F2F2" }}
                    fullWidth
                  />
                )}
              <Divider />{" "}
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
      <div className={classes.header}>
        <p
          className={classes.GenralText}
          style={{ margin: "0px 0px 10px 0px" }}
        >
          {intl.formatMessage({ id: "Order_Note" })}
        </p>
        <TextField
          variant="outlined"
          placeholder={intl.formatMessage({
            id: "Leave_a_note_for_the_server",
          })}
          className={classes.fieldtext}
          // style={{ paddingBottom: 10 }}
          value={orderNote}
          onChange={(e) => setOrderNote(e.target.value)}
          fullWidth
        />

        {menu?.accept_card === true &&
          localStorage.tabId &&
          localStorage.tabId !== "empty" && (
            <p
              className={classes.GenralText}
              style={{ margin: "40px 0px 20px 0px" }}
            >
              {intl.formatMessage({
                id: "Payment_Status",
              })}

              {menu && (!menu.accept_card || !menu.accept_cash) ? (
                <span style={{ fontWeight: "normal" }}>
                  ({" "}
                  {intl.formatMessage({
                    id: "only",
                  })}{" "}
                  {menu && !menu.accept_cash && "card"}{" "}
                  {menu && !menu.accept_card && "cash"}{" "}
                  {intl.formatMessage({
                    id: "accepted",
                  })}
                  )
                </span>
              ) : (
                ""
              )}
            </p>
          )}
      </div>
      {menu?.accept_card === true &&
        localStorage.tabId &&
        localStorage.tabId !== "empty" && (
          <div className={classes.cashcard}>
            <ButtonGroup size="large" className="" style={{ width: "100%" }}>
              <Button
                disabled={menu?.accept_cash ? false : true}
                onClick={() => setPaymentMethod("cash")}
                className={
                  paymentMethod === "cash"
                    ? classes.buttonGroupActive
                    : classes.buttonGroup
                }
                style={{
                  width: "100%",
                  color: paymentMethod === "cash" ? "#fff" : "#777777",
                  backgroundColor:
                    paymentMethod === "cash"
                      ? localStorage.getItem("color")
                        ? localStorage.getItem("color")
                        : defaultThemeColor
                      : "#fff",

                  border: `1px solid ${
                    localStorage.getItem("color")
                      ? localStorage.getItem("color")
                      : defaultThemeColor
                  }`,
                }}
              >
                {localStorage.tabId && localStorage.tabId !== "empty"
                  ? intl.formatMessage({
                      id: "Add_to_tab",
                    })
                  : intl.formatMessage({
                      id: "Cash",
                    })}
              </Button>
              <Button
                disabled={menu && menu.accept_card ? false : true}
                onClick={() => setPaymentMethod("card")}
                className={
                  paymentMethod === "card"
                    ? classes.buttonGroupActive
                    : classes.buttonGroup
                }
                style={{
                  width: "100%",
                  color: paymentMethod === "card" ? "#fff" : "#777777",
                  borderRadius:
                    paymentMethod === "card" ? "0px 5px 5px 0px" : "",
                  backgroundColor:
                    paymentMethod === "card"
                      ? localStorage.getItem("color")
                        ? localStorage.getItem("color")
                        : defaultThemeColor
                      : "#fff",

                  border: `1px solid ${
                    localStorage.getItem("color")
                      ? localStorage.getItem("color")
                      : defaultThemeColor
                  }`,
                }}
              >
                {localStorage.tabId && localStorage.tabId !== "empty"
                  ? intl.formatMessage({
                      id: "Pay_now",
                    })
                  : intl.formatMessage({
                      id: "Card",
                    })}
              </Button>
            </ButtonGroup>
          </div>
        )}
      {paymentMethod === "card" && (
        <>
          <p
            className={classes.GenralText}
            style={{ margin: "40px 0px 20px 0px", padding: "0rem 1.5rem" }}
          >
            {intl.formatMessage({
              id: "Tip_Server",
            })}
            ?
          </p>
          <div style={{ padding: "0rem 1.5rem" }}>
            <Grid container justify="flex-start" style={{}}>
              <Grid item>
                <div
                  style={{
                    background:
                      isSelected === 2
                        ? localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor
                        : "#F2F2F2",
                    color: isSelected === 2 ? "#FFFFFF" : "#777777",
                    borderRadius: "5px",
                    padding: "0.8rem 0.4rem",
                    textAlign: "center",
                    margin: "0px 5px 5px 0px",
                    fontFamily: theme?.fontFamily,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    width: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSelected(2);
                    setTipAmount(1.0);
                  }}
                >
                  {parseFloat(parseFloat(1.0)).toLocaleString("en-US", {
                    style: "currency",
                    currency: menu ? menu.currency : "usd",
                  })}
                </div>{" "}
              </Grid>
              <Grid item>
                {" "}
                <div
                  style={{
                    background:
                      isSelected === 3
                        ? localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor
                        : "#F2F2F2",
                    color: isSelected === 3 ? "#FFFFFF" : "#777777",
                    borderRadius: "5px",
                    padding: "0.8rem 0.4rem",
                    textAlign: "center",
                    margin: "0px 5px 5px 0px",
                    fontFamily: theme?.fontFamily,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    width: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSelected(3);
                    setTipAmount(2.0);
                  }}
                >
                  {parseFloat(parseFloat(2.0)).toLocaleString("en-US", {
                    style: "currency",
                    currency: menu ? menu.currency : "usd",
                  })}
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    background:
                      isSelected === 4
                        ? localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor
                        : "#F2F2F2",
                    color: isSelected === 4 ? "#FFFFFF" : "#777777",
                    borderRadius: "5px",
                    padding: "0.8rem 0.4rem",
                    textAlign: "center",
                    margin: "0px 5px 5px 0px",
                    fontFamily: theme?.fontFamily,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    width: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSelected(4);
                    setOpen(true);
                  }}
                >
                  {intl.formatMessage({
                    id: "other",
                  })}
                </div>{" "}
              </Grid>
              <Grid item>
                <div
                  style={{
                    background:
                      isSelected === 1
                        ? localStorage.getItem("color")
                          ? localStorage.getItem("color")
                          : defaultThemeColor
                        : "#F2F2F2",
                    color: isSelected === 1 ? "#FFFFFF" : "#777777",
                    borderRadius: "5px",
                    padding: "0.8rem 0.4rem",
                    textAlign: "center",
                    margin: "0px 5px 5px 0px",
                    fontFamily: theme?.fontFamily,
                    fontStyle: "normal",
                    fontWeight: "600",
                    fontSize: "12px",
                    lineHeight: "18px",
                    width: "60px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setIsSelected(1);
                    setTipAmount(0);
                  }}
                >
                  {intl.formatMessage({
                    id: "Not_Now",
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
      {(menu?.accept_card === true &&
        localStorage.tabId &&
        localStorage.tabId === "empty") ||
      (paymentMethod === "card" && menu?.accept_card === true) ? (
        <Grid
          container
          spacing={0}
          style={{ padding: "0px 20px 16px 22px ", marginTop: "52px" }}
        >
          <Grid item xs={6}>
            <Grid>
              <p className={classes.totalorder}>
                {intl.formatMessage({
                  id: "Subtotal",
                })}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <p className={classes.price}>
              {parseFloat(parseFloat(subTotal)).toLocaleString("en-US", {
                style: "currency",
                currency: menu ? menu.currency : "usd",
              })}
            </p>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      {(menu?.accept_card === true &&
        localStorage.tabId &&
        localStorage.tabId === "empty") ||
      (paymentMethod === "card" && menu?.accept_card === true) ? (
        <Grid container spacing={0} style={{ padding: "16px 20px 16px 22px " }}>
          <Grid item xs={6}>
            <Grid>
              <p className={classes.totalorder}>
                {intl.formatMessage({
                  id: "Tip",
                })}
              </p>
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "end" }}>
            <p className={classes.price}>
              {parseFloat(parseFloat(tipAmount ? tipAmount : 0)).toLocaleString(
                "en-US",
                {
                  style: "currency",
                  currency: menu ? menu.currency : "usd",
                }
              )}
            </p>
          </Grid>
        </Grid>
      ) : (
        <></>
      )}
      <Grid
        container
        spacing={0}
        style={{
          padding:
            (menu?.accept_card === true &&
              localStorage.tabId &&
              localStorage.tabId === "empty") ||
            (paymentMethod === "card" && menu?.accept_card === true)
              ? "6px 20px 0px 22px "
              : "40px 1.5rem 0px 1.5rem ",
        }}
      >
        <Grid item xs={6}>
          <Grid>
            <p className={classes.totalorder}>
              {intl.formatMessage({
                id: "Total",
              })}
            </p>
          </Grid>
          {(menu?.accept_card === true &&
            localStorage.tabId &&
            localStorage.tabId === "empty") ||
          (paymentMethod === "card" && menu?.accept_card === true) ? (
            <Grid>
              <p className={classes.incl}>
                (
                {intl.formatMessage({
                  id: "incl_VAT",
                })}
                ){" "}
              </p>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={6} style={{ textAlign: "end" }}>
          <p className={classes.totalPrice}>
            {parseFloat(parseFloat(total)).toLocaleString("en-US", {
              style: "currency",
              currency: menu ? menu.currency : "usd",
            })}
          </p>
        </Grid>
      </Grid>
      <div className={classes.buttonDiv}></div>
      <TipDialogue
        tipAmount={tipAmount}
        setTipAmount={setTipAmount}
        open={open}
        setOpen={setOpen}
      />
      <PlaceOrderButton
        reachedBottom={reachedBottom}
        style={
          orderReducer.loader || !tableNumber || !reachedBottom
            ? // ||(!reachedBottom&&dataHeight>window.innerHeight)
              { backgroundColor: "#AEAEAE !important" }
            : {
                backgroundColor: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }
        }
        orderClass={
          orderReducer.loader || !tableNumber || !reachedBottom
            ? // ||(!reachedBottom&&dataHeight>window.innerHeight)
              classes.buttonDisabled
            : classes.button
        }
        handleSubmit={handleSubmit}
        loader={orderReducer?.loader}
        width={props?.width}
        edit={orderReducer?.selectedOrder?.edit}
      />
    </div>
  );
}

export default CartOpen;
