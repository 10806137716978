import React, { useEffect, useState } from "react";
import { Button, Grid, Typography, CircularProgress } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  createOpenTabWithSession,
  getTableAllList,
  checkForExistingTab,
} from "../../actions/order";
import { getVenueDetails } from "../../actions/Menu";
import { useHistory, useLocation } from "react-router-dom";
import splashLogoPic from "../../assets/images/splashLogoPic.png";
import splashLogoWrite from "../../assets/images/splashLogoWrite.png";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../../assets/images/no-image-found.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import FormHelperText from "@material-ui/core/FormHelperText";
import { useIntl } from "react-intl";
import { getCookie } from "../../utils/common";
import { ROOM_SERVICE } from "../../utils/constants";
import LanguageDropDown from "../../components/SelectLanguage/Select";

const SelectTable = (props) => {
  const classes = useStyles();
  const intl = useIntl();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [height] = useState(window.textareaheight);
  // eslint-disable-next-line
  const [error, setError] = useState({ tableNumber: false, roomNumber: false });
  const [loading, setLoading] = useState(null);
  const [loadingTab, setLoadingTab] = useState(false);
  const [roomNumber, setRoomNumber] = useState("");
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  let menuReducer = useSelector((state) => state.menuReducer?.menu);
  const [tableListView, settableListView] = useState([]);
  let tableList = useSelector((state) => state?.menuReducer?.tableList);

  const [errorMessage, setErrorMessage] = useState("");
  const [tableNumber, setTableNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleSwitchPage = async (path) => {
    if (tableNumber === "" || tableNumber === null) {
      // if (roomNumber === "") {
      //   setError({ ...error, roomNumber: true });
      // }
      if (tableNumber === "" || tableNumber === null) {
        setError({ ...error, tableNumber: true });
      }
    } else {
      setLoadingTab(true);
      dispatch(
        createOpenTabWithSession({
          venue_uuid: menuReducer?.uuid,
          table_number: tableNumber,
          first_name: roomNumber ? roomNumber : tableNumber,
        })
      )
        .then((res) => {
          setLoadingTab(false);
          if (res?.status === 200) {
            let sessionCookies = getCookie(
              "session",
              menuReducer?.menu?.business_account?.domain
            );

            let session2 =
              sessionCookies ||
              new URLSearchParams(location.search).get("sessionid");
            if (session2) {
              history.push(`/menu/?sessionid=${session2}`);
            } else {
              history.push(`/menu/`);
            }
          } else {
            setErrorMessage("Something went wrong");
          }
        })
        .catch((e) => {
          setLoadingTab(false);
        });
    }
  };
  const handleChangePage = () => {
    history.goBack();
  };
  const handleChange = (event) => {
    setTableNumber(event.target.value);
    setError(false);
  };
  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage("");
      }, 8000);
    }
  }, [errorMessage]);
  useEffect(() => {
    let sessionCookies = getCookie(
      "session",
      menuReducer?.menu?.business_account?.domain
    );

    let room_no = new URLSearchParams(location.search).get("room_no");
    let session2 =
      sessionCookies || new URLSearchParams(location.search).get("sessionid");
    localStorage.setItem("sessionId", session2);

    if (room_no) {
      setRoomNumber(room_no);
      localStorage.setItem("room_no", room_no);
    }
    getData();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let sessionCookies = getCookie(
      "session",
      menuReducer?.menu?.business_account?.domain
    );

    let room_no = new URLSearchParams(location.search).get("room_no");
    let session2 =
      sessionCookies || new URLSearchParams(location.search).get("sessionid");
    localStorage.setItem("sessionId", session2);

    if (room_no) {
      setRoomNumber(room_no);
      localStorage.setItem("room_no", room_no);
    }
    getData();
    // eslint-disable-next-line
  }, [menuReducer]);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }

    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, [tableList]);

  const getData = async () => {
    const url =
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host;
    // eslint-disable-next-line
    setLoading(true);
    let id = menuReducer?.uuid;
    if (!menuReducer?.uuid) {
      let res = await dispatch(getVenueDetails(url + "/d/"));
      id = res?.data?.uuid;
    }
    // eslint-disable-next-line
    let res1 = await dispatch(getTableAllList(url + "/tables/list/"));
    getTabDetails(id);
  };
  const getTabDetails = async (uuid) => {
    let sessionCookies = getCookie(
      "session",
      menuReducer?.menu?.business_account?.domain
    );

    let session_id =
      sessionCookies || new URLSearchParams(location.search).get("sessionid");
    if (uuid) {
      setLoading(true);

      let res = await dispatch(
        checkForExistingTab({
          venue_uuid: uuid,
          sessionId: session_id,
        })
      );
      // only join edisting tab if its not expired or deleted
      if (res?.data?.data?.tab_link && res?.data?.data?.status !== 2) {
        window.open(`${res?.data?.data?.tab_link}`, "_top");
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (
      menuReducer?.menu?.read_only_menu === true ||
      menuReducer?.menu?.is_currently_accepting_orders === false
    ) {
      history.push(`/menu`);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu]);

  const handleCancel = () => {
    if (localStorage.getItem("from") === "menu") {
      localStorage.removeItem("from");
      history.push("/menu");
    } else {
      history.push("/");
    }
  };
  const handleChangePageBackBusiness = () => {
    if (
      menuReducer?.business_account?.domain &&
      localStorage.getItem("sessionId") !== "null" &&
      localStorage.getItem("sessionId") !== null
    ) {
      window.open(
        `https://${
          menuReducer?.business_account?.domain
        }/?session=${localStorage.getItem("sessionId")}`,
        "_top"
      );
    } else if (menuReducer?.business_account?.domain) {
      window.open(`https://${menuReducer?.business_account?.domain}`, "_top");
    }
  };

  useEffect(() => {
    let table_Number = new URLSearchParams(location.search).get("table_number");
    if (table_Number) {
      setTableNumber(table_Number);
      localStorage.setItem("table_number", table_Number);
      handleCreateSessionWithTableNumber(table_Number);
    }
    // eslint-disable-next-line
  }, []);
  const handleSwitchPageWithTableNumber = async (tNumber) => {
    setLoading(true);
    dispatch(
      createOpenTabWithSession({
        venue_uuid: menuReducer.menu?.uuid,
        table_number: tNumber,
        first_name: tNumber,
      })
    ).then((res) => {
      if (res?.status === 200) {
        let url = res?.tab_Link;
        if (localStorage.getItem("sessionId") !== null) {
          window.open(
            url + `&sessionid=${localStorage.getItem("sessionId")}`,
            "_top"
          );
        } else {
          window.open(url, "_top");
        }
      }
    });
    setLoading(false);
  };
  const handleCreateSessionWithTableNumber = (tNumber) => {
    if (tNumber === "" || tNumber === undefined) {
    } else {
      let sessionCookies = getCookie(
        "session",
        menuReducer?.menu?.business_account?.domain
      );
      let session2 =
        sessionCookies || new URLSearchParams(location.search).get("session");
      let session = localStorage.getItem("sessionId");

      if (
        session2 ||
        (session !== undefined && session !== null && session !== "null")
      ) {
        handleSwitchPageWithTableNumber(tNumber);
      } else {
        history.push(`/login/?table_number=${tNumber}&sessionid=${session2}`);
      }
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Grid
            container
            justify="center"
            style={{
              backgroundColor: "#FFFFFF",
              height: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              classes={{
                root: "circularProgress",
              }}
              size={40}
            />
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#FFFFFF",
              height: "70vh",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30%",
              }}
            >
              <img src={splashLogoPic} width={"130px"} alt=""></img>
              <img
                src={splashLogoWrite}
                width={"130px"}
                style={{ paddingTop: "20px" }}
                alt=""
              ></img>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          direction="column"
          style={{
            height: height,
            minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
              ? "90vh"
              : "85vh",
            display: "flex",
            overflow: "hidden !important",
          }}
        >
          {/* <TabBar /> */}
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",

              height: height,
              minHeight: window?.matchMedia("(display-mode: standalone)")
                ?.matches
                ? "90vh"
                : "85vh",
              overflow: "hidden !important",
            }}
          >
            <Grid container justify="flex-end">
              <Grid item style={{ padding: "16px" }}>
                <LanguageDropDown />
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginTop: "4%",
              }}
            >
              <Grid item xs={2}>
                {
                  <ArrowBackIosIcon
                    style={{ paddingLeft: "20px", cursor: "pointer" }}
                    onClick={handleChangePageBackBusiness}
                  />
                }
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant={"circle"}
                    width={"124px"}
                    height={"124px"}
                  />
                ) : null}
                <img
                  onLoad={() => {
                    setIsLoading(false);
                  }}
                  title={props.title}
                  alt={props.title}
                  onError={(err) => {
                    setIsLoading(false);
                    setIsError(true);
                  }}
                  src={isError ? noimage : menuReducer?.logo}
                  className={classes.logoContaier}
                  style={{ display: isLoading ? "none" : "block" }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginTop: "25%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography className={classes.header}>
                  {menuReducer?.type !== ROOM_SERVICE
                    ? intl.formatMessage({
                        id: "Enter_your_table_number",
                      })
                    : intl.formatMessage({ id: "Enter_your_room_number" })}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  error={error?.tableNumber}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tableNumber}
                    className={classes.selectTable}
                    onChange={handleChange}
                  >
                    {tableListView.map((item) => (
                      <MenuItem value={item?.id}>{item?.id}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    style={{
                      marginLeft: "0px !important",
                      marginRight: "0px !important",
                    }}
                    className={classes.errorMessage}
                  >
                    {error?.tableNumber &&
                      intl.formatMessage({
                        id: "Field_is_required",
                      })}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginBottom: "6%",
                marginTop: "15%",
              }}
            >
              <Grid item xs={12} className={classes.buttonDiv}>
                {loadingTab ? (
                  <Button className={classes.button1}>
                    <CircularProgress size={25} style={{ color: "#fff" }} />
                  </Button>
                ) : (
                  <Button
                    className={classes.button1}
                    onClick={() => handleSwitchPage("menu")}
                  >
                    {intl.formatMessage({
                      id: "View_menu_and_order",
                    })}
                  </Button>
                )}
              </Grid>
              <Grid item xs={12} className={classes.buttonDiv}>
                <Button
                  className={classes.cancelbutton}
                  onClick={() => history.push("/menu/?type=view")}
                >
                  {intl.formatMessage({
                    id: "just_view_menu",
                    defaultMessage: "Just view menu",
                  })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectTable;

const useStyles = makeStyles((theme) => ({
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  errorMessage: {
    marginLeft: "0px",
    marginRight: "0px",
    "&.MuiFormHelperText-contained": {
      marginLeft: "0px",
      marginRight: "0px",
    },
  },
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF",
    },
  },

  formControl: {
    minWidth: "100px !important",
    maxWidth: "101px !important",

    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "100px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },

  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000",
  },

  logoContaier: {
    border: "1px solid #202020",
    width: "127px !important",
    height: "127px !important",
    backgroundColor: "#fff !important",
    borderRadius: "20px !important",
  },
  button1: {
    width: " 100%",
    height: "100%",
    background: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
    "&:focus": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
  },

  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
