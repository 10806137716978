import React, { useState, useEffect } from "react";
import splashLogoPic from "../../assets/images/splashLogoPic.png";
import splashLogoWrite from "../../assets/images/splashLogoWrite.png";
import { Grid, CircularProgress } from "@material-ui/core";
import { useIntl } from "react-intl";
import Logo from "../Logo/Logo";
const LoadingScreen = () => {
  const intl = useIntl();

  const [height, setHeight] = useState(window.textareaheight);
  useEffect(() => {
    setHeight(window.textareaheight);
    // eslint-disable-next-line
  }, []);
  return (
    <Grid
      container
      style={{
        height: height,
        minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
          ? "90vh"
          : "85vh",
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden !important",
        width: "100%",
      }}
    >
      <Grid container justify="center">
        <CircularProgress
          classes={{
            root: "circularProgress",
          }}
          size={40}
          style={{ padding: "10%" }}
        />
      </Grid>
      <Grid
        container
        style={{
          backgroundColor: "#FFFFFF",
          height: "60vh",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            marginTop: "20vh",
          }}
        >
          <Logo/>
          {/* <img
            src={localStorage.getItem("icon")?`data:image/jpeg;base64,${localStorage.getItem("icon")}`:splashLogoPic}
            // src={splashLogoPic}
            width={"130px"}
            style={{
              borderRadius: "15%",
              height: "130px",
            }}
            alt={intl.formatMessage({ id: "logo" })}
          ></img> */}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingScreen;
