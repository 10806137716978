import {
  CREATE_ORDER,
  ADD_ORDER_COUNT,
  REDUCE_ORDER_COUNT,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  UPDATE_ORDER_ITEM,
  GET_DETAIL,
  ADD_ITEM,
  SET_SELECTED_ORDER,
  SET_LOADER,
  SET_UPDATED,
  SET_PAYMENT_INTENT,
  ORDER_HISTORY,
  ORDER_EXPIRE,
  TAB_EXPIRE,
  REORDER_LIST,
  CLEAR_OLD_ORDER,
  UPDATE_ORDER_COUNT
} from "../constant/types";
import moment from "moment";
const initialState = {
  orderId: null,
  orderCount: 0,
  orderList: [],
  orderDetail: null,
  orderItems: [],
  selectedOrder: null,
  loader: false,
  updated: false,
  paymentIntent: null,
  orderHistory: [],
  orderExpire: false,
  tabExpire: false,
};

// handle actions
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_OLD_ORDER:
      let list = [];
      if (state.orderHistory.length > 0) {
        list = state.orderHistory.filter((n) =>
          moment(n.submitted_for_review).isAfter(moment().subtract(1, "day"))
        );
      }
      return { ...state, orderHistory: [...list] };

    case CREATE_ORDER:
      return {
        ...state,
        orderId: payload,
      };
    case SET_PAYMENT_INTENT:
      return {
        ...state,
        paymentIntent: payload,
      };
    case ADD_ITEM:
      return {
        ...state,
      };
    case ORDER_HISTORY:
      return {
        ...state,
        orderHistory: state.orderHistory.filter(
          (item) => payload.order_uuid === item.order_uuid
        )[0]
          ? [...state.orderHistory]
          : [...state.orderHistory, payload],
      };
    case GET_DETAIL:
      return {
        ...state,
        orderDetail: payload,
        orderId:
          payload && (payload.status === 0 || payload.status === 2)
            ? payload.order_uuid
            : null,
        orderHistory:
          payload &&
          !state.orderHistory.filter(
            (item) => payload.order_uuid === item.order_uuid
          )[0] &&
          (payload.status === 3 || payload.status === 4)
            ? [...state.orderHistory, payload]
            : [...state.orderHistory],
        orderItems: payload ? payload.order_items : [],
        orderList:
          (payload && payload.status !== 0) || payload.order_items.length === 0
            ? []
            : state.orderList,
        orderCount:
          (payload && payload.status !== 0) || payload.order_items.length === 0
            ? 0
            : state.orderCount,
      };
    case SET_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case SET_UPDATED:
      return {
        ...state,
        updated: payload,
      };

    case ADD_ORDER_COUNT:
      return {
        ...state,
        orderCount: state.orderCount + payload,
      };
    case UPDATE_ORDER_COUNT:
      return {
        ...state,
        orderCount:  payload,
      };
    case ORDER_EXPIRE:
      return {
        ...state,
        orderExpire: payload,
      };
    case TAB_EXPIRE:
      return {
        ...state,
        tabExpire: payload,
      };
    case REDUCE_ORDER_COUNT:
      return {
        ...state,
        orderCount: state.orderCount - payload,
      };
    case SET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: payload,
      };
    case ADD_ORDER_ITEM:
      return {
        ...state,
        orderList: [...state.orderList, payload],
      };
    case REORDER_LIST:
      return {
        ...state,
        orderList: [...payload]
      };
    case UPDATE_ORDER_ITEM:
      return {
        ...state,
        orderList: state.orderList.map((item, index) => {
          if (item.order_item_id === payload.order_item_id) {
            return payload;
          }
          return item;
        }),
      };
    case REMOVE_ORDER_ITEM:
      return {
        ...state,
        orderList: state.orderList.filter(
          (item) => item.order_item_id !== payload.order_item_id
        ),
      };

    default:
      return state;
  }
}
