import React, { useState, useEffect } from "react";
import OrderNavbar from "./OrderNavbar";
import OrderLoading from "./OrderLoading";
import OrderDeclinePic from "../../assets/images/thumbs_down.png";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Grid } from "@material-ui/core";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  Orderconfirmed: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "22px",
  },
  detail: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#777777",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0px",
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
  button: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    minHeight: "54px",
    background: "#AEAEAE",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    height: "50px",
    paddingLeft: "2rem",
    paddingRight: "2rem",
    bottom: 13,
  },
}));
function OrderDeclined() {
  const classes = useStyles();
  const intl = useIntl();

  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setisLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <Grid container justify="center" className="mainContainer">
        <Grid
          item
          xs={12}
          lg={8}
          style={{ boxShadow: `0px 9px 15px rgba(0, 0, 0, 0.08)` }}
        >
          <OrderNavbar text={"Your order"} backurl={"/checkout"} />

          {isLoading ? (
            <OrderLoading />
          ) : (
            <div
              style={{
                textAlign: "center",
                width: "100%",
                padding: " 180px 0 0  0",
              }}
            >
              <img src={OrderDeclinePic} width={"190px"} alt=""></img>
              <p className={classes.Orderconfirmed}>
                {intl.formatMessage({
                  id: "Order_declined",
                })}
              </p>
              <p className={classes.detail}>
                {intl.formatMessage({
                  id: "Order_decline_kitchen",
                })}
              </p>
              <div className={classes.buttonDiv}>
                <Button
                  className={classes.button}
                  style={{
                    background: localStorage.getItem("color")
                      ? localStorage.getItem("color")
                      : defaultThemeColor,
                  }}
                >
                  {intl.formatMessage({
                    id: "Great",
                  })}
                </Button>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderDeclined;
