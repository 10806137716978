import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  Menu,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getMenusList,
  getVenueDetails,
  getHotelDetails,
} from "../actions/Menu";
import { useHistory, useLocation } from "react-router-dom";
import splashLogoPic from "../assets/images/splashLogoPic.png";
import splashLogoWrite from "../assets/images/splashLogoWrite.png";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../assets/images/no-image-found.png";
import HotelPage from "./Hotel/HotelPage";
// import OpenTab from "./OpenTab";
import SelectTable from "./Hotel/SelectTable";
import OpenTab from "./OpenTab";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useIntl } from "react-intl";
import { getCookie } from "../utils/common";
import Logo from "../components/Logo/Logo";
import { ACCEPT_ORDERS_WITHOUT_TAB } from "../utils/constants";
import { defaultThemeColor } from "../constant/colors";
import analytics from "../utils/analytics"
import LanguageDropDown from "../components/SelectLanguage/Select"
const LandingPage = (props) => {
  const intl = useIntl();
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  const classes = useStyles();
  const dispatch = useDispatch();
  let location = useLocation();
  const [openTab, setOpenTab] = useState(false);
  const [tableNumber, setTableNumber] = useState("");
  const [table, setTable] = useState(null);
  const [currentPage, setCurrentPage] = useState("venue");
  const [displayPage, setDisplayPage] = useState("");

  const history = useHistory();
  const [loader, setLoader] = useState(true);
  let menuReducer = useSelector((state) => state.menuReducer);
  const [height, setHeight] = useState(window.textareaheight);

//  useEffect(async () => {
//    console.log(
//      await analytics.page({
//        url: "busaba.staging.blend.menu",
//      })
//    );
//    // console.log(await analytics.track(`busaba.staging.blend.menu`))
//    //  analytics.track(`${window.location.hostname}`)
//    // eslint-disable-next-line
//  }, []);
  const handleChangePage = () => {
    setLoader(true);
    if (currentPage === "hotel") {
      setCurrentPage("venue");
      getDetails2();
    } else {
      setCurrentPage("hotel");
      getDetails();
    }
  };
  const handleChangePageBack = () => {
    if (localStorage.getItem("hotel_url") !== "empty") {
      window.open(localStorage.getItem("hotel_url"), "_top");
      setCurrentPage("hotel");
      getDetails();
    }
  };
  const handleChangePageBackBusiness = () => {
    if (menuReducer?.menu?.business_account?.domain) {
      window.open(
        `https://${menuReducer?.menu?.business_account?.domain}`,
        "_top"
      );
    }
  };
  useEffect(() => {
    setHeight(window.textareaheight);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (
      menuReducer?.menu?.accept_orders_without_tab ===
        ACCEPT_ORDERS_WITHOUT_TAB ||
      menuReducer?.menu?.read_only_menu === true ||
      menuReducer?.menu?.is_currently_accepting_orders === false
    ) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu?.read_only_menu]);
  useEffect(() => {
    if (menuReducer?.menu?.logo) {
      setLoader(false);
    }
    {
      /* change here  menuReducer?.menu?.business_account?.type === 1*/
    }

    if (
      menuReducer?.menu?.type === 3 &&
      menuReducer?.menu?.business_account?.type === 1
    ) {
      let sessionCookies = null;
      if (menuReducer?.menu?.business_account?.domain) {
        sessionCookies = getCookie(
          "session",
          menuReducer?.menu?.business_account?.domain
        );
      } else {
        sessionCookies = getCookie("session", window.location.host);
      }
      let session =
         new URLSearchParams(location.search).get("session")||sessionCookies ;

      if (
        !session &&
        (localStorage.getItem("sessionId") === "null" ||
          localStorage.getItem("sessionId") === null)
      ) {
        history.push("/login");
      }
      // setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu]);
  const getDetails2 = async () => {
    const url = "venue";
    let res = await dispatch(getVenueDetails(url));
    if (res?.status === 200 && res?.display_hotel_details === true) {
      setCurrentPage("hotel");
      setLoader(false);
    } else if (res?.status === 200 && res?.display_hotel_details === false) {
      dispatch(getMenusList());

      setCurrentPage("venue");
      setLoader(false);
    } else {
    }
  };
  const getDetails = async () => {
    const url = "hotel";
    let sessionCookies = null;
    if (menuReducer?.menu?.business_account?.domain) {
      sessionCookies = getCookie(
        "session",
        menuReducer?.menu?.business_account?.domain
      );
    } else {
      sessionCookies = getCookie("session", window.location.host);
    }

    let session =new URLSearchParams(location.search).get("session")||sessionCookies ;
    if (
      !session &&
      localStorage.getItem("sessionId") !== "null" &&
      localStorage.getItem("sessionId") !== null
    ) {
      session = localStorage.getItem("sessionId");
    }

    let res = await dispatch(getHotelDetails(url, session));
    if (res?.status === 200 && res?.display_hotel_details === true) {
      setCurrentPage("hotel");
      setLoader(false);
    } else if (res?.status === 200 && res?.display_hotel_details === false) {
      dispatch(getMenusList());
      setCurrentPage("venue");
      setLoader(false);
    } else {
    }
  };
  const handleParms = () => {
    let table_number = new URLSearchParams(location.search).get("table_number");
    if (table_number) {
      localStorage.setItem("table_number",table_number)

      setTableNumber(table_number);
      getDetails2();
      setOpenTab(true);
    } else {
      let tabLink = new URLSearchParams(location.search).get("tab");
      let table_Number = new URLSearchParams(location.search).get("table");
      if (table_Number) {
      localStorage.setItem("table_number",table_Number)

        setTable(table_Number);
      }
      let from = new URLSearchParams(location.search).get("from");
      let sessionCookies = null;
      if (menuReducer?.menu?.business_account?.domain) {
        sessionCookies = getCookie(
          "session",
          menuReducer?.menu?.business_account?.domain
        );
      } else {
        sessionCookies = getCookie("session", window.location.host);
      }

      let session = new URLSearchParams(location.search).get("session")||sessionCookies ;
      let session2 = new URLSearchParams(location.search).get("sessionid");
      // localStorage.setItem("sessionId", session2);
      if (!menuReducer?.menu && !hotelDetails?.name) {
        setLoader(true);
      }
      if (
        (session === null || session === undefined || session === "empty") &&
        tabLink
      ) {
        history.push(`/?tab=${tabLink}`);
      }
      if (
        localStorage?.tabId !== null &&
        localStorage?.tabId !== undefined &&
        localStorage?.tabId !== "empty"
      ) {
        // if (session === null || session === undefined || session === "empty") {
          history.push("/menu");
        // }
      }
      if (from) {
        if (from === "login" || from === "join-tab" || from === "open-tab") {
          setDisplayPage("venueHome");
          setOpenTab(false);
        }
        localStorage.setItem("hotel_url", from);
        setCurrentPage("venue");
        getDetails2();
      } else {
        localStorage.setItem("hotel_url", "empty");
        getDetails();
      }
    }
  };
  useEffect(() => {
    handleParms();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    handleParms();
    // eslint-disable-next-line
  }, [new URLSearchParams(location.search).get("from")]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const handleSwitchPage = (path) => {
    localStorage.setItem("from", "landing");

    if (path !== "menu") {
      let p = path;
      if (openTab && menuReducer?.menu?.business_account?.type === 2) {
        p = p + `/?table=${tableNumber}`;
        history.push(`/${p}`);
      } else if (table) {
        p = p + `/?table=${table}`;
        history.push(`/${p}`);
      } else {
        history.push(`/${path}`);
      }
    } else {
      history.push(`/${path}`);
    }
  };
  return (
    <>
      {loader ? (
        <>
          <Grid
            container
            justify="center"
            style={{
              backgroundColor: "#FFFFFF",
              height: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              classes={{
                root: "circularProgress",
              }}
              size={40}
            />
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#FFFFFF",
              height: "80vh",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "20vh",
              }}
            >
              <Logo />
            
            </Grid>
          </Grid>
        </>
      ) : (
        <>
        
          {openTab && menuReducer?.menu?.business_account?.type !== 2 ? (
            <OpenTab table_number={tableNumber} />
          ) : (
            <>
              {currentPage === "hotel" &&
              localStorage.getItem("hotel_url") === "empty" ? (
                <HotelPage handleChangePage={handleChangePage} />
              ) : (
                <>
                  {/* change here  menuReducer?.menu?.business_account?.type === 1*/}
                  {displayPage !== "venueHome" &&
                  menuReducer?.menu?.business_account?.type === 1 ? (
                    <SelectTable />
                  ) : (
                    <Grid
                      container
                      direction="column"
                      style={{
                        height: height,
                        // eslint-disable-next-line
                        // height: "-webkit-fill-available",
                        minHeight: window?.matchMedia(
                          "(display-mode: standalone)"
                        )?.matches
                          ? "90vh"
                          : "85vh",
                        // minHeight: "-webkit-fill-available",
                        display: "flex",
                        justifyContent: "space-between",
                        overflow: "hidden !important",
                      }}
                    >
                      <Grid>
                        <Grid
                          container
                          style={{
                            marginTop: "6%",
                          }}
                        >
                          <Grid item xs={2}>
                            {menuReducer?.menu?.business_account?.type === 1 ||
                              (menuReducer?.menu?.business_account?.type ===
                                2 && (
                                <ArrowBackIosIcon
                                  style={{
                                    paddingLeft: "20px",
                                    cursor: "pointer",
                                  }}
                                  onClick={handleChangePageBackBusiness}
                                />
                              ))}
                          </Grid>
                          <Grid
                            item
                            xs={8}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Grid>
                              {isLoading ? (
                                <Skeleton
                                  animation="wave"
                                  variant={"circle"}
                                  width={"124px"}
                                  height={"124px"}
                                />
                              ) : null}
                              <img
                                onLoad={() => {
                                  setIsLoading(false);
                                }}
                                title={props.title}
                                alt={props.title}
                                onError={(err) => {
                                  setIsLoading(false);
                                  setIsError(true);
                                }}
                                src={
                                  isError ? noimage : menuReducer?.menu?.logo
                                }
                                className={classes.logoContaier}
                                style={{
                                  display: isLoading ? "none" : "block",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid item xs={2}>
                            <LanguageDropDown/>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          style={{
                            marginTop: "5%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Grid style={{ width: "100%" }}>
                            <Typography className={classes.header}>
                              {intl.formatMessage({ id: "welcome" })}
                            </Typography>
                          </Grid>

                          <Grid
                            style={{
                              padding: "0px 48px",
                              marginTop: "1.2vh",
                              width: "100%",
                            }}
                          >
                            <Typography className={classes.body}>
                              {intl.formatMessage({
                                id: "join_an_existing_tab_or_open_a_new_one",
                              })}
                              <br />
                              {menuReducer?.menu?.accept_card &&
                                intl.formatMessage({
                                  id: "just_having_a_quick_drink_Select_Order_pay_now",
                                })}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container style={{ marginBottom: "6%" }}>
                        <Grid item xs={12} className={classes.buttonDiv}>
                          <Button
                            className={classes.button1}
                            style={{
                              background: localStorage.getItem("color")
                                ? localStorage.getItem("color")
                                : defaultThemeColor,
                            }}
                            onClick={() => handleSwitchPage("open-tab")}
                          >
                            {intl.formatMessage({ id: "open_new_tab" })}
                          </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonDiv}>
                          <Button
                            className={classes.button2}
                            style={{
                              border: `1px solid ${
                                localStorage.getItem("color")
                                  ? localStorage.getItem("color")
                                  : defaultThemeColor
                              }`,
                            }}
                            onClick={() => handleSwitchPage("join-tab")}
                          >
                            {intl.formatMessage({ id: "Join_tab" })}
                          </Button>
                        </Grid>
                        <Grid item xs={12} className={classes.buttonDiv}>
                          {menuReducer?.menu?.accept_card && (
                            <Button
                              className={classes.button3}
                              onClick={() => handleSwitchPage("menu")}
                            >
                              {intl.formatMessage({ id: "order_pay_now" })}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}{" "}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default LandingPage;

const useStyles = makeStyles((theme) => ({
  logoContaier: {
    border: "1px solid #202020",
    width: "127px !important",
    height: "127px !important",
    backgroundColor: "#fff !important",
    // boxSizing: "border-box !important",
    borderRadius: "20px !important",
  },
  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    textAlign: "center",
    color: "#000000",
  },
  body: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineheight: "18px",
    textAlign: "center",
    color: "#000000",
  },

  button1: {
    width: " 100%",
    height: "100%",

    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
  },
  button2: {
    width: " 100%",
    height: "100%",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    textTransform: "none",
  },
  button3: {
    width: " 100%",
    height: "100%",
    background: "#fff",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#000",
    textTransform: "none",
  },
  buttonDisabled: {
    width: " 100%",
    height: "100%",
    background: "#AEAEAE",
    borderRadius: "25px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#FFFFFF !important",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#AEAEAE !important",
    },
  },
  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
