import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import OrderNavbar from "./OrderNavbar";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  loadingicon: {
    textAlign: "center",
    padding: " 15rem 0",
  },
  loadingtext: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#000000",
    marginTop: "0px",
  },
  Orderconfirmed: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "22px",
  },
  detail: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "20px",
    textAlign: "center",
    color: "#777777",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "0px",
    paddingLeft: "3rem",
    paddingRight: "3rem",
  },
  cashcard: {
    position: "fixed",
    bottom: 80,
    left: "35%",
  },
}));
function Payment() {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div>
      <OrderNavbar text={"Payment method"} />
      <div
        style={{ textAlign: "center", width: "100%", padding: " 180px 0 0  0" }}
      >
        <p className={classes.Orderconfirmed}>
          {intl.formatMessage({
            id: "How_would_you_like_to_pay",
          })}
        </p>
        <p className={classes.detail}>
          {intl.formatMessage({
            id: "payment_message",
          })}
        </p>

        <div className={classes.cashcard}>
          <ButtonGroup
            size="large"
            color="secondary"
            aria-label="outlined secondary button group"
          >
            <Button>
              {intl.formatMessage({
                id: "Cash",
              })}
            </Button>
            <Button>
              {intl.formatMessage({
                id: "card",
              })}
            </Button>
          </ButtonGroup>
        </div>
      </div>
    </div>
  );
}

export default Payment;
