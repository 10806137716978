import React, { useEffect, useState } from "react";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import CardContent from "@material-ui/core/CardContent";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import parse from "html-react-parser";
import Grid from "@material-ui/core/Grid";
import GridList from "@material-ui/core/GridList";
import SmartImage from "./SmartImage";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AddIcon from "@material-ui/icons/Add";
import { SET_ROUTE } from "../constant/types";
import { defaultThemeColor } from "../constant/colors";
import CardFooter from "./Footer/CardFooter";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap !important",
    transform: "translateZ(0) !important",
    paddingTop: "5px !important",
    paddingBottom: "15px !important",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "&::-webkit-scrollbar-track": {
      display: "none",
    },

    "&::-webkit-scrollbar-thumb": {
      display: "none",
      height: "0px",
      width: "0px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#EDF0F4",
    },
    "&::-webkit-scrollbar-button": {
      width: "0px", //for horizontal scrollbar
      height: "0px", //for vertical scrollbar
    },
  },
  root: {
    "& > span": {
      margin: theme.spacing(2),
    },
  },
  ordercount: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "18px",

    display: "inline",
    marginRight: "5px",
  },
  addItemText: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "18px",
  },
  button: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#FFFFFF",
    borderRadius: "5px",
    padding: "6px 10px",
    boxShadow: "none",
    textTransform: "none",
    "& .MuiButton-endIcon": {
      display: "inherit",
      marginLeft: "2px",
      marginRight: "-4px",
    },
  },
}));

var makeInViewport = function (elemId) {
  let bounding = document.getElementById(elemId).getBoundingClientRect();
  if (
    !(
      bounding.top >= 60 &&
      bounding.left >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <=
        (window.innerWidth || document.documentElement.clientWidth)
    )
  ) {
    if (bounding.top < 80) {
      window.scrollBy(0, bounding.top - 160);
    } else if (
      bounding.bottom > window.innerHeight ||
      document.documentElement.clientHeight
    ) {
      window.scrollBy(0, -(window.innerHeight - bounding.bottom));
    }
  }
};
export default function DetailCard(props) {
  const {currentSelctedMenu,setOpenReadOnlyMessage}=props; 
  const intl = useIntl();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  let dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");
  const [orderItemCount, setOrderItemCount] = useState(0);
  let orderReducer = useSelector((state) => state.orderReducer);
  useEffect(() => {
    makeInViewport("Full_Card");
  }, []);
  useEffect(() => {
    setLoading(false);
  }, [orderReducer.orderCount]);
  useEffect(() => {
    const tempOrderList = orderReducer.orderList.filter(
      (item) => item.item_id === props.dish.item_id
    );
    const tempOrder = tempOrderList[0];

    if (tempOrder) {
      setOrderItemCount(
        tempOrderList.reduce((a, b) => a + (b["quantity"] || 0), 0)
      );
      setSelectedOrder(tempOrder);
    }
  }, [orderReducer.orderList, props.dish.item_id]);

  const addQuantity = () => {
    if(currentSelctedMenu?.read_only===true){
      setOpenReadOnlyMessage(true)
    }else if (
      props?.dish?.availability?.out_of_stock === true ||
      props?.is_currently_accepting_orders === false
    ) {
    } else {
      if (Loading === false) {
        setLoading(true);

        history.push({
          pathname: "/addcart",
        });
        dispatch({
          type: "SET_SELECTED_ORDER",
          payload: {
            ...props.dish,
            currency: props.currency,
          },
        });
        dispatch({
          type: SET_ROUTE,
          payload: {
            current: "addcart",
            last: location.pathname.replace("/", ""),
          },
        });
      }
    }
  };
  // eslint-disable-next-line
  const detectMob = () => {
    const userAgent = window?.navigator?.userAgent?.toLowerCase();
    let device = /iPhone|iPad|iPod|Android/i.test(userAgent);
    if (device) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <Grid key={props.key} col={12} id="Full_Card">
      <Card
        className={"detailCard"}
        style={{
          paddingLeft: "1rem",
          borderLeft:
            selectedOrder && selectedOrder.quantity
              ? `solid ${
                  localStorage.getItem("color")
                    ? localStorage.getItem("color")
                    : defaultThemeColor
                } 6px`
              : "none",
          borderRadius: "0px",
        }}
      >
        <div
          className={"detailHeader"}
          onClick={() => {
            props.setShowDetails(false);
            props.setSelectedCard(null);
          }}
        >
          {selectedOrder && selectedOrder.quantity && (
            <Typography
              className={classes.ordercount}
              style={{
                color: localStorage.getItem("color")
                  ? localStorage.getItem("color")
                  : defaultThemeColor,
              }}
            >
              x{orderItemCount}
            </Typography>
          )}

          <p
            className="dishName"
            style={{
              display: "inline",
              textDecoration:
                props?.dish?.availability?.out_of_stock === true
                  ? "line-through"
                  : "",
            }}
          >
            {props.dish.name}
          </p>
        </div>

        {props.dish.image && (
          <div
            className="detailCardImageDiv"
            // style={{paddingTop:"30px"}}
            onClick={() => {
              props.setShowDetails(false);
              props.setSelectedCard(null);
            }}
          >
            <SmartImage
              url={props.dish.image}
              title={props.dish.name}
              styleClass="detailCardImage"
              width={"100%"}
              height={250}
              type={"rect"}
            />
          </div>
        )}
        {props.dish.image ? (
          <Button
            onClick={() => {
              props.setShowDetails(false);
              props.setSelectedCard(null);
            }}
            className={"overLayButton"}
          >
            <ClearIcon fontSize={props.dish.image ? "large" : "small"} />
          </Button>
        ) : (
          ""
        )}
        <CardContent className="detailContent">
          <p
            className="dishDescription"
            style={{
              cursor: "pointer",
              textDecoration:
                props?.dish?.availability?.out_of_stock === true
                  ? "line-through"
                  : "",
            }}
            onClick={() => {
              props.setShowDetails(false);
              props.setSelectedCard(null);
            }}
          >
            {/* {parse('<h1>single</h1>')} */}
            <div
              dangerouslySetInnerHTML={{
                __html: props.dish.description,
              }}
            />
            {/* {parse(props.dish.description)} */}
            {/* {props.dish.description} */}
          </p>

          {props.dish && props.dish.tags && (
            <GridList
              style={{ cursor: "pointer" }}
              className={classes.gridList}
              onClick={() => {
                props.setShowDetails(false);
                props.setSelectedCard(null);
              }}
            >
              {props.dish &&
                props.dish.tags.length &&
                props.dish.tags.map((tag) => (
                  <>
                    {tag.image ? (
                      <Chip
                        icon={
                          <img
                            src={tag.image.image}
                            style={{
                              marginLeft: "10px",
                              height: "15px",
                              width: "15px",
                            }}
                            alt={tag.name}
                          />
                        }
                        variant="default"
                        size="small"
                        label={tag.name}
                        style={{
                          backgroundColor: tag.colour
                            ? tag.colour.bg_hex
                            : "#000",
                          color: tag.colour ? tag.colour.text_hex : "#000",
                          marginRight: "0.5rem",
                          marginLeft: "0.25rem",
                        }}
                      />
                    ) : (
                      <Chip
                        variant="default"
                        size="small"
                        label={tag.name}
                        style={{
                          backgroundColor: tag.colour
                            ? tag.colour.bg_hex
                            : "#000",
                          color: tag.colour ? tag.colour.text_hex : "#000",
                          marginRight: "0.5rem",
                          marginLeft: "0.25rem",
                        }}
                      />
                    )}
                  </>
                ))}
            </GridList>
          )}
          <Grid container>
            <Grid item xs={6}>
              {parseFloat(props.dish.price) === parseFloat("0") ? (
                ""
              ) : (
                <CardFooter dish={props.dish} currency={props.currency} />
              )}
              
            </Grid>
            <Grid item xs={6} style={{ textAlign: "end", paddingTop: "8px" }}>
              {props.readOnly ? (
                ""
              ) : (
                <>
                  <Grid container justify="flex-end">
                    <Grid item>
                      {Loading ? (
                        <Grid item xs={12}>
                          <CircularProgress
                            style={{
                              color: localStorage.getItem("color")
                                ? localStorage.getItem("color")
                                : defaultThemeColor,
                            }}
                            size={20}
                          />
                        </Grid>
                      ) : (
                        <Grid container>
                          
                          <Grid item>
                            <p
                              style={{
                                color:  currentSelctedMenu?.read_only===true||
                                  props?.dish?.availability?.out_of_stock ===
                                    true ||
                                  props?.is_currently_accepting_orders === false
                                    ? "#AEAEAE"
                                    : localStorage.getItem("color")
                                    ? localStorage.getItem("color")
                                    : defaultThemeColor,
                                height: "38px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                cursor: "default",
                              }}
                              className={classes.addItemText}
                            >
                              {intl.formatMessage({ id: "Add_item" })}
                            </p>
                          </Grid>
                          <Grid item>
                            <p
                              style={{
                                backgroundColor:currentSelctedMenu?.read_only===true||
                                  props?.dish?.availability?.out_of_stock ===
                                    true ||
                                  props?.is_currently_accepting_orders === false
                                    ? "#AEAEAE"
                                    : localStorage.getItem("color")
                                    ? localStorage.getItem("color")
                                    : defaultThemeColor,
                                cursor: "pointer",
                                color: "#fff",
                                width: "38px",
                                height: "38px",
                                borderRadius: "5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginLeft: "15px",
                              }}
                              onClick={() => {
                                addQuantity();
                              }}
                            >
                              <AddIcon
                                style={{
                                  color: "#fff",
                                  cursor: "pointer",
                                  marginLeft: "2px important",
                                }}
                              />
                            </p>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
