import React, { useEffect, useState } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Menu from "./containers/Menu";
import LandingPage from "./containers/LandingPage";
import OpenTab from "./containers/OpenTab";
import JoinTab from "./containers/JoinTab";
import Search from "./containers/Search";
import OrderComplete from "./components/Order/OrderComplete";
import PreviousOrderList from "./components/Order/PreviousOrderList";
import { useDispatch, useSelector } from "react-redux";
import Checkout from "./components/Order/Checkout";
import AddCart from "./components/Cart/AddCart";
import Payment from "./components/Order/Payment";
import CartOpen from "./components/Cart/CartOpen";
import PreviousOrderView from "./components/Cart/PreviousOrderView";
import TipModel from "./components/Cart/TipModel";
import { Grid } from "@material-ui/core";
import OrderDeclined from "./components/Order/OrderDeclined";
import OrderExpireDialog from "./components/Tab/OrderExpireDialog";
import TabExpireDialog from "./components/Tab/TabExpireDialog";
import Login from "./containers/Hotel/Login";
import SentEmail from "./containers/Hotel/EmailConfirmation";
import EnterTable from "./containers/Hotel/EnterTable";
import { IntlProvider } from "react-intl";
import en from "./utils/locales/en";
import es from "./utils/locales/es";
import gr from "./utils/locales/gr";
import fr from "./utils/locales/fr";
import analytics from "./utils/analytics"

function App() {
  let dispatch = useDispatch();
  const orderExpire = useSelector((state) => state.orderReducer.orderExpire);
  const tabExpire = useSelector((state) => state.orderReducer.tabExpire);

  React.useEffect(() => {
    const menuId = localStorage.getItem("menuId");
    dispatch({
      type: "SET_MENU_ID",
      payload: menuId ? menuId : "",
    });
    const orderId = localStorage.getItem("orderId");
    dispatch({
      type: "CREATE_ORDER",
      payload: orderId ? orderId : "",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Router>
        <Grid
          container
          justify="center"
          style={{
            height: "-webkit-fill-available",

            minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
              ? "90vh"
              : "85vh",
            boxShadow: `0px -10px 15px rgba(0, 0, 0, 0.08)`,
            padding: "0px",
            margin: "0px",
          }}
        >
          <Grid
            item
            xs={12}
            lg={5}
            md={5}
            id="menu"
            style={{
              boxShadow: `0px -20px 15px rgba(0, 0, 0, 0.08)`,
            }}
          >
            <Switch>
              <Route
                path="/login"
                component={(restProps) => <Login {...restProps} />}
              />
              <Route
                path="/enter-table/:slug"
                component={(restProps) => <EnterTable {...restProps} />}
              />

              <Route
                path="/email-confirmation"
                component={(restProps) => <SentEmail {...restProps} />}
              />

              <Route
                path="/venue/d/:restaurant_id"
                component={(restProps) => <Menu {...restProps} />}
              />

              <Route
                path="/order"
                component={(restProps) => <OrderComplete {...restProps} />}
              />
              <Route
                path="/orderdecline"
                component={(restProps) => <OrderDeclined {...restProps} />}
              />
              <Route
                path="/Checkout"
                component={(restProps) => <Checkout {...restProps} />}
              />
              <Route
                path="/addCart"
                component={(restProps) => <AddCart {...restProps} />}
              />

              <Route
                path="/payment"
                component={(restProps) => <Payment {...restProps} />}
              />
              <Route
                path="/cartopen"
                component={(restProps) => <CartOpen {...restProps} />}
              />
              <Route
                path="/order-detail"
                component={(restProps) => <PreviousOrderView {...restProps} />}
              />
              <Route
                path="/previousOrders"
                component={(restProps) => <PreviousOrderList {...restProps} />}
              />
              <Route
                path="/tip"
                component={(restProps) => <TipModel {...restProps} />}
              />

              <Route
                path="/open-tab"
                component={(restProps) => <OpenTab {...restProps} />}
              />
              <Route
                path="/join-tab"
                component={(restProps) => <JoinTab {...restProps} />}
              />

              <Route
                path="/menu"
                component={(restProps) => <Menu {...restProps} />}
              />
              <Route
                path="/search"
                component={(restProps) => <Search {...restProps} />}
              />
              <Route
                path="/"
                component={(restProps) => <LandingPage {...restProps} />}
              />
            </Switch>
          </Grid>
        </Grid>
        {orderExpire === true && <OrderExpireDialog />}
        {tabExpire === true && <TabExpireDialog />}
      </Router>
    </div>
  );
}
const msg = {
  fr: fr,
  en: en,
  es: es,
  gr:gr
};
const NewApp = () => {
  let languageReducer = useSelector((state) => state.languageReducer);
  const [locale, setLocale] = useState(
    localStorage.getItem("language") ? localStorage.getItem("language") : "en"
  );
  useEffect(() => {
    setLocale(
      languageReducer?.language ? languageReducer?.language : "en"
    );
    // eslint-disable-next-line
  }, [languageReducer?.language, localStorage.getItem("language")]);
  useEffect(async () => {
    console.log(
      await analytics.page({
        url: `${window.location.hostname}`,
        path: `${window.location.hostname}`,
      })
    );
    
    // eslint-disable-next-line
  }, []);
  return (
    <IntlProvider locale={locale?locale:en} messages={msg[locale]?msg[locale]:msg["en"]}>
      <App />
    </IntlProvider>
  );
};
export default NewApp;
