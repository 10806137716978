import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { defaultThemeColor } from "../../constant/colors";
import { useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    maxWidth: 530,
    width: "100%",
    right: "unset",
  },
  yourOrder: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    textAlign: "left",
    color: "#000000",
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    cursor: "pointer",
  },
  countBadge: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#FFFFFF",
    height: "32px",
    width: "32px",
    borderRadius: "5px",
    justifyContent: "center",
  },
  innerBox: {
    margin: "10px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.15)",
    borderRadius: "5px",
  },
  badgeGrid: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
function OrderBar(props) {
  const intl = useIntl();

  const classes = useStyles();
  const history = useHistory();
  const [width, setWidth] = useState(
    document.getElementById("menu")?.offsetWidth
  );
  useEffect(() => {
    setWidth(document.getElementById("menu")?.offsetWidth);
    // eslint-disable-next-line
  }, document.getElementById("menu")?.offsetWidth);
  return (
    <div
      onClick={() => history.push("/cartopen")}
      style={{ cursor: "pointer" }}
    >
      <Grid container>
        <Grid container>
          <AppBar
            position="fixed"
            color="inherit"
            className={classes.appBar}
            style={{ maxWidth: width ? width : "530px" }}
          >
            <Toolbar style={{ padding: "0px" }}>
              <Grid container className={classes.innerBox}>
                <Grid item xs={2} className={classes.badgeGrid}>
                  <Typography
                    className={classes.countBadge}
                    style={{
                      backgroundColor: localStorage.getItem("color")
                        ? localStorage.getItem("color")
                        : defaultThemeColor,
                    }}
                  >
                    {props.count}
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <p className={classes.yourOrder}>
                    {intl.formatMessage({
                      id: "Checkout",
                    })}
                    <ArrowRightAltIcon
                      style={{ paddingLeft: "4px", paddingTop: "2px" }}
                    />
                  </p>
                </Grid>
                <Grid item xs={2}>
                  {/* <ArrowForwardIosTwoToneIcon className={classes.icon} /> */}
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrderBar;
