import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  CircularProgress,
  FormHelperText,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { getVenueDetails } from "../../actions/Menu";
import { getTableAllList } from "../../actions/order";

import {
  getTableListByUrl,
  createOpenTabWithSession,
} from "../../actions/order";
import { useHistory, useParams } from "react-router-dom";
import splashLogoPic from "../../assets/images/splashLogoPic.png";
import splashLogoWrite from "../../assets/images/splashLogoWrite.png";
import Skeleton from "@material-ui/lab/Skeleton";
import noimage from "../../assets/images/no-image-found.png";
import TabBar from "../../components/Hotel/TabBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { getCookie,getRoomNumberFromCookies } from "../../utils/common";
import { ROOM_SERVICE } from "../../utils/constants";
import LanguageDropDown from "../../components/SelectLanguage/Select"

const SelectTable = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  let menuReducer = useSelector((state) => state.menuReducer);

  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const location = useLocation();

  const [height] = useState(window.textareaheight);
  // eslint-disable-next-line
  const [error, setError] = useState(null);
  let hotelDetails = useSelector((state) => state.hotelReducer?.details);
  let [selectedVenue, setSelectedVenue] = useState(null);
  const [tableNumber, setTableNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isErrorTable, setIsErrorTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tablesList, setTablesList] = useState([]);
  const handleSwitchPage = async () => {
    setLoading(true);
    dispatch(
      createOpenTabWithSession({
        venue_uuid: menuReducer.menu?.uuid,
        table_number: tableNumber,
        first_name: tableNumber,
      })
    ).then((res) => {
      if (res?.status === 200) {
        let url = res?.tab_Link;
        if (localStorage.getItem("sessionId") !== null) {
          window.open(
            url + `&sessionid=${localStorage.getItem("sessionId")}`,
            "_top"
          );
        } else {
          window.open(url, "_top");
        }
      }
    });
    setLoading(false);
  };
  const handleSwitchPageWithTableNumber = async (tNumber) => {
    setLoading(true);
    dispatch(
      createOpenTabWithSession({
        venue_uuid: menuReducer.menu?.uuid,
        table_number: tNumber,
        first_name: tNumber,
      })
    ).then((res) => {
      if (res?.status === 200) {
        let url = res?.tab_Link;
        if (localStorage.getItem("sessionId") !== null) {
          window.open(
            url + `&sessionid=${localStorage.getItem("sessionId")}`,
            "_top"
          );
        } else {
          window.open(url, "_top");
        }
      }
    });
    setLoading(false);
  };
  useEffect(() => {
    let table_Number = new URLSearchParams(location.search).get("table_number");
    if (table_Number) {
      setTableNumber(table_Number);
      localStorage.setItem("table_number", table_Number);
      handleCreateSessionWithTableNumber(table_Number)
        
    }
    // eslint-disable-next-line
  }, []);
  const handleChangePage = () => {
    history.goBack();
  };

  const getDetails = async () => {
    let venuesList = hotelDetails?.venues;

    let index = venuesList?.findIndex((n) => n?.uuid === param?.slug);
    if (index > -1) {
      setSelectedVenue(venuesList[index]);
      let res = await dispatch(
        getTableListByUrl(`https://${venuesList[index]?.domain}`)
      );
      if (res?.data) {
        setTablesList(res?.data);
      }
    } else {
      const params = new URL(window?.location?.href).search;
      let search = new URLSearchParams(params).get("session");
      if (search) {
        history.push(`/?session=${search}`);
      } else {
        history.push(`/`);
      }
    }
  };

  const [tableListView, settableListView] = useState([]);
  const [currentPage, setCurrentPage] = useState("");

  let tableList = useSelector((state) => state?.menuReducer?.tableList);
  useEffect(() => {
    if (props?.table_number) {
      setTableNumber(props.table_number);
      if (localStorage.getItem("tabId") !== "empty") {
        history.push(`/menu/?tab=${localStorage.getItem("tabId")}`);
      }
    }
    // eslint-disable-next-line
  }, [props]);
  // eslint-disable-next-line
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if(menuReducer?.menu?.type===ROOM_SERVICE){
      let room = getRoomNumberFromCookies(
        "room_number",
        //"localhost"
         menuReducer?.menu?.business_account?.domain
      );
      let sessionCookies = getCookie(
        "session",
        // "localhost"
        menuReducer?.menu?.business_account?.domain
      );
  
      if(room!==undefined&&sessionCookies!==undefined&&
        room!==""&&sessionCookies!==""&&
        room!==null&&sessionCookies!==null
        ){      handleSwitchPageWithTableNumber(room)}
      
    }
    if (menuReducer?.menu?.read_only_menu === true) {
      history.push(`/menu`);
    } else if (menuReducer?.menu?.read_only_menu === false) {
      setLoader(false);
    }
    // eslint-disable-next-line
  }, [menuReducer?.menu?.read_only_menu]);

  const handleChange = (event) => {
    setTableNumber(event.target.value);
  };

  const onCreate = () => {};
  useEffect(() => {
    let session2 = new URLSearchParams(location.search).get("sessionid");
    let session3 = new URLSearchParams(location.search).get("session");
    if (session2) {
      setCurrentPage("session");
    } else {
      getData();
    }
    
    if (session2) {
      localStorage.setItem("sessionId", session2);
    } else if (session3) {
      localStorage.setItem("sessionId", session3);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      let temp = [];
      tableList &&
        tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      settableListView(temp);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (tableList) {
      // let temp = [];
      // tableList &&
      // tableList.map((item) => (temp = [...temp, { id: `${item}` }]));
      setTablesList(tableList);
    }
    // eslint-disable-next-line
  }, [tableList]);

  const getData = async () => {
    setLoader(true);
    const url =
      process.env.NODE_ENV === "development"
        ? "https://busaba.staging.blend.menu"
        : window.location.protocol + "//" + window.location.host;
    // eslint-disable-next-line
    if (!menuReducer?.menu) {
      let res = await dispatch(getVenueDetails(url + "/d/"));
    }
    // eslint-disable-next-line
    let res1 = await dispatch(getTableAllList(url + "/tables/list/"));
    setLoader(false);
  };
  const handleCreateSession = () => {
    if (tableNumber === "" || tableNumber === undefined) {
      setIsErrorTable(true);
    } else {
      let sessionCookies = getCookie(
        "session",
        menuReducer?.menu?.business_account?.domain
      );
      let session2 =
        sessionCookies || new URLSearchParams(location.search).get("session");
      let session = localStorage.getItem("sessionId");

      if (
        session2 ||
        (session !== undefined && session !== null && session !== "null")
      ) {
        handleSwitchPage();
        // history.push(`/login/?table_number=${tableNumber}&sessionid=${session2}`);
      } else {
        history.push(
          `/login/?table_number=${tableNumber}&sessionid=${session2}`
        );
      }
    }
  };
  const handleCreateSessionWithTableNumber = (tNumber) => {
    if (tNumber === "" || tNumber === undefined) {
      setIsErrorTable(true);
    } else {
      let sessionCookies = getCookie(
        "session",
        menuReducer?.menu?.business_account?.domain
      );
      let session2 =
        sessionCookies || new URLSearchParams(location.search).get("session");
      let session = localStorage.getItem("sessionId");

      if (
        session2 ||
        (session !== undefined && session !== null && session !== "null")
      ) {
        handleSwitchPageWithTableNumber(tNumber);
      } else {
        history.push(
          `/login/?table_number=${tNumber}&sessionid=${session2}`
        );
      }
    }
  };
  const handleCancel = () => {
    if (localStorage.getItem("from") === "menu") {
      localStorage.removeItem("from");
      history.push("/menu");
    } else {
      history.push("/");
    }
  };
  return (
    <>
      {false ? (
        <>
          <Grid
            container
            justify="center"
            style={{
              backgroundColor: "#FFFFFF",
              height: "20vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress
              classes={{
                root: "circularProgress",
              }}
              size={40}
            />
          </Grid>
          <Grid
            container
            style={{
              backgroundColor: "#FFFFFF",
              height: "70vh",
            }}
          >
            <Grid
              container
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30%",
              }}
            >
              <img src={splashLogoPic} width={"130px"} alt=""></img>
              <img
                src={splashLogoWrite}
                width={"130px"}
                style={{ paddingTop: "20px" }}
                alt=""
              ></img>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid
          container
          direction="column"
          style={{
            height: height,
            minHeight: window?.matchMedia("(display-mode: standalone)")?.matches
              ? "90vh"
              : "85vh",
            display: "flex",

            overflow: "hidden !important",
          }}
        >
          <TabBar />
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",

              height: height,
             minHeight: window?.matchMedia("(display-mode: standalone)")
                ?.matches
                ? "90vh"
                : "85vh",
              overflow: "hidden !important",
            }}
          >
            <Grid
              item
              container
              style={{
                marginTop: "6%",
              }}
            >
              <Grid item xs={2}>
                <ArrowBackIosIcon
                  style={{ paddingLeft: "20px", cursor: "pointer" }}
                  onClick={handleChangePage}
                />
              </Grid>
              <Grid
                item
                xs={8}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    animation="wave"
                    variant={"circle"}
                    width={"124px"}
                    height={"124px"}
                  />
                ) : null}
                <img
                  onLoad={() => {
                    setIsLoading(false);
                  }}
                  title={props.title}
                  alt={props.title}
                  onError={(err) => {
                    setIsLoading(false);
                    setIsError(true);
                  }}
                  src={isError ? noimage : menuReducer?.menu?.logo}
                  className={classes.logoContaier}
                  style={{ display: isLoading ? "none" : "block" }}
                />
              </Grid>
              <Grid item xs={2}>
               <Grid  >
              <LanguageDropDown/>
              </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginTop: "25%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography className={classes.header}>
               { menuReducer?.menu?.type!==ROOM_SERVICE?
                intl.formatMessage({ id: "Enter_your_table_number" }):
                  intl.formatMessage({ id: "Enter_your_room_number" })}
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <FormControl
                  error={isErrorTable}
                  variant="outlined"
                  className={classes.formControl}
                >
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={tableNumber}
                    className={classes.selectTable}
                    onChange={handleChange}
                  >
                    {tablesList.map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {isErrorTable && "Select table number"}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{
                marginBottom: "6%",
                marginTop: "15%",
              }}
            >
              <Grid item xs={12} className={classes.buttonDiv}>
                <Button
                  disabled={loading}
                  className={classes.button1}
                  onClick={() => handleCreateSession()}
                >
                  {loading ? (
                    <CircularProgress size={30} style={{ color: "#fff" }} />
                  ) : (
                    intl.formatMessage({ id: "View_menu_and_order" })
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} className={classes.buttonDiv}>
                <Button
                  className={classes.cancelbutton}
                  onClick={() => history.push("/menu/?type=view")}
                >
                  {intl.formatMessage({
                    id: "just_view_menu",
                    defaultMessage: "Just view menu",
                  })}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default SelectTable;

const useStyles = makeStyles((theme) => ({
  cancelbutton: {
    width: " 100%",
    height: "100%",
    background: "#FFFFFF",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#777777",
    textTransform: "none",
  },
  selectTable: {
    lineHeight: "18px",
    color: "#777777",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF",
    },
  },

  formControl: {
    minWidth: "90px",
    background: "#FFFFFF",
    boxSizing: "border-box",
    borderRadius: "5px",
    maxHeight: "100px",
    height: "50px",
    "& .MuiSelect-select:focus": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
    "& .MuiSelect-select": {
      outline: "none",
      backgroundColor: "#FFFFFF !important",
    },
  },

  header: {
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "#000",
  },

  logoContaier: {
    border: "1px solid #202020",
    width: "127px !important",
    height: "127px !important",
    backgroundColor: "#fff !important",
    borderRadius: "20px !important",
  },
  button1: {
    width: " 100%",
    height: "100%",
    background: localStorage.getItem("color")
      ? localStorage.getItem("color")
      : "#FF3366",
    borderRadius: "5px",
    fontFamily: theme.fontFamily,
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "18px",
    color: "#FFFFFF",
    textTransform: "none",
    "&:hover": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
    "&:focus": {
      backgroundColor: localStorage.getItem("color")
        ? localStorage.getItem("color")
        : "#FF3366",
    },
  },

  buttonDiv: {
    marginTop: "0.4rem ",
    height: "50px",
    paddingLeft: "0.9rem",
    paddingRight: "0.9rem",
    bottom: 13,
  },
}));
